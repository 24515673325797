<div class="playground-container">
    <div class="layout-json">
    </div>
    <div class="controls-container flex justify-end">
        <button class="uisr-primary-button mb-6" (click)="addNote()">{{ 'ADD_STRING' | translate }}</button>
    </div>

    <div class="grid-container">
        <ktd-grid [cols]="cols" [rowHeight]="rowHeight" [layout]="layout" [compactType]="compactType"
            [preventCollision]="preventCollision" [scrollableParent]="autoScroll ? document : null" scrollSpeed="4"
            (dragStarted)="onDragStarted($event)" (resizeStarted)="onResizeStarted($event)"
            (dragEnded)="onDragEnded($event)" (resizeEnded)="onResizeEnded($event)"
            (layoutUpdated)="onLayoutUpdated($event)">
            <ktd-grid-item *ngFor="let item of layout; trackBy:trackById" [id]="item.id"
                [transition]="currentTransition" [dragStartThreshold]="dragStartThreshold" [draggable]="!disableDrag"
                [resizable]="!disableResize">
                <div class="h-[90%] w-[90%] text-ellipsis overflow-hidden rounded-md p-3 break-inside-avoid cursor-pointer m-auto" [ngClass]="item.colour_note || 'bg-blue-200'"
                    (mouseup)="editNote(item)">
                    <div class="shrink flex flex-col gap-[0.15rem] font-medium border-b pb-1" [ngClass]="getDividerColor(item.colour_note)">
                        <span class="text-[0.6rem]">{{item.created_at | date}}</span>
                        <div class="flex justify-between items-center gap-2 text-slate-800">
                            <span class="truncate">{{item.title | titlecase}}</span>
                        </div>
                    </div>
                    <div class="text-sm grow truncate-box mt-2 text-ellipsis overflow-hidden">
                        <div class="text-overflow-ellipsis note-space" [innerHTML]="getSanitizedHtml(item.note_text)"></div>
                    </div>
                </div>

            </ktd-grid-item>
        </ktd-grid>
    </div>
</div>

<ng-template appModalOverlay #tplAddSoNote="appModal">
    <app-add-so-note [inputData]="selectedNote" [idSearchObject]="idSearchObject"
        (noteUpdate)="onNoteUpdate($event)"></app-add-so-note>
</ng-template>