import { Component, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DialogService } from '../../../../../core/services/dialog.service';
import { SimpleDocPickerComponent } from '../../../simple-doc-picker/simple-doc-picker.component';
import { AssistantChatService } from '../../services/assistant-chat.service';
import { UisrAuthService } from 'src/app/core/services/uisr-auth.service';

@UntilDestroy()
@Component({
  selector: 'app-chat-thread',
  templateUrl: './chat-thread.component.html',
  styleUrls: ['./chat-thread.component.scss'],
})
export class ChatThreadComponent {
   
  private readonly _authService = inject(UisrAuthService);
  private readonly _dialogService = inject(DialogService);

  constructor(
    public assistantChatService: AssistantChatService
  ) {}
  
  /**
   * Abre el modal del selector de documentos simple y obtiene los ids de los archivos seleccionados
   */
  viewDocs() {
    this._dialogService.openDialog(SimpleDocPickerComponent, {
      data: {
        prompt: 'Selecciona archivos (.PDF) para agregar a la conversación',
        extensions: ['.pdf']
      }
    }).subscribe((docs: any) => {
      if (docs) {
        this.assistantChatService.addDocs(docs);
      }
    })
  }

  /** Determina si la opción de agregar documentos está disponible */
  canAddFiles() {
    const settings = this._authService.assistantSettings.getValue();
    return !settings || !settings.idAssistantSettings || settings.allowAccess;
  }
}
