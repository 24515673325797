import { Action } from '@ngrx/store';
import {
  SearchFilterActions,
  SearchFilterActionsTypes,
} from './search.actions';
import {
  SearchFilterCJF,
  SearchFilterLeyes,
  SearchFilterSCJN,
} from '../../interfaces/filter-search.interface';

export interface SearchFilterState {
  SelectSourcesType: string;
  SearchText: string;
  scjnFilter: SearchFilterSCJN;
  cjfFilter: SearchFilterCJF;
  leyesFilter: SearchFilterLeyes;
  todasFilter: any;
}

export const initialSearchFilterState: SearchFilterState = {
  SelectSourcesType: 'CJF',
  SearchText: '',
  scjnFilter: {
    index: null,
    año: '',
    "precedente.asunto": '',
    epoca: '',
    header: '',
    documentType: ['tesis', 'voto', 'ejecutoria'],
    tipo: '',
    instancia: '',
    storedSearch: '',
    materia: '',
    numero: '',
    config: {
      id: 'custom',
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: 0,
    },
    searchResults: [],
  } as SearchFilterSCJN,
  cjfFilter: {
    _id: '',
    numExpediente: '',
    estado: '',
    ciudad: '',
    asunto: '',
    materia: '',
    subMateria: '',
    circuito: '',
    organo: '',
    tipoOrgano: '',
    especialidadOrgano: '',
    magistrados: '',
    actosReclamados: '',
    actosReclamadosEspecificos: '',
    sentidoSentencia: '',
    downloadedPDF: true,
    storedSearch: '',
    fechaPresentacion: '',
    fechaSentencia: '',
    config: {
      id: 'custom',
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: 0,
    },
    searchResults: [],
  } as SearchFilterCJF,
  leyesFilter: {
    date: '',
    topic: '',
    storedSearch: '',
    config: {
      id: 'custom',
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: 0,
    },
    searchResults: [],
  } as SearchFilterLeyes,
  todasFilter: {} as any,
};

export function SearchReducer(
  state = initialSearchFilterState,
  action: Action
) {
  const tutorialAction = action as SearchFilterActions;
  switch (tutorialAction.type) {
    case SearchFilterActionsTypes.SelectSourcesTypeSave:
      return {
        ...state,
        SelectSourcesType: tutorialAction.payload,
      };

    case SearchFilterActionsTypes.SelectSourcesTypeClear:
      return {
        ...state,
        SelectSourcesType: 'CJF',
      };
    case SearchFilterActionsTypes.scjnFilterSave:
      return {
        ...state,
        scjnFilter: tutorialAction.payload,
      };
    case SearchFilterActionsTypes.cjfFilterSave:
      return {
        ...state,
        cjfFilter: tutorialAction.payload,
      };
    case SearchFilterActionsTypes.leyesFilterSave:
      return {
        ...state,
        leyesFilter: tutorialAction.payload,
      };
    case SearchFilterActionsTypes.SearchTextSave:
      return {
        ...state,
        SearchText: tutorialAction.payload,
      };
    case SearchFilterActionsTypes.SelectValueFilterClear:

      return {
        ...state,
        SelectSourcesType: 'CJF',
        SearchText: '',
        scjnFilter: initialSearchFilterState.scjnFilter,
        cjfFilter: initialSearchFilterState.cjfFilter,
        leyesFilter: initialSearchFilterState.leyesFilter,
      };
    case SearchFilterActionsTypes.clearFilter:
      if (tutorialAction.payload === 'CJF') {
        return {
          ...state,
          cjfFilter: initialSearchFilterState.cjfFilter,
        }
      } else if (tutorialAction.payload === 'SCJN') {
        return {
          ...state,
          scjnFilter: initialSearchFilterState.scjnFilter,
        }
      }
      else if (tutorialAction.payload === 'Leyes') {
        return {
          ...state,
          leyesFilter: initialSearchFilterState.leyesFilter,
        }
      } else {
        return {
          ...state,
        }
      }

    default: {
      return state;
    }
  }
}
