import { Action } from '@ngrx/store';
import { AccessToken, Permission, UserData } from '../../models/user-data';
export enum UserDataActionsTypes {
  UserData = '[User Data] UserData Data',
  LocalStorage = '[User Data] UserData LocalStorage',
  SimpleLocalStorage = '[User Data] UserData SimpleLocalStorage',
  SaveToken = '[Save Token] Save Token',
  Logout = '[Logout] Logout',
}

export class UserDataSave implements Action {
  readonly type = UserDataActionsTypes.UserData;
  constructor(public payload: { UserData: UserData }) {}
}

export class UserDataLocalStorage implements Action {
  readonly type = UserDataActionsTypes.LocalStorage;
}

export class SimpleUserDataLocalStorage implements Action {
  readonly type = UserDataActionsTypes.SimpleLocalStorage;
}
export class SaveToken implements Action {
  readonly type = UserDataActionsTypes.SaveToken;
  constructor(public payload: { AccessToken: AccessToken }) {}
}

export class Logout implements Action {
  readonly type = UserDataActionsTypes.Logout;
  constructor() {}
}

export type UserDataActions =
  | UserDataSave
  | UserDataLocalStorage
  | SimpleUserDataLocalStorage
  | SaveToken
  | Logout;
