<div
    class="bg-pearl-100 rounded shadow-lg overflow-hidden w-[50rem] max-w-[90%] max-h-[85svh] m-auto p-4 flex flex-col gap-3">
    <div class="pb-1 border-b bg-pearl-100   border-slate-200   sticky top-0">
        <div class="flex justify-between items-center">
            <div class="font-semibold text-primary-100">{{ (isEdit ? 'VIEW_OR_EDIT_NOTE' : 'ADD_NOTE') | translate }}
            </div>
            <button class="text-slate-400 hover:text-slate-500 close-overlay-directive">
                <div class="sr-only">{{ 'CLOSE_STRING' | translate }}</div>
                <i class="fa-duotone fa-xmark error-icon"
                    style="font-size:1rem; --fa-secondary-opacity: 1 !important;"></i>
            </button>
        </div>
    </div>
    <div class="flex gap-2">
        <input type="text" class="form-input grow" [(ngModel)]="OnlyNote.title"
            placeholder="Por favor ingresa un titulo para tu nota" [disabled]="isEditChange">
        <div class="relative shrink min-w-[2rem] grid place-content-center">
            <button (click)="showColorsMenu()" type="button" cdkOverlayOrigin #colorTrigger="cdkOverlayOrigin" class="flex items-center rounded-full px-2 w-8 h-8 min-h-[2rem] min-w-[2rem] {{selectedOption}}" aria-haspopup="true" ngxTippy [ngxTippy]="'Selecciona un color para tu nota'">
            </button>
        </div>
        <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="colorTrigger" [cdkConnectedOverlayOpen]="showColors" (overlayOutsideClick)="menuOutsideClick($event, colorTrigger)">
            <div class="mt-1 border border-slate-200 bg-pearl-100 rounded shadow-lg overflow-hidden p-2  min-w-[2rem] flex flex-col items-center justify-center gap-2 animate__animated animate__fadeIn animate__faster">
                <div *ngFor="let option of options" class="grid place-content-center w-6 h-6 min-h-[1.5rem] cursor-pointer min-w-[1.5rem] rounded-full {{option.value}}" (click)="selectColor(option.value)">
                    <i *ngIf="selectedOption == option.value" class="fa-duotone fa-check primary-icon" style="font-size: 0.7rem;"></i>
                </div>
            </div>
        </ng-template>
        <button *ngIf="isEdit" class="uisr-secondary-button" (click)="changeEdit()">{{ isEditChange ?
            ( 'EDIT_NOTE' | translate | titlecase ): ('SEE_NOTE' |
            translate | titlecase) }}</button>
    </div>

    <ckeditor [ngClass]="selectedOption.split('bg-')" [editor]="Editor" [config]="config" [disabled]="isEditChange" [(ngModel)]="OnlyNote.note_text">
    </ckeditor>

    <div class="pt-3 border-t bg-pearl-100 border-slate-200 sticky bottom-0 flex justify-between gap-2">
        <button *ngIf="isEdit" class="uisr-secondary-button text-rose-600 hover:text-red-600" (click)="deleteNote()">{{"DELETE_STRING"|translate}}</button>
        <div class="flex justify-end gap-2">
            <button class="uisr-secondary-button close-overlay-directive" [loadingElement]="resources.addSoNote"
                [disableOnly]="true">{{ 'CANCEL_STRING' | translate | titlecase}}</button>
            <button [disabled]="isEditChange" (click)="onSubmit()" class="uisr-primary-button" [loadingElement]="resources.addSoNote">{{ (isEdit ?
                'SAVE_CHANGES_STRING' : 'ADD_STRING') | translate }}</button>
        </div>
    </div>
</div>