import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CKEditorComponent, ChangeEvent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/es';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { Subject, finalize, lastValueFrom, takeUntil } from 'rxjs';
import { ALERT_DEFAULTS } from 'src/app/core/constants/alert-defaults.constants';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import {
  CK_EDITOR_NOTES_CONFIG,
  NOTE_COLOR_OPTIONS,
} from 'src/app/features/law-firm/constants/law-firm.constants';
import { UisrApiService } from 'src/app/shared/services/uisr-api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-so-note',
  templateUrl: './add-so-note.component.html',
  styleUrls: ['./add-so-node.component.scss'],
})
export class AddSoNoteComponent implements OnInit, OnChanges {
  isEdit = false;
  isEditChange = false;
  callEditFirstTime = true;
  resources = RESOURCES;
  showColors = false;
  messages: any = {};
  options = NOTE_COLOR_OPTIONS;
  selectedOption: any = this.options[0].value;
  lastData: string = '';

  public Editor = ClassicEditor;
  public config = CK_EDITOR_NOTES_CONFIG;
  private unsubscribe = new Subject<void>();

  @Input() idSearchObject: number | null = null;
  @Input() inputData: any = {
    title: null,
    note_text: null,
    id_notes_search_object: null,
  };
  OnlyNote: any = {
    title: null,
    note_text: null,
    id_notes_search_object: null,
  };

  @Output() noteUpdate: EventEmitter<any> = new EventEmitter(false);

  @ViewChild('editorTag') editorComponent!: CKEditorComponent;

  constructor(
    private translateService: TranslateService,
    private apiService: UisrApiService,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    this.messages = await this.getMessages();
  }

  async getMessages() {
    let messages: any = await lastValueFrom(
      this.translateService.get([
        'GENERIC_ERROR_MESSAGE',
        'SUCCESSFUL_NOTE_CREATION',
        'SUCCESSFUL_NOTE_UPDATE',
        'GENERIC_FORM_ERROR_NOTE',
        'DELETING_ENTRY_NOTE',
        'DELETE_NOTE_QUESTION',
        'NOTE_DELETED_SUCCESSFULLY',
      ])
    );

    return messages;
  }

  public onChange({ editor }: ChangeEvent) {
    const position =
      this.editorComponent.editorInstance!.model.document.selection.getFirstPosition();

    const data = editor.data.get();
    if (this.lastData == data) return;
    if (!data.match(/<a\s+(?:[^>]*?\s+)?href="([^"]*)"/g)) return;
    const regex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"/g;

    let updatedString = data;
    let replacements = 0;
    
    updatedString = updatedString.replace(regex, (match, href) => {
      const updatedHref = this.ensureHttps(href);
      if (updatedHref !== href) {
        replacements++;
      }
      return `<a href="${updatedHref}"`;
    });

    if (replacements > 0) {
      editor.data.set(updatedString);
      this.lastData = editor.data.get();
      setTimeout(() => {
        this.editorComponent.editorInstance!.model.change((writer) => {
          const range = writer.createRange(position!);
          writer.setSelection(range.end);
        });
      }, 10);
    }
  }

  ensureHttps(url: string) {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    } else {
      return 'https://' + url;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isEdit =
      changes &&
      changes['inputData'] &&
      changes['inputData'].currentValue?.id_notes_search_object;
    if (this.isEdit) {
      this.OnlyNote = Object.assign({}, this.inputData);
      this.selectedOption = this.inputData.colour_note || this.options[0].value;
    }
    if (this.callEditFirstTime) {
      this.isEditChange = this.isEdit;
      this.callEditFirstTime = false;
    }
  }

  onSubmit() {
    if (
      this.OnlyNote.note_text == null ||
      this.OnlyNote.note_text == '' ||
      this.OnlyNote.title == '' ||
      this.OnlyNote.title == null
    ) {
      this.toastr.error(this.messages.GENERIC_FORM_ERROR_NOTE);
      return;
    }
    this.apiService.resource = this.resources.addSoNote;
    let data = {
      fk_id_object: this.idSearchObject,
      ...this.OnlyNote,
    };
    data.colour_note = this.selectedOption;
    this.apiService
      .put(data, '', ['button'])
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => (this.apiService.resource = ''))
      )
      .subscribe({
        next: (res: any) => {
          if (!this.isEdit) {
            data.id_notes_search_object = res.ID;
            let message = this.messages.SUCCESSFUL_NOTE_CREATION;
            data.created_at = DateTime.now().toFormat('yyyy-MM-dd');
            data.colour_note = this.selectedOption;
            this.toastr.success(message);
          }
          this.noteUpdate.emit(data);
        },
      });
  }

  selectColor(color: string) {
    this.selectedOption = color;
    this.showColors = false;
  }

  showColorsMenu() {
    if (!this.isEditChange) {
      this.showColors = !this.showColors;
    }
  }

  menuOutsideClick(event: MouseEvent, origin: CdkOverlayOrigin): void {
    if (event.target == origin.elementRef.nativeElement) {
      return;
    }
    this.showColors = false;
  }

  changeEdit() {
    this.isEditChange = !this.isEditChange;
  }

  deleteNote() {
    Swal.fire({
      ...(ALERT_DEFAULTS as any),
      ...{
        html: this.messages.DELETE_NOTE_QUESTION,
        showCancelButton: true,
        icon: 'question',
        title: null,
      },
    }).then((res) => {
      if (res.isConfirmed) {
        this.noteUpdate.emit({ ...this.inputData, isDelete: true });
      }
    });
  }
}
