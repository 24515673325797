import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';
import { RESOURCES } from '../../constants/resource-service.constants';
import { UserData } from '../../models/user-data';
import { JsEncode } from '../../utils/js-encode';
import {
  UserDataActionsTypes,
  UserDataLocalStorage,
  UserDataSave,
} from './user-data.actions';

@Injectable()
export class UserDataEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
  ) {}

  public resources = RESOURCES;

  loadUserData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserDataLocalStorage>(UserDataActionsTypes.LocalStorage),
      map(() => {
        if (localStorage.getItem('UserData')) {
          let localData = JsEncode.decrypt(localStorage.getItem('UserData'));
          let dataUser: UserData = JSON.parse(localData);

          return new UserDataSave({
            UserData: dataUser,
          });
        } else {
          this.router.navigate(['security/login']);
          localStorage.removeItem('UserData');
          return new UserDataSave({
            UserData: {} as UserData,
          });
        }
      })
    );
  });
}
