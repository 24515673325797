<!-- Body -->
<div class="grow overflow-hidden relative flex items-end">
    
</div>

<!-- Footer -->
<div>
    <div class="flex items-center justify-between bg-white border-slate-200 px-4 sm:px-6 md:px-5 py-3">

        <!-- Add Docs -->
        <button class="uisr-primary-button mr-3 p-0 border-none rounded-full" disabled>
            <svg class="w-6 h-6 fill-current" viewBox="0 0 24 24">
                <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.98 5.38 18.62.02 12 0zm6 13h-5v5h-2v-5H6v-2h5V6h2v5h5v2z"></path>
            </svg>
        </button>
    
        <!-- Message input -->
        <app-chat-input></app-chat-input>

    </div>
</div>