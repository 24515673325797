<div *appValidateAccess="menuItem.permission" class="my-[0.3rem]">
    <a ngxTippy [tippyProps]="{allowHTML: true}"
        [routerLink]="menuItem.disabled || !sidebarExpanded ? null : menuItem.url"
        [ngxTippy]="hasAllItemsDisabled(menuItem) ? 'Este contenido estará disponible <br> próximamente' : null"
        role="button"
        class="truncate py-[0.325rem] flex items-center justify-center sidebar-expanded:justify-between px-3 sidebar-expanded:border-l-3 border-transparent w-full"
        [ngClass]="{'!border-primary-100': getParentStatus() && sidebarExpanded}" (mouseover)="onHover(true)"
        (mouseleave)="onHover(false)">
        <button [ngClass]="{'justify-center': !sidebarExpanded}" class="flex flex-grow items-center gap-x-2"
            (click)="sidebarExpanded ? navigateFromParent(menuItem) : navigateFromParent(menuItem)">
            <div class="w-6 h-6 grid place-content-center">
                <i class="fa-duotone" [ngClass]="menuItem.icon" [ngStyle]="showActiveStyle() ? activeIcon: inactiveIcon"
                    style="font-size: 1.5rem;"></i>
            </div>
            <span [ngClass]="{'hidden': !sidebarExpanded}"
                class="text-[0.95rem] leading-none flex items-center font-medium opacity-0 sidebar-expanded:opacity-100 duration-150 text-slate-200">
                {{ menuItem.label | translate | titlecase }}
            </span>
        </button>
        <!-- Solo mostrar el botón de la flecha si el sidebar está expandido -->
    </a>
    <button *ngIf="menuItem.children && menuItem.children.length > 0 && sidebarExpanded"
        (click)="toggleMenuItem(menuItem, $event)" class="-mt-[1.65rem] ml-auto flex items-center justify-center">
        <i class="fa-light fa-angle-down transition-transform duration-150 ease-in-out"
            [ngClass]="menuItem.status ? 'rotate-0' : 'rotate-90'" style="font-size: 1.5rem;"></i>
    </button>
    <div *ngIf="menuItem.children && menuItem.children[0]" (mouseover)="onHover(true)" (mouseleave)="onHover(false)">
        <ul class="overflow-hidden"
            [ngClass]="{'hidden': !menuItem.status && sidebarExpanded, 'block': !sidebarExpanded}"
            #menuChildrenContainer>
            <ng-container *ngFor="let menuChild of menuItem.children; let i = index; trackBy: trackByFn">
                <ng-container *appValidateAccess="menuChild.permission">
                    <ng-container
                        *ngIf="(userData?.idWorkspace || userData?.id_workspace_member || menuChild.public) && showChild(menuChild)">
                        <!-- Mostrar ícono más pequeño si el menú está colapsado y es 'Despacho' -->
                        <li *ngIf="!sidebarExpanded ? menuItem.label === 'LAW_FIRM_STRING' : true"
                        [ngClass]="{'opacity-50': menuChild.disabled, 'mb-4': menuChild.divider}" ngxTippy [tippyProps]="{allowHTML: true}"
                        [ngxTippy]="menuChild.tooltip ? menuChild.tooltip : null" role="button">
                        <a role="button" [routerLink]="menuChild.disabled ? null : menuChild.url"
                            class="truncate py-[0.325rem] flex justify-center sidebar-expanded:justify-between items-center sidebar-expanded:border-l-3 border-transparent text-slate-400 sidebar-expanded:pl-11"
                            (mouseup)="trackChildClick(menuChild)" [ngClass]="{'bg-transparent': !sidebarExpanded}"
                            [routerLinkActive]="['!border-primary-100', 'bg-mirage', 'menu-item-active-style']">
                            <div *ngIf="!sidebarExpanded && showIcon(menuItem)" class="size-5 flex items-center justify-center">
                                <i class="fa-solid" [ngClass]="menuChild.icon"></i>
                            </div>
                            <div class="grow relative text-[0.95rem] flex font-medium duration-150" *ngIf="sidebarExpanded">
                                {{ menuChild.label | translate }}
                            </div>
                            <div *ngIf="menuChild.hasUpdate && sidebarExpanded" class="flex flex-shrink-0 mr-2">
                                <span
                                    class="flex items-center justify-center text-[0.7rem] font-medium bg-primary-100 text-pearl-100 dark:text-pearl-100 dark:bg-primary-100 px-1 rounded">Nuevo</span>
                            </div>
                        </a>
                    </li>                    
                    </ng-container>
                </ng-container>
            </ng-container>
        </ul>
    </div>
</div>