import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BodyLoading } from '../interfaces/blockType.interface';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public loadingServices = new BehaviorSubject<BodyLoading>(new BodyLoading());
  constructor() { }


}
