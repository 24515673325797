import Step from 'shepherd.js/src/types/step';
import * as Sp from 'shepherd.js';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const LIBRARY_STEPS: Step.StepOptions[] = [
   {
    attachTo: {
        element: document.getElementById('lb4') || '#lb4',
        on: 'top',
    },
    scrollTo: true,
    showOn() {
        return (document.getElementById('lb4') || false) as boolean;
    },
    title: 'Bienvenido a Biblioteca',
    text: `<p>Encontrarás acá la sección de blog, listado de audiencias y plantillas.</p>`,
  },
  {
    attachTo: {
        element: document.getElementById('lb1') || '#lb1',
        on: 'right',
    },
    showOn() {
        return (document.getElementById('lb1') || false) as boolean;
    },
    title: 'Listados',
    text: `<p>Aquí puedes consultar listados relevantes para tus asuntos, como listados de acuerdos o audiencias recientes.</p>`,
  },
  {
    attachTo: {
        element: document.getElementById('lb2') || '#lb2',
        on: 'bottom',
    },
    showOn() {
        return (document.getElementById('lb2') || false) as boolean;
    },
    title: 'Blog',
    text: `<p>Aquí puedes crear publicación del blog, consultar tus publicaciones y leer publicaciones de los demás.</p>`,
  },
  {
    attachTo: {
        element: document.getElementById('lb3') || '#lb3',
        on: 'bottom',
    },
    showOn() {
        return (document.getElementById('lb3') || false) as boolean;
    },
    title: 'Plantillas',
    text: `<p>Aquí puedes gestionar tus plantillas.</p>`,
  },
]