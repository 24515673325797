import { SweetAlertOptions } from 'sweetalert2';

export const ALERT_DEFAULTS: SweetAlertOptions = {
  icon: 'error',
  title: '¡Error!',
  confirmButtonText: 'Aceptar',
  cancelButtonText: 'Cancelar',
  allowOutsideClick: true,
  customClass: {
    popup: 'bg-pearl-100 text-slate-700',
    confirmButton: 'uisr-primary-button',
    cancelButton: 'ml-2 uisr-secondary-button',
    input: 'text-center',
  },
  buttonsStyling: false,
};
