import { Injectable } from '@angular/core';
import { UisrAuthService } from '../services/uisr-auth.service';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AccessGuard {
  constructor(private authService: UisrAuthService, private router: Router) {}

  async canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return true;
    setTimeout(() => {
      const permission = route.data['permission'];
      if (!permission) return true;
      if (!this.authService.hasAccess(permission)) {
        this.redirect();
        return false;
      }
      return true;
    }, 0);
  }

  redirect() {
    this.router.navigate(['main']);
    Swal.fire({
      toast: true,
      icon: 'warning',
      html: `<span class='font-semibold'>¡Sin Acceso!</span> No tienes acceso a esta ruta.`,
      position: 'top-right',
      showConfirmButton: false,
    });
  }
}
