import { Component, EventEmitter, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-chat-empty',
  templateUrl: './chat-empty.component.html',
  styleUrls: ['./chat-empty.component.scss'],
})
export class ChatEmptyComponent {
  @Output() createThread = new EventEmitter<string>();

  shouldReset: boolean = true;

  /**
   * Direccionar prompt a crear una nueva conversación
   */
  hookPromptMessage(prompt: string) {
    this.createThread.emit(prompt);
  }
}
