import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { PAGINATION_CONFIG } from 'src/app/features/law-firm/constants/pagination.constants';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [CommonModule, NgxPaginationModule, TranslateModule],
  templateUrl: './pagination.component.html',
})
export class PaginationComponent {
  @Input({ required: true }) paginationConfig = structuredClone(PAGINATION_CONFIG);
  @Input() visiblePages = 5;

  @Output() pageChange = new EventEmitter();

  onPageChange(page: number) {
    this.paginationConfig.currentPage = page;
    this.pageChange.emit(page);
  }

  getUpperPaginationLimit() {
    if (this.paginationConfig) {
      return Math.min(
        this.paginationConfig.currentPage * this.paginationConfig.itemsPerPage,
        this.paginationConfig.totalItems!
      );
    }
    return 0;
  }
}
