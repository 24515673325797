import { Action } from '@ngrx/store';
import { SearchFilterCJF, SearchFilterLeyes, SearchFilterSCJN } from '../../interfaces/filter-search.interface';
export enum SearchFilterActionsTypes {
    SelectSourcesTypeSave = '[SearchFilter] SelectSourcesType Save',
    scjnFilterSave = '[scjnFilter] scjnFilter Save',
    cjfFilterSave = '[cjfFilter] cjfFilterSave',
    leyesFilterSave = '[leyesFilter] leyesFilter Save',
    SelectSourcesTypeClear = '[SearchFilter] SelectSourcesType Clear',
    SelectValueFilterClear = '[SearchFilter] SelectValueFilter Clear',
    SearchTextSave = '[SearchFilter] SearchText Save',
    clearFilter = '[SearchFilter] Clear Filter'
}

export class SelectSourcesTypeSave implements Action {
    readonly type = SearchFilterActionsTypes.SelectSourcesTypeSave;
    constructor(public payload: string) { }
}
export class scjnFilterSave implements Action {
    readonly type = SearchFilterActionsTypes.scjnFilterSave;
    constructor(public payload: SearchFilterSCJN) { }
}
export class cjfFilterSave implements Action {
    readonly type = SearchFilterActionsTypes.cjfFilterSave;
    constructor(public payload: SearchFilterCJF) { }
}
export class leyesFilterSave implements Action {
    readonly type = SearchFilterActionsTypes.leyesFilterSave;
    constructor(public payload: SearchFilterLeyes) { }
}
export class SearchTextSave implements Action {
    readonly type = SearchFilterActionsTypes.SearchTextSave;
    constructor(public payload: string) { }
}

export class SelectSourcesTypeClear implements Action {
    readonly type = SearchFilterActionsTypes.SelectSourcesTypeClear;
}

export class SelectValueFilterClear implements Action {
    readonly type = SearchFilterActionsTypes.SelectValueFilterClear;
}

export class ClearFilter implements Action {
    readonly type = SearchFilterActionsTypes.clearFilter;
    constructor(public payload: string) { }
}

export type SearchFilterActions = SelectSourcesTypeSave | SelectSourcesTypeClear | scjnFilterSave | leyesFilterSave | SearchTextSave | SelectValueFilterClear | cjfFilterSave | ClearFilter ;
