import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Host,
  Input,
  OnChanges,
  Optional,
  Renderer2,
  Self,
  SimpleChanges,
} from '@angular/core';
import { ComponentBlocker } from 'src/app/core/utils/component-blocker';
import { InputGenericComponent } from '../components/input-generic/input-generic.component';

@Directive({
  selector:
    'input[loadingElement], button[loadingElement], app-input-generic[loadingElement], app-autocomplete-select[loadingElement], app-autocomplete-multi-select[loadingElement]',
  standalone: true,
})
export class LoadingElementDirective implements AfterViewInit, OnChanges {
  buttonText: string = '';
  typeTag: string = '';

  @Input() initialDisableState?: boolean;
  @Input('loadingElement') loadingElement: string | string[] = '';
  @Input('disableOnly') disableOnly: boolean = false;
  @Input('spinnerOnly') spinnerOnly: boolean = false;

  constructor(
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private render: Renderer2,
    public componentBlocker: ComponentBlocker,
    @Host()
    @Self()
    @Optional()
    public InputGenericComponent: InputGenericComponent
  ) {
    this.typeTag = this.el.nativeElement.outerHTML.includes('input')
      ? 'input'
      : this.el.nativeElement.outerHTML.includes('button')
      ? 'button'
      : '';
    switch (this.typeTag) {
      case 'input':
        this.componentBlocker.loaderAll.subscribe((el) => {
          let filterData = el.form.paths.filter(
            (el2: string) => el2 == this.loadingElement
          );
          if (
            (el.form.count > 0 && filterData.length > 0) ||
            (this.loadingElement == '' && el.form.count > 0)
          ) {
            this.InputGenericComponent.ngControl.control?.disable();
          } else {
            this.InputGenericComponent.ngControl.control?.enable();
          }
        });
        break;
      case 'button':
        this.componentBlocker.loaderAll.subscribe((el) => {
          let filterData = el.button.paths.filter(
            (el2: string) =>
              el2 == this.loadingElement || this.loadingElement.includes(el2)
          );
          if (
            (el.button.count > 0 && filterData.length > 0) ||
            (this.loadingElement == '' && el.button.count > 0)
          ) {
            this.render.setProperty(this.el.nativeElement, 'disabled', true);
            if (!this.disableOnly && this.buttonText) {
              const loadingText = this.spinnerOnly ? '' : 'Cargando';

              const loadingHTML = `
                ${loadingText}
                <svg class="animate-spin w-4 h-4 fill-current shrink-0 ml-2" viewBox="0 0 16 16">
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              `;
              this.render.setProperty(
                this.el.nativeElement,
                'innerHTML',
                loadingHTML
              );
            } else if (!this.disableOnly) {
              this.render.setProperty(
                this.el.nativeElement,
                'disabled',
                this.initialDisableState
              );
            }
          } else if (this.buttonText) {
            this.render.setProperty(
              this.el.nativeElement,
              'disabled',
              this.initialDisableState
            );
            this.render.setProperty(
              this.el.nativeElement,
              'innerHTML',
              this.buttonText
            );
          }
        });
        break;

      default:
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.el.nativeElement.disabled !== this.initialDisableState) {
      this.cdr.detectChanges();
      this.initialDisableState = this.el.nativeElement.disabled;
    }
  }

  ngAfterViewInit(): void {
    this.buttonText = this.el.nativeElement.innerHTML;
    if (this.initialDisableState === undefined) {
      this.initialDisableState = this.el.nativeElement.disabled;
    }
  }
}
