import { BehaviorSubject, Observable, OperatorFunction } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

// Define una interfaz para el seguimiento de los grupos de estado de carga
interface LoadingGroups {
  [key: string]: {
    observables: BehaviorSubject<number>;
    model: BehaviorSubject<boolean>
  }
}

// Objeto para almacenar los grupos de estado de carga
const loadingGroups: LoadingGroups = {};

export function loadingState<T>(model: BehaviorSubject<boolean>): OperatorFunction<T, T> {
  return (source: Observable<T>) => {

    // Obtén una clave única para el modelo
    const modelKey = getGroupNameFromModel(model) || `model-${uuidv4()}`;

    // Si el grupo no existe, se crea con un contador en 0
    if (!loadingGroups[modelKey]) {
      loadingGroups[modelKey] = {
        observables: new BehaviorSubject<number>(0),
        model: model
      }
    }

    // Incrementa el contador cuando se inicia el observable
    const incrementCounter = () => {
      loadingGroups[modelKey].observables.next(loadingGroups[modelKey].observables.value + 1);
    };

    // Decrementa el contador cuando se completa o produce un error el observable
    const decrementCounter = () => {
      loadingGroups[modelKey].observables.next(loadingGroups[modelKey].observables.value > 0 ? loadingGroups[modelKey].observables.value - 1 : 0);
    };

    // Verifica si no hay observables en curso y actualiza el modelo
    const emitModelValue = () => {
      if (loadingGroups[modelKey].observables.value === 0) {
        model.next(false);
      } else {
        model.next(true);
      }
    };

    incrementCounter();
    emitModelValue();

    return source.pipe(
      tap({
        finalize: decrementCounter,
      }),
      finalize(() => {
        emitModelValue();
      })
    );
  };
}

// Función para obtener el nombre del grupo a partir del modelo
function getGroupNameFromModel(model: BehaviorSubject<boolean>): string | undefined {
  for (const key in loadingGroups) {
    if (loadingGroups[key].model === model) {
      return key;
    }
  }
  return undefined;
}
