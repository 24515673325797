import { CdkMenuTrigger } from '@angular/cdk/menu';
import {
  Component,
  ElementRef,
  Input,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { IOptions } from '../../interfaces/autocomplete-options.interface';

@Component({
  selector: 'app-autocomplete-select',
  templateUrl: './autocomplete-select.component.html',
})
export class AutocompleteSelectComponent implements ControlValueAccessor {
  @Input() options: IOptions[] = [];
  @Input() formControlName = '';
  @Input() label: string | null = 'Elige una opción';
  @Input() disableFilter: boolean = false;
  @Input() showIcon: boolean = false;
  @Input() disabled = false;
  @Input() placeholder = '';

  keyValue: any = '';
  optionSelected: any = '';

  onChange = (value: number) => {};

  onTouched = () => {};

  touched = false;

  @ViewChild('inputText', { static: false }) inputText!: ElementRef;
  @ViewChild(CdkMenuTrigger) menuTrigger!: CdkMenuTrigger;

  uniqueId = '';

  onScroll() { 
    // Check if the menu is open
    if (this.menuTrigger && this.menuTrigger.isOpen()) {
      // Close the menu
      this.menuTrigger.close();
    }
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private eRef: ElementRef
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  keypress(event: any) {
    if (event == '') {
      this.ngControl.control?.setValue('');
    }
    if (event != this.optionSelected) {
      this.ngControl.control?.setValue('');
      this.optionSelected = '';
    }
  }

  onChangeValue = (event: any) => {
    if (event.label != this.optionSelected) {
      this.optionSelected = event.label;
    }
    this.markAsTouched();
    if (!this.disabled) {
      this.onChange(event.value);
    }
  };

  selectOption(item: any) { 
    if (!item.disabled) {
      this.optionSelected = item.label;
      if (this.keyValue === item.value) return;
      this.writeValue(item);
    }
  }

  writeValue(quantity: any) {
    if (quantity) {
      let value = this.options.filter(
        (element) => element.value == quantity.value
      );
      if (value.length > 0) this.keyValue = value[0].label;
      this.onChangeValue(quantity);
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  onMenuFocus(event: FocusEvent) { 
    event.preventDefault();
    const inputElement = document.getElementById(this.uniqueId);
    if (inputElement) {
      inputElement.focus();
    }
  }

  ngAfterViewInit(): void {
    this.uniqueId = `input_${uuidv4()}`;
    this.inputText.nativeElement.setAttribute('id', this.uniqueId);
  }

  onButtonKeydown(event: KeyboardEvent) { 
    if (event.code == 'Space') {
      event.stopPropagation();
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  onKeydown(event: KeyboardEvent) { 
    if (event.key === ' ') {
      event.stopPropagation();
    }
  }
}
