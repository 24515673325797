import { PaginationInstance } from "ngx-pagination";
import { IOptions } from "src/app/shared/interfaces/autocomplete-options.interface";

export const PAGE_SIZE_OPTIONS: IOptions[] = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
];

export const PAGINATION_CONFIG: PaginationInstance = {
  itemsPerPage: 10,
  currentPage: 1,
  totalItems: 0, 
};