import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UisrAuthService } from '../services/uisr-auth.service';

@Directive({ selector: '[appValidateAccess]', standalone: true })
export class ValidateAccessDirective {
  private _action: string | null | undefined;
  private _thenTemplateRef: TemplateRef<any>;
  private _thenViewRef: any;

  constructor(
    templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _authService: UisrAuthService
  ) {
    this._thenTemplateRef = templateRef;
    this._authService.permissions.subscribe(() => this._updateView());
  }

  @Input()
  set appValidateAccess(action: string | null | undefined) {
    this._action = action;
    this._updateView();
  }

  _updateView() {
    if (!this._action || this._authService.hasAccess(this._action)) {
      if (!this._thenViewRef) {
        this._viewContainer.clear();
        if (this._thenTemplateRef) {
          this._thenViewRef = this._viewContainer.createEmbeddedView(
            this._thenTemplateRef
          );
        }
      }
    } else {
      this._viewContainer.clear();
      this._thenViewRef = null;
    }
  }
}
