<label class="block text-sm font-medium mb-1 text-slate-500" *ngIf="(seeInput | async) && showLabel"
    [for]="objectConfig.name">
    {{objectConfig.label}} <span *ngIf="requiredCheck" class="text-rose-500">*</span>
</label>
<div class="relative flex items-center">
    <input (click)="onClick()" *ngIf="(seeInput | async) && !isTextArea" [id]="objectConfig.name"
        [name]="objectConfig.name" #genericInput [style]="lineBoxShadow()" [ngClass]="{
            'capitalize': objectConfig.name == 'first_name' || objectConfig.name == 'last_name',
            'border-rose-300' : ngControl.control?.errors && ngControl.control?.touched,
            '!border-slate-200 !bg-slate-100 text-slate-400 !cursor-not-allowed' : returnDisabled(), 
             '!pr-[3.75rem]' : hasSuffix, '!pr-[5.5rem]' : hasExtendedSuffix
            }" [class]="checkTypeInput(objectConfig.type)" [(ngModel)]="model" (ngModelChange)="onModelChange($event)"
        [type]="objectConfig.type"
        [placeholder]="objectConfig.placeholder ? (objectConfig.placeholder | translate) : ''"
        [disabled]="returnDisabled()" />

    <textarea (click)="onClick()" *ngIf="(seeInput | async) && isTextArea" [id]="objectConfig.name"
        [name]="objectConfig.name" #genericInput [style]="lineBoxShadow()" [ngClass]="{
            'capitalize': objectConfig.name == 'first_name' || objectConfig.name == 'last_name',
            'border-rose-300' : ngControl.control?.errors && ngControl.control?.touched,
            '!border-slate-200 !bg-slate-100 text-slate-400 !cursor-not-allowed' : returnDisabled(), 
             '!pr-[3.75rem]' : hasSuffix, '!pr-[5.5rem]' : hasExtendedSuffix
            }" [class]="checkTypeInput(objectConfig.type)" [(ngModel)]="model" (ngModelChange)="onModelChange($event)"
        [placeholder]="objectConfig.placeholder ? (objectConfig.placeholder | translate) : ''"
        [disabled]="returnDisabled()">

        </textarea>


    <i class="fa-brands fa-cc-visa ml-3 text-center text-slate-500  text-[25px] w-fit"
        *ngIf="objectConfig.formatCleave  == 'cardNumber' && cardType != ''" [class]="CheckTypeCard().type"></i>
    <div class="absolute inset-0 left-auto flex items-center" *ngIf="objectConfig.type == 'password'">
        <span (click)="controlsService.changeInputType(genericInput?.nativeElement, passwordIcon)"
            class="text-sm cursor-pointer text-slate-600 font-medium px-3 grid place-content-center">
            <ng-icon #passwordIcon name="matVisibilityOutline" size="1.25rem"></ng-icon>
        </span>
    </div>
</div>
<div *ngIf="showErrors && errorsArray[0]" class="-intro-x text-xs text-rose-600 mt-1 w-[290px]">
    {{errorsArray[0]}}
</div>