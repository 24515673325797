export interface BlockTypes {
  type:
  'global' | // Bloqueo global
  'form' | // Bloqueo formulario
  'button' // Bloqueo botones;
}

export class BodyLoading {
  global: {
    count:number,
    paths:Array<string>
  }
  form: {
    count:number,
    paths:Array<string>
  }
  button: {
    count:number,
    paths:Array<string>
  }
  constructor() {
    this.global = {
      count:0,
      paths:[]
    }
    this.form = {
      count:0,
      paths:[]
    }
    this.button = {
      count:0,
      paths:[]
    }
  }
}