import { AfterViewInit, Component, ElementRef, inject, ViewChild } from '@angular/core';
import { DateTime } from 'luxon';
import Typed from 'typed.js';
import { v4 as uuidv4 } from 'uuid';
import { AssistantChatService } from '../../services/assistant-chat.service';
import { AppTutorialService } from 'src/app/core/services/tutorial.service';

@Component({
  selector: 'app-chat-threads',
  templateUrl: './chat-threads.component.html',
  styleUrls: ['./chat-threads.component.scss'],
})
export class ChatThreadsComponent implements AfterViewInit {
  tutorialService = inject(AppTutorialService);

  @ViewChild('threadsWrapper') private threadsWrapper!: ElementRef;

  constructor(
    public assistantChatService: AssistantChatService
  ) {
    this.assistantChatService.threadsComponent = this;
  }

  ngAfterViewInit(): void {
    this.assistantChatService.fetchThreads();
  }

  /**
   * Comprobar si el listado de conversasiones debe cargar automaticamente mas conversaciones
   */
	checkThreadsScroll() {
		const container = this.threadsWrapper.nativeElement;
    
		if (container.scrollHeight <= container.clientHeight && !this.assistantChatService.loadingStates.loadingThreads.value) {
			this.assistantChatService.fetchNextThreads();
		}
	}

  /**
   * Calcular si el mensaje actual esta en el dia siguiente con respecto al anterior
   */
  isNewDayMessage(actualDate: string, previousDate: string) {

    // Prevenir error de consultar indice inexistente anterior
    // o error de los mensajes nuevos (salientes) que no tienen "created_at"
    let actualDateS = actualDate
      ? DateTime.fromISO(actualDate).toSeconds()
      : DateTime.now().toSeconds();
    let previousDateS = previousDate
      ? DateTime.fromISO(previousDate).toSeconds()
      : 1000000000;

    let aDate = DateTime.fromISO(
      DateTime.fromSeconds(actualDateS).toISODate() || ''
    );

    let pDate = DateTime.fromISO(
      DateTime.fromSeconds(previousDateS).toISODate() || ''
    );

    let sDate = aDate.setLocale('es').toFormat("cccc, dd 'de' LLLL");
    let sTime = DateTime.fromSeconds(actualDateS)
      .setLocale('en')
      .toLocaleString(DateTime.TIME_SIMPLE);

    return {
      condition: aDate > pDate,
      date: sDate,
      time: sTime,
    };
  }

}
