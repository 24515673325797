<div class="comentario-container rounded-lg shadow-sm">
    <!-- Contenedor editable que simula un textarea -->

    <div class="comment-container bg-slate-200">
        <div id="comentarioTexto" contenteditable="true" #comentarioTexto class="form-input editable text-slate-500"
            placeholder="Escribe un comentario..." (input)="procesarCambios($event)" (keyup)="checkForTag($event)">
        </div>
        <i class="fa-duotone fa-paper-plane-top cursor-pointer" (click)="sendText()" *ngIf="!(loadingSendMessage | async)"
            [ngClass]="{'cursor-pointer text-primary-100':comentarioTexto.innerText.length > 0,'cursor-not-allowed':comentarioTexto.innerText.length == 0}"
            style="font-size: 1.5rem;"></i>
        <svg class="animate-spin w-4 h-4 fill-current shrink-0 ml-2" viewBox="0 0 16 16"
            *ngIf="loadingSendMessage | async">
            <path
                d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
        </svg>

    </div>

    <div [ngClass]="{'invisible -z-60':!mostrarUsuarios || !posicionUsuariosListAjustada}"
    class="absolute right-0 w-max z-10 bg-white border border-gray-300 rounded-md p-2 list-none max-h-48 overflow-y-auto"
    #usuariosList id="usuariosList">
        <ul *ngIf="mostrarUsuarios">
            <li *ngFor="let usuario of usuariosFiltrados; let i = index" [attr.id]="'usuario-' + i"
                [ngClass]="{'bg-indigo-100 text-indigo-700' : i == usuarioSeleccionadoIndex}"
                class="p-2 hover:bg-gray-100 cursor-pointer" (click)="seleccionarUsuario(usuario)">
                <div class="flex items-center">
                    <img [src]="usuario.selfie ? usuario.selfie : '/assets/images/profile-placeholder.png'"
                        alt="Foto de usuario" class="w-8 h-8 rounded-full mr-2">
                    <span>{{ usuario.label }}</span>
                </div>
            </li>
            <li *ngIf="!usuariosFiltrados[0]">
                <span class="text-gray-500">No se encontraron resultados</span>
            </li>
        </ul>
    </div>
</div>