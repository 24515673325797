import { Pipe, PipeTransform } from '@angular/core';
import { UisrTools } from 'src/app/core/utils/uisr-tools';

@Pipe({
  name: 'extToIcon',
  standalone: true,
})
export class ExtToIconPipe implements PipeTransform {
  transform(ext: string): string {
    return UisrTools.getFileExtensionIcon(ext) || 'fa-file';
  }
}
