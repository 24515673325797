import Step from 'shepherd.js/src/types/step';

export const VIEW_LATER_STEP: Step.StepOptions = {
  attachTo: {
    element: document.getElementById('help-button') || '#help-button',
    on: 'bottom',
  },
  title: 'Repite el tutorial',
  text: `<p>Si necesitas repasar estas instrucciones, puedes hacer clic en el icono de ayuda en cualquier momento para repetir este tutorial.</p>`,
};
