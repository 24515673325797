import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgModule } from '@angular/core';
import {CdkScrollable, OverlayModule} from '@angular/cdk/overlay';
import { DialogModule } from '@angular/cdk/dialog';
import {CdkMenuModule} from '@angular/cdk/menu';
import { MenuTrigger } from 'src/app/core/directives/menu-trigger-for.directive';


const MODULES = [CdkStepperModule, OverlayModule, DialogModule, CdkMenuModule, CdkScrollable];

@NgModule({
  imports: [...MODULES, MenuTrigger],
  exports: [...MODULES, MenuTrigger],
})
export class MaterialCdkModule {}
