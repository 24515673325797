<form [formGroup]="chatForm" class="grow flex items-end">
  <div class="grow mr-3" id="assistant-chat-wrapper">
    <app-input-field
      formControlName="message"
      [inputType]="'textarea'"
      [placeholder]="'Escribe tu consulta..'"
      [showInvalidState]="false"
      [autoComplete]="false"
      [class]="'h-[2.375rem] min-h-[2.375rem] max-h-[20rem] resize-none'"
      [taAutoResize]="true"
      [chatMode]="true"
      (onChatSend)="sendMessage()"
    ></app-input-field>
  </div>
  <div>
    <button
      [disabled]="!chatForm.valid || disabledSend"
      (click)="sendMessage()"
      type="button"
      class="uisr-primary-button whitespace-nowrap"
    >
      Enviar -&gt;
    </button>
  </div>
</form>
