import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[contenteditableModel]',
  standalone: true,
  providers: [
  {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ContenteditableModelDirective,
      multi: true,
    },
  ],
})
export class ContenteditableModelDirective implements ControlValueAccessor {
  private onChange!: (value: string) => void;
  private onTouched!: () => void;

  disabled = false;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('input')
  callOnChange() {
    if (this.onChange) {
      this.onChange(this.elementRef.nativeElement.innerText);
    }
  }

  @HostListener('blur')
  callOnTouched() {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  writeValue(value: string): void {
    const normalizedValue = value == null ? '' : value;
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'innerText',
      normalizedValue
    );
  }

  registerOnChange(onChange: (value: string) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
