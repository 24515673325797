import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UisrTranslateService implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(require(`../../../assets/i18n/${lang}.json`));
  }
}
