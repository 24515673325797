import { Directive, ElementRef, Input, OnInit, OnDestroy, Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Directive({
  selector: '[appParallaxEffect]',
})
@Injectable({
  providedIn: 'root'
})
export class ParallaxEffectDirective implements OnInit, OnDestroy {
  @Input('parallaxConfig') parallaxConfig: any;
  @Input('parallaxType') parallaxType: 'element' | 'css-vars' = 'element';

  private parent: any;
  private eventSignature: string;

  constructor(private elementRef: ElementRef) {
    this.eventSignature = 'parallax-'+uuidv4();
  }

  /* Inicializar directiva */
  ngOnInit() {

    /* Se obtiene el elemento padre */
    /* Este elemento es quien define el area del efecto */
    /* Elementos padres locales limitan el efecto al area propia */
    /* Lo ideal siempre seria enviar el body al menos que se desee */
    /* Limitar el area del paralaje */

    this.parent = document.querySelector(this.parallaxConfig.parentSelector);

    /* Hay que declarar la funcion que ejecutara el event listener como */
    /* una propiedad del elemento, ya que si posteriormente removemos */
    /* el event listener utilizado en esta directiva (mousemove) se corre */
    /* el riesgo de eliminar otros listener similares anonimos, de esta */
    /* forma solo se elimina el actual */
    this.parent[this.eventSignature] = (e: any) => {

      let ow = this.normalizeNP(e.clientX,this.parent.offsetWidth,0);
      let oh = this.normalizeNP(e.clientY,this.parent.offsetHeight,0);

      /* El efecto se calcula y aplica al elemento con la directiva */
      let nw = ow * (this.parallaxConfig.directX * this.parallaxConfig.offsetX) + "%";
			let nh = oh * (this.parallaxConfig.directY * this.parallaxConfig.offsetY) + "%";

      if(this.parallaxType == 'css-vars') {

        /* Se establece el valor de los ejes XY independientes */
        this.setCssProp(this.elementRef,'--parallax-x',nw);
        this.setCssProp(this.elementRef,'--parallax-y',nh);
      } else {
  
        /* Se establece directamente la transformacion */
        this.setCssProp(this.elementRef,'transform','translate(' + nw + ',' + nh + ')');
      }
    }

    /* Escuchar por el evento mousemove en el padre */
    /* Este evento sera siempre persistente por lo que hay que matarlo */
    /* en el onDestroy */
    /* Se hace el llamado de su propia funcion para asignar la referencia */
    /* y removerla en la destruccion de la directiva */
    this.parent.addEventListener('mousemove', this.parent[this.eventSignature]);
  }

  /* Finalizar directiva */
  ngOnDestroy() {
    this.parent.removeEventListener('mousemove', this.parent[this.eventSignature]);
  }

  /* Normalizar un valor [val] a una escala [max] [min] */
  /* https://stackoverflow.com/questions/39776819/function-to-normalize-any-number-from-0-1 */
  /* Ajustado para funcionar mejor con funciones basadas en paralaje */
  normalizeNP(val: any, max: any, min: any) {
    return 2 * ((val - min) / (max - min)) - 1;
  }

  /* Establecer propiedades de css */
  setCssProp(ele: ElementRef, propName: string, propValue: string | number) {
    ele.nativeElement.style.setProperty(propName, propValue);
  }
}
