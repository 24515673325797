import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import {
  FormControlConfig,
  ObjectValidator,
} from 'src/app/core/interfaces/form-control-config.interface';
import { validatorsCustom } from 'src/app/core/utils/validators';

@Injectable({
  providedIn: 'root',
})
export class UisrGenericFormControlsService {
  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private toastr: ToastrService
  ) { }

  createValidators(validators: ObjectValidator[]): Validators | null {
    if (validators && validators.length > 0) {
      return validators
        .map((validator) => {
          const customValidators = new validatorsCustom();
          if (validator.type == "cardNumberValidator") {
            return customValidators.cardNumberValidator
          } else if (validator.type == "expiryDateValidator") {
            return customValidators.expiryDateValidator
          }
          else if (validator.value) {
            return Validators[validator.type](validator.value);
          } else {
            return Validators[validator.type];
          }
        })
        .filter((validator) => validator != null);
    } else {
      return null;
    }
  }

  createFormGroup(
    formControls: FormControlConfig[],
    sort: boolean = true
  ): FormGroup {
    if (sort) {
      formControls.forEach((control, index) => {
        if (control.order == null || control.order == undefined) {
          control.order = index;
        }
      });
      formControls = formControls.sort((a, b) => a.order! - b.order!)
    }
    const controls: any = {};
    formControls.forEach((obj: any) => {
      controls[obj.name] = [
        { value: obj.value, disabled: obj.disabled },
        this.createValidators(obj.validators),
      ];
    });
    return this.formBuilder.group(controls);
  }

  changeInputType(input: any, icon: any): void {
    if (input.type == 'text') {
      input.type = 'password';
      icon.name = 'matVisibilityOutline';
    } else {
      input.type = 'text';
      icon.name = 'matVisibilityOffOutline';
    }
  }

  async getGenericFormErrorMessage() {
    let message = await lastValueFrom(
      this.translateService.get('GENERIC_FORM_ERROR')
    );
    this.toastr.error(message);
  }
}
