import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnChanges,
  Optional,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl
} from '@angular/forms';
import { IOptions } from '../../interfaces/autocomplete-options.interface';

@Component({
  selector: 'app-autocomplete-multi-select',
  templateUrl: './autocomplete-multi-select.component.html'
})
export class AutocompleteMultiSelectComponent implements ControlValueAccessor, OnChanges {
  @Input() options: IOptions[] = [];
  @Input() formControlName = '';
  @Input() label: string = 'Eliga una opción';

  statusSelect: boolean = false;
  statusSelectFocus: boolean = false;
  statusKeyPress: boolean = false;

  value: any = '';
  valuekeyPress: any = '';
  optionSelected: any = [];

  onChange = (value: number) => { };

  onTouched = () => { };

  touched = false;

  disabled = false;


  @ViewChild('inputText', { static: false }) inputText!: ElementRef;


  constructor(private cdr: ChangeDetectorRef, @Optional() @Self() public ngControl: NgControl, private injector: Injector, private eRef: ElementRef) {

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    if (this.ngControl.valueChanges) {
      this.ngControl.valueChanges.subscribe(() => {
        this.cdr.detectChanges();
      })
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.ngControl) {
      if ((this.ngControl.control?.value as string[])?.length > 0) {
        let filter: any[] = this.options.filter((el: any) => this.ngControl.control?.value.includes(el.label))
        if (filter.length > 0) {
          this.optionSelected = filter
          this.valuekeyPress = ''
        } 
      }
    }
  }

  @HostListener('document:click', ['$event'])
  clickTarget(event: any) {
    if (!(this.eRef.nativeElement.contains(event.target))) {
      this.statusSelectFocus = false;
      this.statusSelect = false;
    }
  }
  @HostListener('mousedown')
  clickInside() {
    this.wasInside = true;
  }
  private wasInside = false;
  @HostListener('document:mousedown')
  clickout() {
    if (!this.wasInside) {
      this.statusSelect = false;
      this.valuekeyPress = ''
    }
    this.wasInside = false;
  }
  @HostListener('document:mouseup')
  public onClick(event: any): void {
    if (!this.ngControl.control?.disabled) {
      event?.stopPropagation();
    }
  }



  clickButton() {
    if (!this.ngControl.control?.disabled) {
      this.ngControl.control?.markAsTouched();
      this.value = '';
      if (this.statusSelect) {
        this.statusSelectFocus = true;
        this.inputText.nativeElement.focus();
      } else {
        this.statusSelectFocus = false;
        this.inputText.nativeElement.blur();
      }
    }
  }

  onChangeValue = (event: any) => {
    this.markAsTouched();
    if (!this.disabled) {
      this.onChange(event);
    }
  };

  checkLabel(id: any) {
    let data = this.options.filter((element) => element.value == id);
    if (data.length > 0) {
      return data[0].label;
    } else {
      return this.label;
    }
  }
  selectOption(item: any) {
    if (!item.disabled) {
      let filter: any[] = this.optionSelected.filter((el: any) => el == item)
      if (filter.length == 0) {
        this.optionSelected.push(item)
      }
      this.valuekeyPress = '';
      this.statusSelectFocus = false;
      this.statusSelect = false;
      this.writeValue(this.optionSelected);

    }
  }

  writeValue(quantity: any) {
    if (quantity) {
      this.value = quantity;
      let value = this.options.filter((element) => element.value == quantity);
      if (value.length > 0) this.valuekeyPress = value[0].label;
      this.onChangeValue(quantity.map((q: any) => q?.value));
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
  focus() {
    setTimeout(() => {
      this.statusSelectFocus = true;
      this.statusSelect = true;
    }, 100);
  }
  checkListOptions(item: any) {
    let filter: any[] = this.optionSelected.filter((el: any) => el == item)
    if (filter.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  removeItem(item: any) {
    let filter: any[] = this.optionSelected.filter((el: any) => el != item)
    this.optionSelected = filter;
    this.writeValue(this.optionSelected);
  }
}
