import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { StorageObject } from 'src/app/features/law-firm/interfaces/storage-object.interface';
import { ExtToIconPipe } from 'src/app/features/law-firm/pipes/ext-to-icon.pipe';
import { SimpleDocPickerComponent } from '../simple-doc-picker.component';

@Component({
  selector: 'app-simple-storage-object-data',
  standalone: true,
  imports: [CommonModule, ExtToIconPipe, TranslateModule],
  templateUrl: './simple-storage-object-data.component.html',
  host: {
    class: 'contents',
  },
})
export class SimpleStorageObjectDataComponent {
  container = inject(SimpleDocPickerComponent);
  @Input({ required: true }) storageObjects: StorageObject[] = [];
  @Input() parentId?: number;

  trackByFn(index: any, item: any) {
    return index;
  }

  isLoading() {
    return this.container.loading.value || this.container.loadingFolder.value;
  }
}
