import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AngularResizeElementDirection } from 'src/app/core/enums/angular-resize-element.enum';
// @ts-ignore
import WebViewer from '@pdftron/pdfjs-express';

@Component({
  selector: 'app-pdf-viewer-overlay',
  templateUrl: './pdf-viewer-overlay.component.html',
})
export class PdfViewerOverlayComponent implements AfterViewInit, OnChanges {
  viewerInstance: any = null;
  isExpanded: boolean = false;
  public readonly AngularResizeElementDirection = AngularResizeElementDirection;
  public data: any = {};

  @Input() urlPdf: string | boolean = false;

  @Output() onViewerClose: EventEmitter<boolean> = new EventEmitter(true);

  @ViewChild('viewer', { static: false }) viewer!: ElementRef;
  @ViewChild('viewerContainer', { static: false }) viewerContainer?: ElementRef;
  @ViewChild('backdrop', { static: false }) backdrop?: ElementRef;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['urlPdf'] && changes['urlPdf'].currentValue) {
      this.openDoc(changes['urlPdf'].currentValue);
    }
  }

  openDoc(url: string): void {
    this.viewerInstance.UI.loadDocument(url);
  }

  initializeWebViewer(): void {
    WebViewer(
      {
        licenseKey: 'lymr76e0MbRmMfKFiaUH',
        path: '../viewer',
      },
      this.viewer.nativeElement
    ).then((instance: any) => {
      this.viewerInstance = instance;

      instance.disableTools(['TextHighlightCreate']);
      instance.setLanguage('es');
      instance.disableElements(['contextMenuPopup']);

      instance.setHeaderItems((header: any) => {
        /* Boton personalizado de expandir visor */
        header.push({
          type: 'statefulButton',
          initialState: 'expand',
          states: {
            expand: {
              title: 'Expandir',
              img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style="--ng-icon__size: 1.375rem; width:var(--ng-icon__size, 1em);height:var(--ng-icon__size, 1em)" fill="currentColor"><path fill-rule="evenodd" d="M4.177 7.823l2.396-2.396A.25.25 0 017 5.604v4.792a.25.25 0 01-.427.177L4.177 8.177a.25.25 0 010-.354z"></path><path fill-rule="evenodd" d="M0 1.75C0 .784.784 0 1.75 0h12.5C15.216 0 16 .784 16 1.75v12.5A1.75 1.75 0 0114.25 16H1.75A1.75 1.75 0 010 14.25V1.75zm1.75-.25a.25.25 0 00-.25.25v12.5c0 .138.112.25.25.25H9.5v-13H1.75zm12.5 13H11v-13h3.25a.25.25 0 01.25.25v12.5a.25.25 0 01-.25.25z"></path></svg>',
              onClick: (update: any, activeState: any) => {
                update('shrink');
                this.expandViewer();
                this.cdRef.detectChanges();
              },
            },
            shrink: {
              title: 'Contraer',
              img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style="--ng-icon__size: 1.375rem; width:var(--ng-icon__size, 1em);height:var(--ng-icon__size, 1em)" fill="currentColor"><path fill-rule="evenodd" d="M6.823 7.823L4.427 5.427A.25.25 0 004 5.604v4.792c0 .223.27.335.427.177l2.396-2.396a.25.25 0 000-.354z"></path><path fill-rule="evenodd" d="M1.75 0A1.75 1.75 0 000 1.75v12.5C0 15.216.784 16 1.75 16h12.5A1.75 1.75 0 0016 14.25V1.75A1.75 1.75 0 0014.25 0H1.75zM1.5 1.75a.25.25 0 01.25-.25H9.5v13H1.75a.25.25 0 01-.25-.25V1.75zM11 14.5v-13h3.25a.25.25 0 01.25.25v12.5a.25.25 0 01-.25.25H11z"></path></svg>',
              onClick: (update: any, activeState: any) => {
                update('expand');
                this.expandViewer();
                this.cdRef.detectChanges();
              },
            },
          },
        });

        /* Boton personalizado de cerrar visor */
        header.push({
          type: 'actionButton',
          title: 'Cerrar',
          img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="--ng-icon__size: 1.375rem; width:var(--ng-icon__size, 1em);height:var(--ng-icon__size, 1em)" fill="currentColor"><path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41z"></path></svg>',
          onClick: () => {
            this.closeViewer();
            this.cdRef.detectChanges();
          },
        });

      });
    });
  }

  closeViewer(): void {
    this.urlPdf = false;
    this.isExpanded = false;
    this.onViewerClose.emit(true);
  }

  expandViewer(): void {
    this.isExpanded = !this.isExpanded;
  }

  ngAfterViewInit(): void {
    this.initializeWebViewer();
  }
}
