import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserDataState } from './user-data.reducer';

export const SelectOperationState =
  createFeatureSelector<UserDataState>('UserData');

export const UserDataFull = createSelector(
  SelectOperationState,
  (UserDataState) => UserDataState.UserData
);
export const AccessTokenSelector = createSelector(
  SelectOperationState,
  (UserDataState) => UserDataState.AccessToken
);
