import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-password-validation',
  templateUrl: './password-validation.component.html'
})
export class PasswordValidationComponent implements OnChanges {
  passLevel: number = 0;
  passChecks: any[] = [
    {
      type: 'passLength',
      valid: false,
      message: 'Tener al menos 8 caracteres.',
      regEx: /^(.{8,})$/
    },
    {
      type: 'passUpperC',
      valid: false,
      message: 'Contener al menos una letra mayúscula.',
      regEx: /[A-Z]/
    },
    {
      type: 'passLowerC',
      valid: false,
      message: 'Contener al menos una letra minúscula.',
      regEx: /[a-z]/
    },
    {
      type: 'passNumber',
      valid: false,
      message: 'Contener al menos un número.',
      regEx: /[0123456789]/
    },
    {
      type: 'passSpecial',
      valid: false,
      message: 'Contener al menos un caracter especial',
      regEx: /[~`!@#$%^&*()_\-\+={[}\]\|:;"'<,>\.?\/]/
    },
  ];

  @Input() hasErrors: boolean = true;
  @Input() isTouched: boolean = false;
  @Input() hasChanged: boolean = false;
  @Input() actualValue: any = null;

  isInvalidPassword():boolean {
    let isInvalid = 
      this.passChecks.some(checks => {
        return checks.valid === false
      });
    return isInvalid;
  }

  isValidCheck(checkType: string): boolean {
    let isValid = 
      this.passChecks.filter(check => check.type === checkType)[0].valid;
    return isValid;
  }

  evaluatePassword(actualValue: string | undefined | null) {
    if (!actualValue) {
      this.passLevel = 0;
      this.passChecks.forEach(check => check.valid = false);
      return;
    }

    let level = 0;
    this.passChecks.forEach(check => {
      check.valid = check.regEx.test(actualValue);
      if (check.valid) {
        level++;
      }
    });
    this.passLevel = level;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['actualValue'] && !changes['actualValue'].firstChange) {
      this.evaluatePassword(this.actualValue)
    }
  }
}
