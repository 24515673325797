import Step from 'shepherd.js/src/types/step';
import * as Sp from 'shepherd.js';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const BLOG_STEPS: Step.StepOptions[] = [
  {
    attachTo: {
        element: document.getElementById('bs1') || '#bs1',
        on: 'bottom',
      },
      scrollTo: true,
      // beforeShowPromise: function () {
      //   return new Promise<void>(function(resolve) {
      //     window.scrollTo(0,0);
      //     resolve();
      //   })
      // },
      showOn() {
        return (document.getElementById('bs1') || false) as boolean;
      },
      title: 'Bienvenido al Blog',
      text: `<p>Descubre un nuevo mundo de conocimiento en nuestro blog. Sumérgete en artículos fascinantes que abarcan una amplia gama de temas legales.</p>`,
  },
  {
    attachTo: {
        element: document.getElementById('bs2') || '#bs2',
        on: 'top-start',
      },
      showOn() {
        return (document.getElementById('bs2') || false) as boolean;
      },
      title: 'Publicaciones Populares',
      text: `<p>Explora las publicaciones más populares que han cautivado a nuestra comunidad. Sumérgete en historias emocionantes, opiniones perspicaces y análisis profundos que han capturado la atención de nuestros lectores.</p>`,
  },
  {
    attachTo: {
        element: document.getElementById('bs3') || '#bs3',
        on: 'top',
      },
      showOn() {
        return (document.getElementById('bs3') || false) as boolean;
      },
      title: 'Publicación destacada',
      text: `<p>Sumérgete en una publicación que ha resonado con nuestra comunidad. Descubre por qué esta publicación ha generado tanto interés y participa en la conversación que ha provocado.</p>`,
  },
  {
    attachTo: {
        element: document.getElementById('bs4') || '#bs4',
        on: 'right',
      },
      showOn() {
        return (document.getElementById('bs4') || false) as boolean;
      },
      title: 'Todas las publicaciones',
      text: `<p>Explora nuestra amplia colección de publicaciones y sumérgete en un mar de conocimiento. Encuentra artículos sobre una variedad de temas que te interesan y descubre nuevas perspectivas..</p>`,
  },  
  {
    attachTo: {
        element: document.getElementById('bs13') || '#bs13',
        on: 'top',
      },
      showOn() {
        return (document.getElementById('bs13') || false) as boolean;
      },
      title: 'Filtrar entre las publicaciones',
      text: `<p>Aquí puedes filtrar entre todas las publicaciones del blog, puedes buscar por fecha o por palabras relevantes.</p>`,
  },
  {
    attachTo: {
        element: document.getElementById('bs12') || '#bs12',
        on: 'top',
      },
      showOn() {
        return (document.getElementById('bs12') || false) as boolean;
      },
      title: 'Aquí puedes ver tus publicaciones',
      text: `<p>Consulta tus publicaciones, su estado y visualizaciones, entre otras estadisticas.</p>`,
  },
  {
    attachTo: {
        element: document.getElementById('bs5') || '#bs5',
        on: 'top',
      },
      showOn() {
        return (document.getElementById('bs5') || false) as boolean;
      },
      title: 'Aquí puedes crear una publicación',
      text: `<p>Haz oír tu voz y comparte tus ideas con nuestra comunidad. Desde reflexiones personales hasta análisis profundos, aquí es donde puedes dar vida a tus pensamientos y compartirlos con el mundo.</p>`,
  },
  {
      title: 'Creación de una publicación',
      text: `<p>Desde esta vista podrás crear una nueva publicación asignando un titulo y contenido personalizado.</p>`,
  }
];

export const CREATE_POST_STEPS: Step.StepOptions[] = [
  {
    attachTo: {
        element: document.getElementById('bs6') || '#bs6',
        on: 'bottom',
      },
      showOn() {
        return (document.getElementById('bs6') || false) as boolean;
      },
      title: 'Agrega un título para tu publicación',
      text: `<p>En este paso, debes agregar un título a tu publicación. El título es importante porque captura la atención de los lectores y les da una idea de qué trata tu publicación.</p>`,
  },
  {
    attachTo: {
        element: document.getElementById('bs7') || '#bs7',
        on: 'bottom',
      },
      showOn() {
        return (document.getElementById('bs7') || false) as boolean;
      },
      title: 'Agrega el contenido de tu publicación',
      text: `<p>En este paso, debes agregar el contenido principal de tu publicación. Aquí es donde puedes expresar tus ideas, compartir información relevante y cautivar a tus lectores con un contenido interesante y bien estructurado.</p>`,
  },
  {
    attachTo: {
        element: document.getElementById('bs8') || '#bs8',
        on: 'left-end',
      },
      showOn() {
        return (document.getElementById('bs8') || false) as boolean;
      },
      title: 'Configurar el estatus de tu publicación',
      text: `<p>En este paso, puedes configurar tu publicación como "Borrador", "Publicar" y "Por Publicar". Asegúrate de elegir las configuraciones adecuadas para que tu publicación se muestre correctamente y sea fácilmente accesible para los lectores.</p>`,
  },
  {
    attachTo: {
        element: document.getElementById('bs14') || '#bs14',
        on: 'left-end',
      },
      showOn() {
        return (document.getElementById('bs14') || false) as boolean;
      },
      title: 'Configurar la visibilidad de tu publicación',
      text: `<p>En este paso, puedes configurar tu publicación como "Despacho" o "Publica". De esta manera podrás decidir si tu publicación solo se compartira con las personas de tu despacho o con el mundo.</p>`,
  },
  {
    attachTo: {
        element: document.getElementById('bs9') || '#bs9',
        on: 'left-end',
      },
      showOn() {
        return (document.getElementById('bs9') || false) as boolean;
      },
      title: 'Agregar una imagen principal',
      text: `<p>En este paso, puedes agregar una imagen principal a tu publicación. La imagen principal es importante porque ayuda a captar la atención de los lectores y proporciona una representación visual de tu contenido. Elige una imagen relevante y atractiva que complemente tu publicación.</p>`,
  },
  {
    attachTo: {
        element: document.getElementById('bs10') || '#bs10',
        on: 'left-end',
      },
      showOn() {
        return (document.getElementById('bs10') || false) as boolean;
      },
      title: 'Agregar etiquetas',
      text: `<p>En este paso, puedes agregar etiquetas a tu publicación. Las etiquetas son palabras clave que ayudan a categorizar y organizar tu contenido. Elige etiquetas relevantes que describan el tema principal de tu publicación y faciliten su descubrimiento por parte de los lectores interesados en ese tema.</p>`,
  },
  {
    attachTo: {
        element: document.getElementById('bs11') || '#bs11',
        on: 'left-end',
      },
      showOn() {
        return (document.getElementById('bs11') || false) as boolean;
      },
      title: 'Cuando estes listo, guarda',
      text: `<p>En este último paso, cuando hayas completado todos los detalles de tu publicación, puedes guardarla. Al guardar tu publicación, estarás listo para compartirla con el mundo y permitir que los lectores la disfruten y se involucren en ella.</p>`,
  }
]

export const MY_POST_STEPS: Step.StepOptions[] = [
  {
    attachTo: {
        element: document.getElementById('bs12') || '#bs12',
        on: 'bottom',
      },
      showOn() {
        return (document.getElementById('bs12') || false) as boolean;
      },
      title: 'Bienvenido a Mis Publicaciones',
      text: `<p>Aquí puedes encontrar información sobre las publicaciones que creaste. Puedes gestionar la visibilidad, consultar estadísticas y filtrar a través de tus publicaciones.</p>`,
  },
  {
    attachTo: {
        element: document.getElementById('bs13') || '#bs13',
        on: 'bottom',
      },
      showOn() {
        return (document.getElementById('bs13') || false) as boolean;
      },
      title: 'Información general',
      text: `<p>En esta sección puedes encontrar información global sobre tus publicaciones, como el total de Me Gusta, No Me Gusta y Visitas de tu blog.</p>`,
  },
  {
    attachTo: {
        element: document.getElementById('bs14') || '#bs14',
        on: 'bottom',
      },
      showOn() {
        return (document.getElementById('bs14') || false) as boolean;
      },
      title: 'Opciones de filtrado',
      text: `<p>Tú puedes filtrar por Borradores, Publicados, Eliminados y Por Publicar. También puedes utilizar tus propios filtros.</p>`,
  },
  {
    attachTo: {
        element: document.getElementById('bs15') || '#bs15',
        on: 'bottom',
      },
      showOn() {
        return (document.getElementById('bs15') || false) as boolean;
      },
      title: 'Filtros',
      text: `<p>Acá puedes buscar una publicación en específico, filtrar por fecha o por su visibilidad dentro del blog.</p>`,
  },
  {
    attachTo: {
        element: document.getElementById('bs16') || '#bs16',
        on: 'bottom',
      },
      showOn() {
        return (document.getElementById('bs16') || false) as boolean;
      },
      title: 'Post sinteticos',
      text: `<p>Aquí puedes revisar las publicaciones sintenticas que hayas realizado.</p>`,
  },
]