import { coerceNumberProperty } from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  KtdDragEnd,
  KtdDragStart,
  KtdGridComponent,
  KtdGridLayout,
  KtdResizeEnd,
  KtdResizeStart,
  ktdTrackById,
} from '@katoid/angular-grid-layout';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
  debounceTime,
  filter,
  finalize,
  fromEvent,
  lastValueFrom,
  merge,
} from 'rxjs';
import { ALERT_DEFAULTS } from 'src/app/core/constants/alert-defaults.constants';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { ModalOverlayDirective } from 'src/app/shared/directives/modal-overlay.directive';
import Swal from 'sweetalert2';
import { UisrApiServiceV2 } from '../../services/uisr-api.service-v2';

@UntilDestroy()
@Component({
  selector: 'app-so-notes',
  templateUrl: './so-notes.component.html',
  styleUrls: ['./so-notes.component.scss'],
})
export class SoNotesComponent implements OnInit, OnDestroy, OnChanges {
  // ----------------------
  @ViewChild(KtdGridComponent, { static: true }) grid: any;
  trackById = ktdTrackById;
  @Output() notesChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateData: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('tplAddSoNote', { read: ModalOverlayDirective })
  addNoteOverlay!: ModalOverlayDirective;
  @Input() notes: any[] = [];
  @Input() idSearchObject: number | null = null;
  cols = 12;
  rowHeight = 50;
  compactType: 'vertical' | 'horizontal' | null = 'vertical';
  layout: any = [];
  transitions: { name: string; value: string }[] = [
    {
      name: 'ease',
      value: 'transform 500ms ease, width 500ms ease, height 500ms ease',
    },
    {
      name: 'ease-out',
      value:
        'transform 500ms ease-out, width 500ms ease-out, height 500ms ease-out',
    },
    {
      name: 'linear',
      value: 'transform 500ms linear, width 500ms linear, height 500ms linear',
    },
    {
      name: 'overflowing',
      value:
        'transform 500ms cubic-bezier(.28,.49,.79,1.35), width 500ms cubic-bezier(.28,.49,.79,1.35), height 500ms cubic-bezier(.28,.49,.79,1.35)',
    },
    {
      name: 'fast',
      value: 'transform 200ms ease, width 200ms linear, height 200ms linear',
    },
    {
      name: 'slow-motion',
      value:
        'transform 1000ms linear, width 1000ms linear, height 1000ms linear',
    },
    { name: 'transform-only', value: 'transform 500ms ease' },
  ];
  currentTransition: string = this.transitions[0].value;

  dragStartThreshold = 0;
  autoScroll = true;
  disableDrag = false;
  disableResize = true;
  disableRemove = false;
  autoResize = true;
  preventCollision = false;
  isDragging = false;
  isResizing = false;
  resizeSubscription: any;
  selectedNote: any = {
    title: null,
    note_text: null,
    id_notes_search_object: null,
  };
  resizeTimeout: any;
  note_update: any;
  messages: any;
  resources = RESOURCES;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.layout = this.generateResponsiveLayout(this.layout);
    }, 200); // 200 milisegundos de espera
  }

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) public document: Document,
    private apiService: UisrApiServiceV2,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  async ngOnInit() {
    this.resizeSubscription = merge(
      fromEvent(window, 'resize'),
      fromEvent(window, 'orientationchange')
    )
      .pipe(
        debounceTime(50),
        filter(() => this.autoResize)
      )
      .subscribe(() => {
        this.grid.resize();
      });
    this.messages = await this.getMessages();
  }
  async getMessages() {
    let messages: any = await lastValueFrom(
      this.translateService.get([
        'GETTING_COLLECTIONS',
        'GENERIC_ERROR_MESSAGE',
        'GETTING_COLLECTION_DETAILS',
        'DOCUMENT_REMOVED_FROM_COLLECTION',
        'DELETING_ENTRY_STRING',
        'DOCUMENT_ADDED_TO_COLLECTION_STRING',
        'SUCCESSFUL_COLLECTION_UPDATE',
        'COLLECTION_CLONED',
        'SUCCESSFUL_RESULT_UPDATE',
        'DELETE_RESULT_QUESTION',
        'DELETE_COLLECTION_QUESTION',
        'COLLECTION_DELETED',
        'DELETE_NOTE_QUESTION',
        'DELETING_ENTRY_NOTE',
        'DOCUMENT_REMOVED_FROM_NOTE',
      ])
    );

    return messages;
  }

  ngOnChanges(data: any) {
    if (data.notes.currentValue) {
      if (data.notes.currentValue?.length > 0) {
        const texts: any[] = this.notes.map((note: any) => {
          return {
            note_text: note.note_text,
            id_notes_search_object: note.id_notes_search_object,
            title: note.title,
            created_at: note.created_at,
            colour_note: note.colour_note,
            fk_id_object: note.fk_id_object,
          };
        });
        this.layout = this.generateResponsiveLayout(texts);
      }
    }
  }
  ngOnDestroy() {
    this.resizeSubscription.unsubscribe();
  }

  onDragStarted(event: KtdDragStart) {
    this.isDragging = true;
  }
  onDragEnded(event: KtdDragEnd) {
    setTimeout(() => {
      this.isDragging = false;
    }, 200);
  }

  onResizeStarted(event: KtdResizeStart) {
    this.isResizing = true;
  }

  onResizeEnded(event: KtdResizeEnd) {
    this.isResizing = false;
  }
  updateOrderNotas(notes: any[]) {
    let sortedLayout = [...notes].sort((a, b) => {
      // Primero, ordena por la posición y
      if (a.y !== b.y) {
        return a.y - b.y;
      }

      // Si la posición y es la misma, ordena por la posición x
      return a.x - b.x;
    });

    sortedLayout = sortedLayout.map((item: any) => {
      let gatData = this.layout.find((items: any) => items.id == item.id);
      return {
        ...gatData,
        x: item.x,
        y: item.y,
      };
    });
    this.note_update = {
      positions: sortedLayout.map((item: any, i: number) => {
        return {
          id_notes_search_object: item.id_notes_search_object,
          order: i + 1,
        };
      }),
    };
    if (this.note_update.positions.length == 0) {
      return;
    }
    this.apiService
      .patch(this.resources.updatedSoNote, this.note_update)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (res: any) => {
          this.toastr.clear();
          if (res.success) {
            // this.toastr.success(this.messages.UPDATE_SUCCESS);
          } else {
            let message = this.messages.GENERIC_ERROR_MESSAGE;
            if (res.code) {
              let messageAux = this.translateService.instant(res.code);
              message = messageAux != res.code ? messageAux : message;
            }
            Swal.fire({
              ...(ALERT_DEFAULTS as any),
              ...{
                text: message,
              },
            });
          }
        },
        error: (_error) => {
          this.toastr.clear();
        },
      });
  }
  onLayoutUpdated(layout: KtdGridLayout) {
    this.updateOrderNotas(layout);

    let dataFilter = layout.map((item: any) => {
      let gatData = this.layout.find((items: any) => items.id == item.id);
      return {
        ...gatData,
        x: item.x,
        y: item.y,
      };
    });
    this.layout = dataFilter;
  }

  onCompactTypeChange(change: any) {
    this.compactType = change.value;
  }

  onTransitionChange(change: any) {
    this.currentTransition = change.value;
  }

  onAutoScrollChange(checked: boolean) {
    this.autoScroll = checked;
  }

  onDisableDragChange(checked: boolean) {
    this.disableDrag = checked;
  }

  onDisableResizeChange(checked: boolean) {
    this.disableResize = checked;
  }

  onDisableRemoveChange(checked: boolean) {
    this.disableRemove = checked;
  }

  onAutoResizeChange(checked: boolean) {
    this.autoResize = checked;
  }

  onPreventCollisionChange(checked: boolean) {
    this.preventCollision = checked;
  }

  onColsChange(event: Event) {
    this.cols = coerceNumberProperty((event.target as HTMLInputElement).value);
  }

  onRowHeightChange(event: Event) {
    this.rowHeight = coerceNumberProperty(
      (event.target as HTMLInputElement).value
    );
  }

  onDragStartThresholdChange(event: Event) {
    this.dragStartThreshold = coerceNumberProperty(
      (event.target as HTMLInputElement).value
    );
  }

  stopEventPropagation(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  ktdArrayRemoveItem<T>(array: T[], condition: (item: T) => boolean) {
    const arrayCopy = [...array];
    const index = array.findIndex((item) => condition(item));
    if (index > -1) {
      arrayCopy.splice(index, 1);
    }
    return arrayCopy;
  }
  generateLayout(datas: any[]): KtdGridLayout | any[] {
    let layout = [];
    let index = 0;
    let IndexY = 0;
    for (let data of datas) {
      layout.push({
        id: layout.length.toString(),
        text: data.note_text,
        note_text: data.note_text,
        id_notes_search_object: data.id_notes_search_object,
        title: data.title,
        colour_note: data.colour_note,
        created_at: data.created_at,
        fk_id_object: data.fk_id_object,
        x: index * 3,
        y: IndexY,
        w: 3,
        h: 5,
      });
      index++;
      if (index == 4) {
        index = 0;
        IndexY += 5;
      }
    }

    return layout;
  }

  measureTextSize(text: string): { w: number; h: number } {
    // Aquí puedes implementar tu lógica de medición.
    // Esta es solo una función de ejemplo que devuelve 1 para textos cortos y 2 para textos más largos.
    // const length = text.length;

    // if (length <= 20) {
    return { w: 3, h: 5 };
  }

  getDividerColor(color: string | null) {
    let dividerColor = 'border-blue-600';
    switch (color) {
      case 'border-blue-200':
        dividerColor = 'border-blue-600';
        break;
      case 'bg-green-200':
        dividerColor = 'border-green-600';
        break;
      case 'bg-amber-200':
        dividerColor = 'border-amber-600';
        break;
      case 'bg-pink-200':
        dividerColor = 'border-pink-600';
        break;
      default:
        break;
    }

    return dividerColor;
  }

  getSanitizedHtml(value: string) {
    if (this.getLength(value) > 150) {
      return this.sanitizer.bypassSecurityTrustHtml(
        value.slice(0, 150) + '...'
      );
    } else {
      return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }
  getLength(value: string) {
    if (value) {
      return value.length;
    } else {
      return 0;
    }
  }
  onNoteUpdate(note: any) {
    if (note && note.isDelete) {
      this.deleteNote(note);
      this.addNoteOverlay.close();
      this.updateData.emit(true);
      return;
    }
    let match = this.notes?.find(
      (currentNote) =>
        currentNote.id_notes_search_object == note.id_notes_search_object
    );
    if (!match) {
      if (this.notes) {
        const width = window.innerWidth;
        const mobileBreakpoint = 480;
        const tabletBreakpoint = 768;

        if (width < mobileBreakpoint) {
          note = {
            ...note,
            x: 0,
            y: 12,
            w: 12,
            h: 5,
          };
        } else if (width < tabletBreakpoint) {
          note = {
            ...note,
            x: 0,
            y: 6,
            w: 6,
            h: 5,
          };
        } else {
          note = {
            ...note,
            x: 0,
            y: 0,
            w: 3,
            h: 5,
          };
        }

        this.notes.unshift(note);
      } else {
        this.notes = [note];
      }
      const texts: any[] = this.notes.map((note: any) => {
        return {
          note_text: note.note_text,
          id_notes_search_object: note.id_notes_search_object,
          title: note.title,
          created_at: note.created_at,
          colour_note: note.colour_note,
          fk_id_object: note.fk_id_object,
        };
      });
      this.layout = this.generateResponsiveLayout(texts);
    }
    this.notesChange.emit(this.notes);
    this.updateData.emit(true);
    this.addNoteOverlay.close();
  }
  addNote() {
    this.selectedNote = {
      title: null,
      note_text: null,
      id_notes_search_object: null,
    };
    this.addNoteOverlay.open();
  }

  editNote(note: any) {
    if (!this.isDragging) {
      this.selectedNote = note;
      this.addNoteOverlay.open();
    }
  }
  generateOneColumnLayout(datas: any[]): KtdGridLayout | any[] {
    let layout = [];

    for (let data of datas) {
      layout.push({
        id: layout.length.toString(),
        text: data.note_text,
        note_text: data.note_text,
        id_notes_search_object: data.id_notes_search_object,
        title: data.title,
        colour_note: data.colour_note,
        created_at: data.created_at,
        fk_id_object: data.fk_id_object,
        x: 0,
        y: 12,
        w: 12,
        h: 5,
      });
    }

    return layout;
  }

  generateTwoColumnLayout(datas: any[]): KtdGridLayout | any[] {
    let layout = [];
    let index = 0;
    for (let data of datas) {
      layout.push({
        id: layout.length.toString(),
        text: data.note_text,
        note_text: data.note_text,
        id_notes_search_object: data.id_notes_search_object,
        title: data.title,
        colour_note: data.colour_note,
        created_at: data.created_at,
        fk_id_object: data.fk_id_object,
        x: index % 2 === 0 ? 0 : 6,
        y: 6,
        w: 6,
        h: 5,
      });
      index = index + 1;
    }

    return layout;
  }

  generateResponsiveLayout(datas: any[]): KtdGridLayout | any[] {
    const width = window.innerWidth;
    const mobileBreakpoint = 480;
    const tabletBreakpoint = 768;

    if (width < mobileBreakpoint) {
      return this.generateOneColumnLayout(datas);
    } else if (width < tabletBreakpoint) {
      return this.generateTwoColumnLayout(datas);
    } else {
      return this.generateLayout(datas);
    }
  }
  /** Removes the item from the layout */
  removeItem(id: string) {
    // Important: Don't mutate the array. Let Angular know that the layout has changed creating a new reference.
    this.layout = this.ktdArrayRemoveItem(
      this.layout,
      (item: any) => item.id === id
    );
    this.layout = this.generateResponsiveLayout(this.layout);
    this.onLayoutUpdated(this.layout);
  }

  deleteNote(result: any) {
    this.toastr.info(this.messages.DELETING_ENTRY_NOTE);
    let data = {
      id_notes_search_object: result.id_notes_search_object,
    };
    this.apiService
      .delete(this.resources.deleteNotesSo, data, null, ['button'])
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (res: any) => {
          this.toastr.clear();
          if (res.success) {
            this.toastr.success(this.messages.DOCUMENT_REMOVED_FROM_COLLECTION);
            this.removeItem(result.id);
          } else {
            let message = this.messages.GENERIC_ERROR_MESSAGE;
            if (res.code) {
              let messageAux = this.translateService.instant(res.code);
              message = messageAux != res.code ? messageAux : message;
            }
            Swal.fire({
              ...(ALERT_DEFAULTS as any),
              ...{
                text: message,
              },
            });
          }
        },
        error: () => this.toastr.clear(),
      });
  }
}
