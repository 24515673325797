<span class="block text-sm font-medium mb-1" *ngIf="label">
    {{ label }}
</span>
<div class="relative w-full" (window:scroll)="onScroll()">
    <div [ngClass]="{
        disabled: this.ngControl.control?.disabled,
        error:this.ngControl.control?.invalid &&this.ngControl.control?.touched
      }">
        <div class="relative" [cdkMenuTriggerFor]="optionsMenu">
            <input #inputText input data-cy="selectedOption" type="text" class="form-input w-full" 
                [ngClass]="{'border-rose-300': this.ngControl.control?.errors && this.ngControl.control?.touched, 'border-slate-200 bg-slate-100 text-slate-400 !cursor-not-allowed': this.ngControl.control?.disabled, '!pr-10' : showIcon}"
                [disabled]="this.ngControl.control?.disabled || false" (ngModelChange)="keypress($event)" [(ngModel)]="keyValue" (keydown)="onButtonKeydown($event)" [placeholder]="placeholder ? placeholder : (label ? label : '')" />
            <i class="absolute right-4 bottom-2/4 translate-y-2/4 fa-duotone fa-chevron-down secondary-icon pointer-events-none" *ngIf="showIcon"></i>
        </div>
        <ng-template #optionsMenu>
            <div class="absolute left-0 bg-white shadow rounded mt-1 py-1 w-75 max-w-[400px] z-50 h-fit max-h-[200px] overflow-y-auto" (keydown)="onKeydown($event)" cdkMenu>
                <ng-template ngFor let-item [ngForOf]="options | filterForAutocomplete: keyValue : disableFilter">
                    <button class="flex items-center justify-between w-full hover:bg-slate-50 py-2 px-3 cursor-pointer gap-3"
                        [ngClass]="{ 'text-primary-100': item.label == optionSelected }" (click)="selectOption(item)" (focus)="onMenuFocus($event)" cdkMenuItem>
                        <span *ngIf="!item?.disabled; else disabled">
                            {{ item.label }}
                        </span>
                        {{ item.label == optionSelected ? '✓' : '' }}
                        <ng-template #disabled>
                            {{ item.label }}
                        </ng-template>
                    </button>
                </ng-template>
            </div>
        </ng-template>
    </div>
</div>