import { FormControl } from "@angular/forms";

export class validatorsCustom {
  
    // Validador personalizado para número de tarjeta de crédito
    public cardNumberValidator(control: FormControl) {
        if (!control.value) {
          return null;
        }
      
        const cardNumber = control.value.replace(/ /g, ''); // eliminar espacios
      
        const amexRegExp = /^3[47][0-9]{13}$/;
        const visaRegExp = /^4[0-9]{12}(?:[0-9]{3})?$/;
        const dinersClubRegExp = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
        const mastercardRegExp = /^5[1-5][0-9]{14}$/;
        const jcbRegExp = /^(?:2131|1800|35\d{3})\d{11}$/;
        const discoverRegExp = /^6(?:011|5[0-9]{2})[0-9]{12}$/;
      
        if (visaRegExp.test(cardNumber)) {
          return null; // Es una tarjeta Visa válida
        } else if (mastercardRegExp.test(cardNumber)) {
          return null; // Es una tarjeta MasterCard válida
        } else if (amexRegExp.test(cardNumber)) {
          return null; // Es una tarjeta American Express válida
        } else if (discoverRegExp.test(cardNumber)) {
          return null; // Es una tarjeta Discover válida
        } else if (jcbRegExp.test(cardNumber)) {
          return null; // Es una tarjeta JCB válida
        } else if (dinersClubRegExp.test(cardNumber)) {
          return null; // Es una tarjeta Diners Club válida
        } else {
          return { cardNumberValidator: true }; // Es un número de tarjeta no válido
        }
      }
      

    // Validador personalizado para fecha de expiración
    public expiryDateValidator(control: FormControl) {
        if (!control.value) {
          return null;
        }
      
        const expiryDate = control.value.split('/');
        if (expiryDate.length !== 2) {
          return { expiryDateValidator: true }; // Formato de fecha incorrecto
        }
      
        const expMonth = Number(expiryDate[0]);
        const expYear = Number('20' + expiryDate[1]);
        if (isNaN(expMonth) || isNaN(expYear)) {
          return { expiryDateValidator: true }; // Fecha de expiración no es un número
        }
      
        if (expMonth < 1 || expMonth > 12) {
          return { expiryDateValidator: true }; // Mes inválido
        }
      
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // getMonth() devuelve valores de 0-11, por eso sumamos 1
        if (expYear < currentYear || (expYear === currentYear && expMonth < currentMonth)) {
          return { expiryDateValidator: true }; // Tarjeta expirada
        }
      
        return null; // Fecha de expiración válida
      }
      
}

     