import Step from 'shepherd.js/src/types/step';

export const ADD_ACTIVITY_STEPS: Step.StepOptions[] = [
  {
    attachTo: {
      element: document.getElementById('aas1') || '#aas1',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('aas1') || false) as boolean;
    },
    title: 'Título de la Actividad',
    text: `<div class='space-y-2'><p>Es obligatorio asignar un título a cada actividad que anexes.</p>
      <p>Utiliza un formato claro y específico. Por ejemplo: "Acuerdo 183-2023-1222".</p></div>`,
  },
  {
    attachTo: {
      element: document.getElementById('aas2') || '#aas2',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('aas2') || false) as boolean;
    },
    title: 'Descripción de la Actividad',
    text: `<div class='space-y-2'><p>Es obligatorio proporcionar una breve descripción de cada actividad.</p>
      <p>Esta descripción ayudará a identificar el propósito o contenido principal del documento. Por ejemplo: "Acuerdo donde se establece la admisión de la demanda".</p></div>`,
  },
  {
    attachTo: {
      element: document.getElementById('aas3') || '#aas3',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('aas3') || false) as boolean;
    },
    title: 'Carga de Archivos',
    text: `<div class='space-y-2'><p>Puedes cargar tantos archivos como necesites al asunto.</p>
      <p>Si en algún caso no tienes un archivo específico para cargar, no hay problema. No es obligatorio anexar un archivo en cada ocasión.</p></div>`,
  },
];

export const ADD_REMINDER_STEPS: Step.StepOptions[] = [
  {
    attachTo: {
      element: document.getElementById('ars1') || '#ars1',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('ars1') || false) as boolean;
    },
    title: 'Título del Recordatorio',
    text: `<div class='space-y-2'><p>Es obligatorio asignar un título a cada recordatorio que anexes.</p></div>`,
  },
  {
    attachTo: {
      element: document.getElementById('ars2') || '#ars2',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('ars2') || false) as boolean;
    },
    title: 'Descripción del Recordatorio',
    text: `<div class='space-y-2'><p>Es obligatorio proporcionar una breve descripción de cada recordatorio.</p>
      <p>Esta descripción ayudará a identificar el propósito o contenido principal del recordatorio.</p></div>`,
  },
  {
    attachTo: {
      element: document.getElementById('ars3') || '#ars3',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('ars3') || false) as boolean;
    },
    title: 'Fecha de Recordatorio',
    text: `<div class='space-y-2'><p>Asigna una fecha al recordatorio.</p>
      <p>Todos los miembros del equipo involucrados en el caso recibirán el recordatorio en su correo el día asignado.</p></div>`,
  },
];

export const CLIENTS_STEPS: Step.StepOptions[] = [
  {
    attachTo: {
      element: document.getElementById('cs1') || '#cs1',
      on: 'left',
    },
    showOn() {
      return (document.getElementById('cs1') || false) as boolean;
    },
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        const closeButton = document.querySelector(
          '#close-add-client'
        ) as HTMLElement;
        if (closeButton) {
          closeButton?.click();
        }
        resolve();
      });
    },
    title: 'Crear Cliente',
    text: `<p>Inicia aquí para dar vida a un nuevo cliente. Al seleccionar esta opción, se desplegará un formulario donde podrás introducir los detalles del cliente.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('acs1') || '#acs1',
      on: 'top',
    },
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        const cs3Element = document.querySelector('#cs1') as HTMLElement;
        cs3Element?.click();
        resolve();
      });
    },
    title: 'Formulario de creación de cliente',
    text: `<p>Este es tu espacio para registrar a un nuevo cliente. Recuerda completar todos los campos necesarios, identificados con un asterisco (<span class="text-rose-600">*</span>).</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('acs2') || '#acs2',
      on: 'top',
    },
    showOn() {
      return (document.getElementById('acs2') || false) as boolean;
    },
    title: 'Guardar cambios',
    text: `<p>Una vez llenado el formulario, asegúrate de guardar la información. Este paso es vital para añadir el cliente a tu base de datos y posteriormente vincular asuntos, documentos y facturas.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('cs2') || '#cs2',
      on: 'top',
    },
    showOn() {
      return (document.getElementById('cs2') || false) as boolean;
    },
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        const closeButton = document.querySelector(
          '#close-add-client'
        ) as HTMLElement;
        if (closeButton) {
          closeButton?.click();
        }
        resolve();
      });
    },
    title: 'Lista de Clientes',
    text: `<p>Aquí encontrarás un panorama completo de tus clientes. Explora la lista, accede a los detalles de cada cliente, edita información o elimina registros según necesites.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('cs3') || '#cs3',
      on: 'left',
    },
    showOn() {
      return (document.getElementById('cs3') || false) as boolean;
    },
    title: 'Filtrar Clientes',
    text: `<p>Esta herramienta te facilitará la búsqueda de clientes específicos. Filtra según nombre, email, teléfono, u otros criterios para agilizar tu búsqueda.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('cs4') || '#cs4',
      on: 'left',
    },
    showOn() {
      return (document.getElementById('cs4') || false) as boolean;
    },
    title: 'Cambiar Vista',
    text: `<p>Personaliza cómo visualizas tus datos. Elige entre una vista en tabla para un resumen por filas o la vista en tarjetas para un enfoque más visual e individualizado.</p>`,
  },
];

export const CREATE_FIELD_STEPS: Step.StepOptions[] = [
  {
    attachTo: {
      element: document.getElementById('cfs1') || '#cf1',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('cfs1') || false) as boolean;
    },
    title: 'Tutorial de agregar campo a una plantilla',
    text: `<div class='space-y-2'><p>Si requieres un nuevo campo además de los que te incluye la plantilla por defecto, créalos aquí</p></div>
     `,
  },
  {
    attachTo: {
      element: document.getElementById('cfs2') || '#cfs2',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('cfs2') || false) as boolean;
    },
    title: 'Nombre del Campo',
    text: `<div class='space-y-2'><p>Es obligatorio proporcionar un nombre para cada campo.</p>
      <p>El nombre debe ser claro y descriptivo para identificar fácilmente su función. Por ejemplo: "Materia". Texto de Ejemplo (Placeholder):</p></div>`,
  },
  {
    attachTo: {
      element: document.getElementById('cfs3') || '#cfs3',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('cfs3') || false) as boolean;
    },
    title: 'Texto de ejemplo',
    text: `<div class='space-y-2'><p>Agrega un texto de ejemplo dentro del campo para orientar al usuario sobre el tipo de información esperada.</p>
      <p>Este texto es solo ilustrativo y no es obligatorio. Por ejemplo: "Ej. Penal".</p></div>`,
  },
  {
    attachTo: {
      element: document.getElementById('csf4') || '#cfs4',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('cfs4') || false) as boolean;
    },
    title: 'Descripción',
    text: `<div class='space-y-2'><p>Proporciona una breve descripción o indicación que aclare la información que debe ingresar el usuario.</p>
      <p>Esta descripción sirve como guía adicional para asegurar que el usuario proporcione los datos correctamente. Por ejemplo: "Ingresa el tipo de materia del cual trata el asunto".</p></div>`,
  },
  {
    attachTo: {
      element: document.getElementById('cs5') || '#cfs5',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('cfs5') || false) as boolean;
    },
    title: 'Campo Requerido',
    text: `<div class='space-y-2'><p>Indica si el campo es requerido.</p>
      <p>Si se escoge como requerido quien llene la plantilla forzosamente deberá llenar este campo.</p></div>`,
  },
  {
    attachTo: {
      element: document.getElementById('cs6') || '#cfs6',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('cfs6') || false) as boolean;
    },
    title: 'Vista Previa',
    text: `<div class='space-y-2'><p>Aquí puedes visualizar como quedaría tu campo.</p></div>`,
  },
];

export const INVOICE_STEPS: Step.StepOptions[] = [
  {
    attachTo: {
      element: document.getElementById('is1') || '#is1',
      on: 'top',
    },
    scrollTo: true,
    showOn() {
      return (document.getElementById('is1') || false) as boolean;
    },
    title: 'Bienvenido a Cobranzas',
    text: `<p>Aquí encontrarás un panorama completo de tus cobros. Explora la lista, accede a los detalles de cada cliente, registra pagos o elimina registros según necesites.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('is2') || '#is2',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('is2') || false) as boolean;
    },
    title: 'Ordena tus Cobros',
    text: `<p>Puedes ordenar tus cobros de manera ascendente y descendente por el número de factura, monto total y fecha.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('is3') || '#is3',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('is3') || false) as boolean;
    },
    title: 'Filtra tus Cobros',
    text: `<p>Esta herramienta te facilitá la búsqueda de cobros específicos. Filtra según cliente, fecha, monto, u otros criterios para agilizar tu búsqueda.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('is6') || '#is6',
      on: 'top',
    },
    showOn() {
      return (document.getElementById('is6') || false) as boolean;
    },
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        const closeButton = document.querySelector(
          '#close-add-charge'
        ) as HTMLElement;
        if (closeButton) {
          closeButton?.click();
        }
        resolve();
      });
    },
    title: 'Crea un cobro',
    text: `<p>Inicia aquí para registrar un nuevo cobro. Al seleccionar esta opción, se desplegará un formulario donde podrás introducir los detalles del cobro.</p>`,
  },

  {
    attachTo: {
      element: document.getElementById('cis1') || '#cis1',
      on: 'top',
    },
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        const cs3Element = document.querySelector('#is6') as HTMLElement;
        cs3Element?.click();
        resolve();
      });
    },
    title: 'Formulario de creación de cobro',
    text: `<p>Este es tu espacio para registrar a un nuevo cobro. Recuerda completar todos los campos necesarios, identificados con un asterisco (<span class="text-rose-600">*</span>).</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('cis2') || '#cis2',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('cis2') || false) as boolean;
    },
    title: 'Estado de la factura',
    text: `<p>No olvides de seleccionar el estado del pago de tu factura.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('cis3') || '#cis3',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('cis3') || false) as boolean;
    },
    title: 'Carga de factura',
    text: `<p>Si lo deseas carga tu factura en PDF o XML.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('cis4') || '#cis4',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('cis4') || false) as boolean;
    },
    title: 'Envía la factura',
    text: `<p>Selecciona esta opción para enviar la factura al correo que proporciones.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('is4') || '#is4',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('is4') || false) as boolean;
    },
    beforeShowPromise: () => {
      return new Promise<void>((resolve) => {
        const closeButton = document.querySelector(
          '#close-add-charge'
        ) as HTMLElement;
        if (closeButton) {
          closeButton?.click();
        }
        resolve();
      });
    },
    title: 'Filtrar Cobros',
    text: `<p>Al igual tienes la opción de filtrar tus cobros dependiendo de su estado.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('is5') || '#is5',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('is5') || false) as boolean;
    },
    title: 'Cambia de moneda',
    text: `<p>Este botón te permite filtrar por moneda, ya sea MXN o USD.</p>`,
  },
];

export const DOSSIER_STEPS: Step.StepOptions[] = [
  {
    attachTo: {
      element: document.getElementById('ds1') || '#ds1',
      on: 'top',
    },
    scrollTo: true,
    showOn() {
      return (document.getElementById('ds1') || false) as boolean;
    },
    title: 'Bienvenido a Asuntos',
    text: `<p>Aquí encontrarás un panorama completo de tus asuntos. Explora la lista, accede a los detalles de cada asunto, edita información o elimina registros según necesites.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('ds2') || '#ds2',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('ds2') || false) as boolean;
    },
    title: 'Filtrar Asuntos',
    text: `<p>Esta herramienta te facilitará la búsqueda de asuntos específicos. Filtra según número de asunto, fecha de creación, u otros criterios para agilizar tu búsqueda.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('ds3') || '#ds3',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('ds3') || false) as boolean;
    },
    title: 'Filtrar Asuntos',
    text: `<p>Al igual tienes la opción de filtrar tus asuntos dependiendo de su estado.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('ds4') || '#ds4',
      on: 'top',
    },
    showOn() {
      return (document.getElementById('ds4') || false) as boolean;
    },
    title: 'Cambia la vista de tus asuntos',
    text: `<p>Tienes dos opciones para la visualización de sus asuntos, eligé la que más te guste.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('ds5') || '#ds5',
      on: 'top',
    },
    showOn() {
      return (document.getElementById('ds5') || false) as boolean;
    },
    title: 'Carga Multiple de asuntos',
    text: `<p>Una forma facil y rápida para cargar multiples asuntos a la vez. Al seleccionar esta opción, se desplegará un formulario donde podrás introducir los detalles de la carga.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('ds6') || '#ds6',
      on: 'top',
    },
    showOn() {
      return (document.getElementById('ds6') || false) as boolean;
    },
    title: 'Crear Asunto',
    text: `<p>Inicia aquí para dar vida a un nuevo asunto. Al seleccionar esta opción, se desplegará un formulario donde podrás introducir los detalles del asunto.</p>`,
  },
  {
    title: 'Bienvenido a Asuntos',
    text: `<p>Aquí encontrarás un panorama completo de tus asuntos. Explora la lista, accede a los detalles de cada asunto, edita información o elimina registros según necesites.</p>`,
  },
];

export const REGISTER_CHARGE_STEPS: Step.StepOptions[] = [
  {
    attachTo: {
      element: document.getElementById('rc1') || '#rc1',
      on: 'bottom',
    },
    scrollTo: true,
    showOn() {
      return (document.getElementById('rc1') || false) as boolean;
    },
    title: 'Registra un pago',
    text: `<p>Nunca había sido tan fácil registrar un pago a una factura. Recuerda completar todos los campos necesarios, identificados con un asterisco (<span class="text-rose-600">*</span>).</p>`,
  },

  {
    attachTo: {
      element: document.getElementById('rc2') || '#rc2',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('rc2') || false) as boolean;
    },
    title: 'Saldo de la factura',
    text: `<p>Consulta de manera rápida el saldo pendiente o pagado de tu factura.</p>`,
  },

  {
    attachTo: {
      element: document.getElementById('rc3') || '#rc3',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('rc3') || false) as boolean;
    },
    title: 'Estado de la factura',
    text: `<p>Inicia aquí para registrar un nuevo asunto. Al seleccionar esta opción, se desplegará un formulario donde podrás introducir los detalles del asunto.</p>`,
  },
];

export const getCreateDossierSteps = (context: any): Step.StepOptions[] => [
  {
    attachTo: {
      element: document.getElementById('select-template') || '#select-template',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('select-template') || false) as boolean;
    },
    title: 'Selección de Plantilla',
    text: `<p>Empieza por seleccionar una plantilla. Tienes la opción de elegir entre las plantillas que MiDespacho ofrece o puedes crear tu propia plantilla.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('search-template') || '#search-template',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('search-template') || false) as boolean;
    },
    title: 'Busqueda de Plantilla',
    text: `<p>Asistete del buscador para encontrar una plantilla por nombre.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('Templates-0') || '#Templates-0',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('Templates-0') || false) as boolean;
    },
    title: 'Previsualizar',
    text: `<p>Da clic derecho a cualquier plantilla para previsualizarla y verificar los campos que aparecerán en el asunto</p>`,
  },

  {
    attachTo: {
      element: document.getElementById('dossier-form') || '#dossier-form',
      on: 'bottom',
    },
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        context.onTemplateActivate(context.filteredTemplates[0]);
        setTimeout(() => {
          // Luego simula el click en el botón de continuar
          const continueButton = document.getElementById(
            'continue-button-file'
          ) as HTMLElement;
          if (continueButton) {
            continueButton.click();
          }

          resolve();
        }, 300); // Ajusta este tiempo según sea necesario
      });
    },
    title: 'Llena la plantilla',
    text: `<p>Llena la plantilla para crear tu asunto. Recuerda completar todos los campos necesarios, identificados con un asterisco (<span class="text-rose-600">*</span>).</p>`,
  },
];

export const FILE_TABS_STEPS: Step.StepOptions[] = [
  {
    attachTo: {
      element: '#tab-information',
      on: 'left-end',
    },
    // showOn() {
    //   return (document.getElementById('tab-information') || false) as boolean;
    // },
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        const openButton = document.querySelector(
          '#tab-information'
        ) as HTMLElement;
        openButton?.click();
        resolve();
      });
    },
    title: 'Información',
    text: `<p>Consulta todos los detalles sobre tu asunto.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('fts1') || '#fts1',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('fts1') || false) as boolean;
    },
    scrollTo: false,
    title: 'Funciones',
    text: `<p>En esta sección puedes editar, archivar y ver el codigo QR de tu asunto.</p>`,
  },
  {
    attachTo: {
      element: '#tab-activity',
      on: 'left-end',
    },
    // showOn() {
    //   return (document.getElementById('tab-activity') || false) as boolean;
    // },
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        const openButton = document.querySelector(
          '#tab-activity'
        ) as HTMLElement;
        if (openButton) {
          openButton?.click();
        }
        resolve();
      });
    },
    title: 'Expediente',
    text: `<p>Agrega actividades y recordatorios a tu asunto. Una vez agregados podrás hacer comentarios y etiquetar al resto de tu equipo en ellos.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('fts3') || '#fts3',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('fts3') || false) as boolean;
    },
    scrollTo: false,
    title: 'Agrega Recordatorios',
    text: `<p>Agrega recordatorios a tu asunto, todos los miembros del equipo asociados al asunto recibirán un correo electrónico con los detalles.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('ars1') || '#ars1',
      on: 'left-end',
    },
    // showOn() {
    //   return (document.getElementById('fts4') || false) as boolean;
    // },
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        const openButton = document.querySelector('#fts3') as HTMLElement;
        openButton?.click();
        resolve();
      });
    },
    title: 'Título del Recordatorio',
    text: `<div class='space-y-2'><p>Es obligatorio asignar un título a cada recordatorio que anexes.</p></div>`,
  },
  {
    attachTo: {
      element: document.getElementById('ars2') || '#ars2',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('ars2') || false) as boolean;
    },
    title: 'Descripción del Recordatorio',
    text: `<div class='space-y-2'><p>Es obligatorio proporcionar una breve descripción de cada recordatorio.</p>
      <p>Esta descripción ayudará a identificar el propósito o contenido principal del recordatorio.</p></div>`,
  },
  {
    attachTo: {
      element: document.getElementById('ars3') || '#ars3',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('ars3') || false) as boolean;
    },
    title: 'Fecha de Recordatorio',
    text: `<div class='space-y-2'><p>Asigna una fecha al recordatorio.</p>
      <p>Todos los miembros del equipo involucrados en el caso recibirán el recordatorio en su correo el día asignado.</p></div>`,
  },
  {
    attachTo: {
      element: document.getElementById('fts5') || '#fts5',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('fts5') || false) as boolean;
    },
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        const closeButton = document.querySelector(
          '#close-remider-modal'
        ) as HTMLElement;
        if (closeButton) {
          closeButton?.click();
        }
        resolve();
      });
    },
    scrollTo: false,
    title: 'Anexa Actividades',
    text: `<p>Añade y gestiona actividades y tareas para tu asunto.</p>`,
  },
  {
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        const openButton = document.querySelector('#fts5') as HTMLElement;
        openButton?.click();
        resolve();
      });
    },
    attachTo: {
      element: document.getElementById('aas1') || '#aas1',
      on: 'left-end',
    },
    title: 'Título de la Actividad',
    text: `<div class='space-y-2'><p>Es obligatorio asignar un título a cada actividad que anexes.</p>
      <p>Utiliza un formato claro y específico. Por ejemplo: "Acuerdo 183-2023-1222".</p></div>`,
  },
  ADD_ACTIVITY_STEPS[1],
  ADD_ACTIVITY_STEPS[2],
  {
    attachTo: {
      element: '#tab-documents',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('tab-documents') || false) as boolean;
    },
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        const closeButton = document.querySelector('#aas4') as HTMLElement;

        closeButton?.click();

        setTimeout(() => {
          const openButton = document.querySelector(
            '#tab-documents'
          ) as HTMLElement;
          openButton?.click();
        }, 100);
        resolve();
      });
    },
    title: 'Documentos',
    text: `<p>En esta pestaña podrás agregar documentos relacionados con tu asunto para que estén disponibles en cualquier momento para ti y para todos los miembros de tu equipo.</p>`,
  },
  {
    attachTo: {
      element: '#add-folder-button',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('add-folder-button') || false) as boolean;
    },
    title: 'Organiza tus archivos',
    text: `<p>Puedes organizar tus archivos relacionados con el asunto <span class="font-medium">utilizando carpetas</span>. Esto te ayudará en la gestión de tus documentos.</p>
    <p>No es obligatorio utilizar carpetas para tus archivos. Simplemente carga los archivos directamente y se almacenarán en la carpeta principal.</p>`,
  },
  {
    title: 'Arrastrar y Soltar',
    text: `<p>Puedes organizar tus archivos y carpetas arrastrando los elementos de la carpeta actual. Esto te ayudará a mantener un orden y una estructura clara en la gestión de tus documentos.</p>
    <img class="my-3" src="/assets/images/drag-drop.gif" alt="Arrastrar y Soltar">`,
  },
  {
    attachTo: {
      element: '#tab-collections',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('tab-collections') || false) as boolean;
    },
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        const openButton = document.querySelector(
          '#tab-collections'
        ) as HTMLElement;
        if (openButton) {
          openButton?.click();
        }
        resolve();
      });
    },
    title: 'Legislación',
    text: `<p>Nada como tener una sección donde puedes agregar leyes, sentencias, jurisprudencias y tesis para fundamentar tus asuntos.</p>`,
  },
  {
    attachTo: {
      element: '#tab-notes',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('tab-notes') || false) as boolean;
    },
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        const openButton = document.querySelector('#tab-notes') as HTMLElement;
        if (openButton) {
          openButton?.click();
        }
        resolve();
      });
    },
    title: 'Notas',
    text: `<p>Agrega notas a tu asunto para que todo el equipo este siempre en la misma página.</p>`,
  },
];
