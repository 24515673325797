import Step from 'shepherd.js/src/types/step';
import { VIEW_LATER_STEP } from 'src/app/core/constants/common.steps';

export const USER_GROUP_STEPS: Step.StepOptions[] = [
  {
    attachTo: {
      element:
        document.getElementById('law-firm-settings-content') ||
        '#law-firm-settings-content',
      on: 'top',
    },
    title: 'Sección de Grupos de Usuarios',
    text: `<p>Bienvenido a la sección de Grupos. En esta sección puedes administrar los grupos de usuarios de tu equipo de trabajo, podrás determinar configuraciones de acceso a asuntos por defecto y crear multiples grupos para tu despacho.</p>`,
  },
  {
    attachTo: {
      element:
        document.getElementById('group-add-button') || '#group-add-button',
      on: 'left',
    },
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        const closeButton = document.querySelector(
          '#close-group-modal'
        ) as HTMLElement;
        if (closeButton) {
          closeButton?.click();
        }
        setTimeout(() => {
          resolve();
        }, 0);
      });
    },
    showOn() {
      return (document.getElementById('group-add-button') || false) as boolean;
    },
    title: 'Agrega un nuevo grupo de usuarios',
    text: `<p>Esta opción te permite crear nuevos grupos, agregar una descripción opcional y asignar grupos al grupo nuevo.</p>`,
  },
  {
    attachTo: {
      element:
        document.getElementById('manage-group-form') || '#manage-group-form',
      on: 'left',
    },
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        const button = document.querySelector(
          '#group-add-button'
        ) as HTMLElement;
        if (button) {
          button?.click();
        }
        setTimeout(() => {
          resolve();
        }, 0);
      });
    },
    showOn() {
      return (document.getElementById('group-add-button') || false) as boolean;
    },
    title: 'Formulario de Creación',
    text: `<p>Diligencia todos los campos requeridos del formulario, puedes agregar una descripción opcional y asignar miembros de tu despacho al grupo.</p>`,
  },
  {
    attachTo: {
      element:
        document.getElementById('dossier-access-control') ||
        '#dossier-access-control',
      on: 'top',
    },
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        const button = document.querySelector(
          '#group-add-button'
        ) as HTMLElement;
        if (button) {
          button?.click();
        }
        setTimeout(() => {
          resolve();
        }, 0);
      });
    },
    title: 'Configuración de Acceso a Asuntos',
    text: `<p>Establece valores predeterminados para acceso a asuntos. Cuando agregues un grupo a un asunto, estos valores se aplicarán.
    </p><p><span class="font-semibold">Importante:</span> Todos los miembros compartirán el mismo acceso a los asuntos y si agregas nuevos miembros, estos tendrán acceso a todos los asuntos del grupo.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('save-group') || '#save-group',
      on: 'left',
    },
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        const closeButton = document.querySelector(
          '#close-group-modal'
        ) as HTMLElement;
        const button = document.querySelector(
          '#group-add-button'
        ) as HTMLElement;
        if (!closeButton && button) {
          button.click();
        }
        setTimeout(() => {
          resolve();
        }, 0);
      });
    },
    title: 'Guardar Cambios',
    text: `<p>Una vez termines de diligenciar el formulario, presiona el botón de Guardar para completar la creación del grupo.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('group-search') || '#group-search',
      on: 'left',
    },
    beforeShowPromise: function () {
      return new Promise<void>((resolve) => {
        const closeButton = document.querySelector(
          '#close-group-modal'
        ) as HTMLElement;
        if (closeButton) {
          setTimeout(() => {
            closeButton.click();
            resolve();
          }, 10);
        }
      });
    },
    title: 'Búsqueda de Grupos',
    text: `<p>Utiliza esta opción para buscar grupos por nombre o descripción.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('group-wrapper') || '#group-wrapper',
      on: 'left',
    },
    title: 'Listado de Grupos',
    text: `<p>Desde esta sección puedes ver el listado de grupos, editar un grupo existente o ver los miembros asociados a un grupo.</p>`,
  },
  VIEW_LATER_STEP,
];
