import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterForAutocomplete',
  pure: false,
})
export class FilterForAutocompletePipe implements PipeTransform {
  transform(items: any[], filter: string, disableFilter: boolean = false): any {
    if (!items || !filter || disableFilter) {
      return items;
    }

    const filterLower = filter.toLowerCase();
    const filteredItems = items.filter(
      (item) => item.label.toLowerCase().indexOf(filterLower) !== -1
    );

    if (filteredItems.length === 0) {
      return [{
        label: 'No se encontraron resultados',
        value: null,
      }];
    }

    return filteredItems;
  }
}
