import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AngularDeviceInformationService } from 'angular-device-information';

@Directive({
  selector: '[appMentionStyler]',
})
export class MentionStylerDirective implements OnChanges {
  @Input() appMentionStyler: any[] = []; // Las menciones que quieres destacar
  @Input() openMention: boolean = false; // Si quieres que se abra el contenedor de menciones
  @Input() idIdentify?: number;
  private el!: HTMLElement | any;
  private cursorPosition: number = 0;
  isDelete: boolean = false;
  public accents: string[] = ['¨', '´', '`'];

  constructor(
    private elementRef: ElementRef,
    private deviceInformationService: AngularDeviceInformationService
  ) {
    if (this.idIdentify) {
      this.el = document.getElementById(JSON.stringify(this.idIdentify));
    }
  }

  @HostListener('blur')
  onBlur2() {
    this.el = undefined;
  }

  @HostListener('input') async onInput(event: any) {
    if (this.openMention) return;
    this.Insert();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['openMention'] && this.el) {
      if (changes['openMention'].currentValue) this.el.focus();
      if (!changes['openMention'].currentValue) this.Insert();
    }
  }

  Insert() {
    const currentCursorPos = this.getCursorPosition(); // Obtener la posición actual del cursor
    let aplicateMention = false;
    let content = this.el.innerText;

    if (
      this.accents.some((accent) => this.el.innerText.includes(accent)) &&
      this.deviceInformationService.getDeviceInfo().os.includes('OS')
    ) {
      return;
    }
    this.appMentionStyler.forEach((item) => {
      // <span class="mention">@${item.label}</span>
      //<span class="text-xs inline-flex font-medium bg-indigo-100 text-indigo-600 rounded-full text-center px-2.5 py-1">@${item.label}</span>
      if (content.includes(`@${item.label}`)) {
        const regex = new RegExp(`@${item.label}`, 'g');
        content = content.replace(
          regex,
          `<span class="text-indigo-600">@${item.label}</span>`
        );
        aplicateMention = true;
      }
    });
    this.el.innerHTML = content;
    this.setCursorPosition(this.el, currentCursorPos);
  }

  private getCursorPosition(): number {
    const sel = window.getSelection();
    if (sel && sel.rangeCount > 0 && this.el.contains(sel.anchorNode)) {
      const range = sel.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(this.el);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      return preCaretRange.toString().length;
    }
    return 0; // Devuelve 0 si no se puede determinar la posición
  }

  setCursorPosition(element: HTMLElement, position: number) {
    let range = document.createRange();
    let sel = window.getSelection();

    let currentNode: Node | null | any = element;
    let charCount = 0; // Cuántos caracteres hemos recorrido

    if (!sel) return;
    // Este ciclo recorrerá todos los nodos de texto dentro del elemento hasta encontrar la posición correcta.
    while (currentNode) {
      if (currentNode.nodeType === Node.TEXT_NODE) {
        // Si es un nodo de texto
        const nextCharCount = charCount + currentNode.textContent!.length;
        if (position <= nextCharCount) {
          // Hemos encontrado el nodo de texto correcto. Ahora, debemos establecer el offset dentro de este nodo.
          range.setStart(currentNode, position - charCount);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
          return; // Terminamos, así que salimos de la función
        }
        charCount = nextCharCount;
      }

      // Si no hemos encontrado el nodo correcto, continuamos con el siguiente nodo.
      if (currentNode.hasChildNodes()) {
        currentNode = currentNode.firstChild;
      } else {
        if (!currentNode) return;

        while (!currentNode?.nextSibling && currentNode !== element) {
          currentNode = currentNode?.parentNode;
        }
        currentNode = currentNode.nextSibling;
      }
    }
  }

  @HostListener('focus', ['$event.target']) onFocus(target: any) {
    this.el = target;
  }
}
