import Step from 'shepherd.js/src/types/step';
import { VIEW_LATER_STEP } from 'src/app/core/constants/common.steps';
import { FilesAddComponent } from '../pages/files-add/files-add.component';

export const getCreateDossierSteps = (
  context: FilesAddComponent
): Step.StepOptions[] => [
  {
    attachTo: {
      element: document.getElementById('select-template') || '#select-template',
      on: 'bottom',
    },
    beforeShowPromise: () => {
      return new Promise<void>(async (resolve) => {
        if (context.selectedStepIndex !== 0) {
          context.previousStep();
        }
        setTimeout(() => {
          resolve();
        }, 0);
      });
    },
    title: 'Selección de Plantilla',
    text: `<p>Empieza por seleccionar una plantilla. Tienes la opción de elegir entre las plantillas que MiDespacho ofrece o puedes crear tu propia plantilla.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('search-template') || '#search-template',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('search-template') || false) as boolean;
    },
    title: 'Búsqueda de Plantilla',
    text: `<p>Asistete del buscador para encontrar una plantilla por nombre.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('Templates-0') || '#Templates-0',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('Templates-0') || false) as boolean;
    },
    beforeShowPromise: () => {
      return new Promise<void>(async (resolve) => {
        if (context.selectedStepIndex !== 0) {
          context.previousStep();
        }
        setTimeout(() => {
          resolve();
        }, 0);
      });
    },
    title: 'Previsualizar',
    text: `<p>Da clic derecho a cualquier plantilla para previsualizarla y verificar los campos que aparecerán en el asunto</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('dossier-form') || '#dossier-form',
      on: 'bottom',
    },
    beforeShowPromise: function () {
      return new Promise<void>(async (resolve) => {
        context.stepper?.selectedIndexChange.subscribe(() => {
          setTimeout(() => {
            resolve();
          }, 0);
        });
        const selectComponent = context.selectTemplateComponent;
        selectComponent?.onTemplateActivate(
          selectComponent.filteredTemplates[0]
        );
        context.nextStep();
      });
    },
    title: 'Llena la plantilla',
    text: `<p>Llena la plantilla para crear tu asunto. Recuerda completar todos los campos necesarios, identificados con un asterisco (<span class="text-rose-600">*</span>).</p>`,
  },
  {
    attachTo: {
      element:
        document.getElementById('access-management-wrapper') ||
        '#access-management-wrapper',
      on: 'top',
    },
    title: 'Configuración de Acceso',
    text: `<p>Ahora tienes más control sobre el acceso a tus asuntos. Podrás configurar acceso de miembros de tu despacho para que puedan ver o editar tu asunto.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('tab-all') || '#tab-all',
      on: 'top',
    },
    beforeShowPromise: function () {
      return new Promise<void>(async (resolve) => {
        context.accessManagementComponent?.changeType('all');
        setTimeout(() => {
          resolve();
        }, 0);
      });
    },
    title: 'Selección de Miembros',
    text: `<p>Desde esta sección podrás seleccionar miembros individualmente para que puedan ver o editar tu asunto.</p>`,
  },
  {
    attachTo: {
      element:
        document.getElementById('members-list-container') ||
        '#members-list-container',
      on: 'top',
    },
    title: 'Visualiza miembros y grupos con acceso',
    text: `<p>Acá verás los miembros y grupos que tienen acceso a tu asunto, así como el tipo de acceso (solo lectura, lectura y escritura)</p>`,
  },
  {
    attachTo: {
      element:
        document.getElementById('access-control-0') || '#access-control-0',
      on: 'top',
    },
    beforeShowPromise: function () {
      return new Promise<void>(async (resolve) => {
        context.accessManagementComponent?.changeType('all');
        setTimeout(() => {
          resolve();
        }, 0);
      });
    },
    title: 'Control de Acceso',
    text: `<p>Esta columna permite gestionar el acceso de tus miembros.</p><p><span class="font-semibold">Importante:</span> Los miembros de los grupos no pueden ser modificados individualmente, siempre tendrán el acceso que reciban de los grupos a los que pertenezcan.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('tab-groups') || '#tab-groups',
      on: 'top',
    },
    title: 'Selección de Grupos',
    text: `<p>Si tienes grupos de usuarios que comparten el mismo tipo de acceso a asuntos, puedes agregarlos a través de un grupo para gestionar el acceso de manera más rápida y sencilla.</p>`,
  },
  {
    attachTo: {
      element:
        document.getElementById('add-group-button') || '#add-group-button',
      on: 'top',
    },
    beforeShowPromise: function () {
      return new Promise<void>(async (resolve) => {
        context.accessManagementComponent?.changeType('groups');
        setTimeout(() => {
          resolve();
        }, 0);
      });
    },
    title: 'Agrega Nuevos Grupos',
    text: `<p>Usando este botón podrás agregar nuevos grupos para luego asignarlos a tus asuntos o seleccionar un grupo existente.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('submit-dossier') || '#submit-dossier',
      on: 'top',
    },
    title: 'Guardar Cambios',
    text: `<p>Una vez estés listo, presiona el botón para crear tu asunto.</p>`,
  },
  VIEW_LATER_STEP,
];
