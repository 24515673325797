<div class="flex items-start mb-4 last:mb-0 justify-end" style="overflow-anchor: none;">
  <div>
    <div
      class="prose text-sm bg-indigo-500 text-white p-3 rounded-lg rounded-tr-none border border-transparent shadow-md mb-1">
      <p>¿Qué puedes hacer?</p>
    </div>
  </div>
  <div class="flex items-center ml-4 gap-4"><img class="rounded-full size-10 shrink-0"
      src="/assets/images/profile-placeholder.png"></div>
</div>

<div class="flex items-start mb-4 last:mb-0" style="overflow-anchor: none;"><img src="/assets/images/isotipo.png"
    class="rounded-full mr-4 size-10 shrink-0">
  <div>
    <div class="bg-white text-slate-800 p-3 rounded-lg rounded-tl-none border border-slate-200 shadow-md mb-1">
      <div class="prose text-sm">
        <p>Puedo asistirte en varias áreas relacionadas con la gestión de tu despacho legal. Aquí tienes un resumen de
          mis capacidades:</p>
        <ol>
          <li>
            <p><strong>Interpretación y Síntesis de Documentos</strong>:</p>
            <ul>
              <li>Puedo responder preguntas específicas sobre el contenido de documentos PDF que hayas seleccionado para
                referenciar. Esto te permite acceder rápidamente a la información relevante sin tener que revisar todo
                el documento.</li>
            </ul>
          </li>
          <li>
            <p><strong>Asistencia Legal Básica</strong>:</p>
            <ul>
              <li>Ofrezco soporte y orientación rápida en temas legales básicos. Si tienes alguna pregunta legal
                general, puedo ayudarte a encontrar una respuesta.</li>
            </ul>
          </li>
          <li>
            <p><strong>Tutoriales de Plataforma</strong>:</p>
            <ul>
              <li>Puedo proporcionarte tutoriales interactivos y responder preguntas sobre los módulos, secciones y
                funcionalidades de la plataforma MiDespacho. Esto incluye ayudarte a maximizar el uso de las funciones
                de la plataforma.</li>
            </ul>
          </li>
        </ol>
        <p>Si tienes alguna pregunta específica o necesitas ayuda con algo en particular, no dudes en decírmelo. ¡Estoy
          aquí para ayudarte!</p>
      </div>
    </div>
  </div>
</div>