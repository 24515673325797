<div class="px-5 py-3 border-b bg-pearl-100 border-slate-200 sticky top-0 flex justify-between items-center">
  <span class="font-semibold text-primary-100">{{action() === 'create' ? 'Creación de Grupo' : 'Editar Grupo'}}</span>
  <button *ngIf="dialogRef" class="text-slate-400 hover:text-rose-500" (click)="dialogRef.close()"
    id="close-group-modal">
    <span class="sr-only" translate="CLOSE_STRING"></span>
    <i class="fa-solid fa-xmark"></i>
  </button>
</div>

<form [formGroup]="form" class="overflow-auto p-5 max-h-[calc(85svh-11.4rem)] grid grid-cols-1 gap-4 max-w-[50rem]"
  id="manage-group-form">
  <div *ngIf="action() !== 'create'"
    class="px-4 py-2 rounded-sm text-sm bg-sky-100 border border-sky-200 text-sky-600 flex w-full items-center gap-2">
    <i class="fa-regular fa-circle-info"></i>
    <p><span class="font-medium">Importante: </span>Al editar la configuración de acceso del grupo, se actualizará la
      configuración de acceso para todos los asuntos a los que ya pertenece el mismo.</p>
  </div>
  <span class="text-slate-800 font-medium pb-1 border-b border-slate-200">Información general</span>
  <div class="flex flex-col gap-1">
    <label class="text-sm font-medium text-slate-500" for="name" translate="NAME_STRING">
      <span class="text-rose-500">*</span>
    </label>
    <input id="name" formControlName="name" type="text" class="w-full form-input" [placeholder]="'Nombre del Grupo'" />
    <div *ngIf="controls['name'].errors && controls['name'].touched" class="text-xs font-medium max-w-xs text-rose-600">
      <span translate="REQUIRED_FIELD_STRING"></span>
    </div>
  </div>
  <div class="flex flex-col gap-1">
    <label class="text-sm font-medium text-slate-500" translate="DESCRIPTION_STRING" for="description">
    </label>
    <textarea id="description" formControlName="description" type="text" class="form-input max-h-[5rem]"
      [placeholder]="'Descripción'"></textarea>
  </div>

  <div class="flex flex-col gap-1">
    <label class="text-sm font-medium text-slate-500" for="member" translate="GROUP_MEMBERS"></label>
    <ng-select [multiple]="true" [clearable]="false" formControlName="members" bindLabel="label"
      placeholder="Selecciona miembros de tu despacho" class="ng-select-override" appendTo="body" labelForId="member">
      <ng-option *ngFor="let member of members" [value]="member" [disabled]="member.isDisabled">
        <div class="flex items-center gap-2">
          <img class="pointer-events-none size-10 shrink-0 shadow-inner border-2 border-white rounded-full"
            [src]="member.selfie || '/assets/images/profile-placeholder.png'" alt="Foto de Perfil" />
          {{member.label}}
        </div>
      </ng-option>
    </ng-select>
  </div>


  <div formGroupName="config" class="flex flex-col gap-2">
    <div class="flex gap-1.5 items-center pb-1 border-b border-slate-200">
      <i class="fa-solid fa-info-circle text-slate-400 text-[1rem] cursor-help"
        ngxTippy="Puedes editar la configuración de un grupo al agregarlo a un asunto. Todos los miembros del grupo tendrán el mismo acceso y no será editable a nivel del miembro, solo del grupo."></i>
      <span class="text-slate-800 font-medium">Configuración de Acceso a Asuntos</span>
    </div>

    <div class="flex flex-col gap-2 p-4 rounded bg-white border border-slate-200 shadow-sm" id="dossier-access-control">
      <p class="text-sm text-slate-600 text-pretty">
        En esta sección, puedes establecer una configuración predeterminada de acceso cuando agregas un grupo a un
        expediente.
      </p>
      <div class="text-sm font-semibold uppercase flex gap-6">
        <label class="flex items-center gap-2 text-sm text-slate-600 font-medium">
          <input type="checkbox" formControlName="readDossier" class="form-checkbox" />
          Lectura
        </label>
        <label class="flex items-center gap-2 text-sm text-slate-600 font-medium">
          <input type="checkbox" formControlName="writeDossier" class="form-checkbox" />
          Escritura
        </label>
      </div>
    </div>

  </div>
</form>

<div class="py-5 px-4 border-t bg-pearl-100 border-slate-200 sticky bottom-0 flex flex-wrap justify-end gap-2">
  <button *ngIf="dialogRef" [disabled]="loading | async" class="uisr-secondary-button" (click)="dialogRef.close()"
    translate="CANCEL_STRING">
  </button>
  <button [loadingElement]="resources.userGroup" class="uisr-primary-button" (click)="onSave()" id="save-group">
    <span translate="CARD_SAVE"></span>
  </button>
</div>