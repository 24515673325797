import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import {
  BehaviorSubject,
  distinctUntilChanged,
  Subject,
  switchMap,
} from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { APIResponse } from 'src/app/core/interfaces/api.interface';
import { UisrTools } from 'src/app/core/utils/uisr-tools';
import { PAGINATION_CONFIG } from 'src/app/features/law-firm/constants/pagination.constants';
import { ActivityFile } from 'src/app/features/law-firm/interfaces/activity.interfaces';
import { DossierFilter } from 'src/app/features/law-firm/interfaces/dossier-filter.interface';
import { StorageObjectFilter } from 'src/app/features/law-firm/interfaces/storage-object-filter.interface';
import { StorageObject } from 'src/app/features/law-firm/interfaces/storage-object.interface';
import { DossierIconStatusPipe } from 'src/app/features/law-firm/pipes/dossier-icon-status.pipe';
import { DossierStatusIconPipe } from 'src/app/features/law-firm/pipes/dossier-status-icon.pipe';
import { DossierDTO } from '../../../features/law-firm/interfaces/dossier.interfaces';
import { AppPaginationModule } from '../../modules/pagination.module';
import { loadingState } from '../../operators/loading-state.operator';
import { UisrApiServiceV2 } from '../../services/uisr-api.service-v2';
import { SimpleStorageObjectDataComponent } from './simple-storage-object-data/simple-storage-object-data.component';
@UntilDestroy()
@Component({
  selector: 'app-simple-doc-picker',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    AppPaginationModule,
    NgxTippyModule,
    DossierIconStatusPipe,
    DossierStatusIconPipe,
    SimpleStorageObjectDataComponent,
    InfiniteScrollModule,
    ReactiveFormsModule,
  ],
  templateUrl: './simple-doc-picker.component.html',
})
export class SimpleDocPickerComponent implements OnInit {
  data? = inject(DIALOG_DATA, { optional: true });
  dialogRef? = inject(DialogRef, { optional: true });
  loading = new BehaviorSubject(false);
  scrolling = new BehaviorSubject(false);
  loadingFolder = new BehaviorSubject(false);
  selectedFiles: Set<ActivityFile> = new Set();
  tabs = [{ label: 'Asuntos', action: 'dossiers' }];
  selectedTab = this.tabs[0].action;
  dossiers: DossierDTO[] = [];
  dossierFilterSubject = new Subject<DossierFilter>();
  dossierFilter: DossierFilter = {};
  dossierPagination = structuredClone(PAGINATION_CONFIG);
  selectedFolder?: StorageObject;
  selectedDossier?: DossierDTO;
  storageObjects: StorageObject[] = [];
  searchControl = new FormControl(null);
  defaultDossierId?: string;

  private readonly apiService = inject(UisrApiServiceV2);
  private readonly activatedRoute = inject(ActivatedRoute);

  @Input() prompt?: string;
  @Input() extensions?: string[] = [];

  @Output() selection = new EventEmitter<ActivityFile[]>();

  constructor() {
    this.dossierPagination.id = 'dossier-pagination';
    if (this.data) {
      this.prompt = this.data.prompt;
      this.extensions = this.data.extensions || [];
    }
  }

  ngOnInit() {
    this.subscribeToDossierFilter();
    if (this.selectedTab == 'dossiers') {
      this.applyDossierFilter({ pageNumber: 1, pageSize: 10 });
    }
  }

  /** Permite hacer modificaciones al filtro de asuntos antes de realizar una búsqueda, elimina los campos vacíos */
  applyDossierFilter(newFilter: DossierFilter) {
    this.dossierFilter = { ...this.dossierFilter, ...newFilter };

    this.dossierFilter = UisrTools.removeEmptyProperties(this.dossierFilter);

    this.dossierFilterSubject.next(this.dossierFilter);
  }

  /** Se ejecuta cuando el usuario confirma la selección, emite los ids de los documentos seleccionados */
  onSubmit() {
    const array = Array.from(this.selectedFiles);
    this.dialogRef?.close(array);
    this.selection.emit(array);
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  /** Permite cambiar la pestaña seleccionada en el componente solo si la nueva pestaña es diferente a la actual */
  changeTab(action: string) {
    if (this.selectedTab != action) {
      this.selectedTab = action;
    } else {
      this.selectedDossier = undefined;
    }
  }

  /** Se suscribe al observable del filtro de asuntos para realizar una búsqueda en cuanto hayan cambios */
  subscribeToDossierFilter() {
    this.dossierFilterSubject
      .pipe(
        distinctUntilChanged(isEqual),
        switchMap((filter) => {
          this.dossierPagination.currentPage = filter.pageNumber || 1;
          this.dossierPagination.itemsPerPage = filter.pageSize || 10;

          return this.apiService
            .post(RESOURCES.dossierList, filter)
            .pipe(loadingState(this.loading));
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: (res: APIResponse<DossierDTO[]>) => {
          this.dossiers = res.data;
          this.dossierPagination.totalItems = res.total || 0;

          this.defaultDossierId =
            this.activatedRoute.snapshot.params['dossierId'];

          if (this.defaultDossierId) {
            this.selectedDossier = this.dossiers.find(
              (x) => x.idDossier == Number(this.defaultDossierId)
            );

            this.defaultDossierId = undefined;
          }
        },
      });
  }

  clearSearch() {
    this.searchControl.setValue(null);
    this.onSearch();
  }

  /** Al realizar la búsqueda, si se ha seleccionado un asunto, buscar en storage objects, de lo contrario, filtrar los asuntos */
  onSearch() {
    if (this.selectedDossier) {
      if (this.searchControl.value) {
        this.onStorageObjectSearch();
      } else {
        this.getFolderData();
      }
    } else {
      this.applyDossierFilter({
        pageNumber: 1,
        searchValue: this.searchControl.value || undefined,
      });
    }
  }

  /** Realiza la búsqueda de storage object con el valor del input de búsqueda de carpetas y archivos */
  onStorageObjectSearch() {
    const filter: StorageObjectFilter = {};
    filter.dossierId = Number(this.selectedDossier?.idDossier);
    filter.searchValue = this.searchControl.value || undefined;
    if (!filter.dossierId) return;

    filter.fileExts = this.extensions;

    this.apiService
      .get(RESOURCES.storageObjects, filter)
      .pipe(loadingState(this.loading), untilDestroyed(this))
      .subscribe({
        next: (res: APIResponse<any>) => {
          this.storageObjects = res.data;

          this.selectedFolder = undefined;
        },
      });
  }

  /** Al hacer click en un archivo o carpeta, se determina si se debe consultar la información extra o si se agrega al arreglo de archivos seleccionados */
  onStorageObjectClick(storageObject: StorageObject) {
    if (storageObject.file) {
      if (this.selectedFiles.has(storageObject.file)) {
        this.selectedFiles.delete(storageObject.file);
      } else {
        this.selectedFiles.add(storageObject.file);
      }
    } else {
      if (storageObject.storageObjects && storageObject.open) {
        storageObject.open = false;
      } else if (!storageObject.storageObjects) {
        storageObject.open = true;
        this.getFolderData(storageObject);
      } else {
        storageObject.open = true;
      }
    }
  }

  /** Permite obtener los datos de una carpeta, obtiene sus archivos y sub-carpetas */
  getFolderData(folder?: StorageObject) {
    const filter: StorageObjectFilter = {};
    if (folder) {
      this.selectedFolder = folder;
      filter.dossierId = Number(this.selectedFolder.dossierId);
      filter.parentId = Number(this.selectedFolder.id);
    } else {
      filter.dossierId = Number(this.selectedDossier?.idDossier);
    }

    filter.fileExts = this.extensions;

    if (!filter.dossierId) return;

    this.apiService
      .get(RESOURCES.storageObjectDetails, filter)
      .pipe(loadingState(this.loadingFolder), untilDestroyed(this))
      .subscribe({
        next: (res: APIResponse<any>) => {
          if (!folder) {
            this.storageObjects = [...res.data.folders, ...res.data.files];
          } else {
            folder.storageObjects = [...res.data.folders, ...res.data.files];
            folder.totalFiles = res.data.totalFiles || 0;
          }

          this.selectedFolder = undefined;
        },
      });
  }

  /** Permite seleccionar un asunto y consultar sus archivos y carpetas. Solo se ejecuta si el asunto seleccionado es diferente al actual */
  selectDossier(dossier: DossierDTO) {
    if (this.selectedDossier?.idDossier != dossier.idDossier) {
      this.selectedDossier = dossier;
      this.getFolderData();
    }
  }

  nextPage() {
    if (
      this.dossierPagination.totalItems &&
      this.dossierPagination.totalItems > this.dossiers.length
    ) {
      this.dossierFilter.pageNumber = this.dossierPagination.currentPage + 1;
      this.apiService
        .post(RESOURCES.dossierList, this.dossierFilter)
        .pipe(loadingState(this.scrolling), untilDestroyed(this))
        .subscribe({
          next: (res: APIResponse<DossierDTO[]>) => {
            this.dossiers.push(...res.data);
            this.dossierPagination.currentPage =
              this.dossierFilter.pageNumber || 1;
            this.dossierPagination.itemsPerPage =
              this.dossierFilter.pageSize || 10;
            this.dossierPagination.totalItems = res.total || 0;
          },
        });
    }
  }
}
