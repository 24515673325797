import { FormControlConfig } from 'src/app/core/interfaces/form-control-config.interface';

export const PASSWORD_UPDATE_CONTROLS: FormControlConfig[] = [
  {
    type: 'password',
    label: 'Contraseña Actual',
    name: 'password',
    placeholder: 'Contraseña Actual',
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'password',
    label: 'Nueva Contraseña',
    name: 'new_password',
    placeholder: 'Nueva Contraseña',
    validators: [
      {
        type: 'required',
      },
    ],
  },
  {
    type: 'password',
    label: 'Confirmar Contraseña',
    name: 'passwordConf',
    placeholder: 'Confirmar Contraseña',
    validators: [
      {
        type: 'required',
      },
    ],
  },
];

export const TWO_FA_CHANGE_CONTROLS: FormControlConfig[] = [
  {
    type: 'password',
    label: 'Contraseña',
    name: 'password',
    placeholder: 'Ingresa tu Contraseña',
    validators: [
      {
        type: 'required',
      },
    ],
  },
];

export const EMAIL_CHANGE_CONTROLS: FormControlConfig[] = [
  {
    type: 'email',
    label: 'Email',
    name: 'email',
    placeholder: 'Ingresa el nuevo email',
    validators: [
      {
        type: 'required',
      },
    ],
  },
];

export const PROFILE_CONTROLS: FormControlConfig[] = [
  {
    type: 'text',
    label: 'Nombre(s)',
    name: 'first_name',
    placeholder: 'Nombre(s)',
    validators: [
      {
        type: 'required',
      },
    ],
  },
  // {
  //   type: 'text',
  //   label: 'Segundo Nombre',
  //   name: 'second_name',
  //   placeholder: 'Segundo Nombre',
  // },
  {
    type: 'text',
    label: 'Apellido(s)',
    name: 'last_name',
    placeholder: 'Apellido(s)',
    validators: [
      {
        type: 'required',
      },
    ],
  },
  // {
  //   type: 'text',
  //   label: 'Segundo Apellido',
  //   name: 'middle_name',
  //   placeholder: 'Segundo Apellido',
  // },
  {
    type: 'number',
    label: 'Teléfono',
    name: 'phone_number',
    placeholder: 'Teléfono',
    validators: [
      {
        type: 'maxLength',
        value: 12,
      },
    ],
  },
  {
    type: 'text',
    label: 'País',
    name: 'fk_id_country',
    placeholder: 'México',
    disabled: true,
    validators: [],
  },
  {
    type: 'text',
    label: 'Email',
    name: 'email',
    placeholder: 'test@test.com',
    disabled: true,
    validators: [
      {
        type: 'required',
      },
    ],
  },
];

export const PAY_STRIPE: FormControlConfig[] = [
  {
    type: 'text',
    label: 'Número de tarjeta',
    name: 'cardNumber',
    placeholder: '1234 1234 1234 1234',
    formatCleave: 'cardNumber',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'cardNumberValidator',
      },
    ],
  },
  {
    type: 'text',
    label: 'Fecha de expiración',
    name: 'cardExpired',
    placeholder: 'MM/YY',
    formatCleave: 'cardExpired',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'expiryDateValidator',
      },
    ],
  },
  {
    type: 'text',
    label: 'CVC',
    name: 'cvc',
    placeholder: 'CVC',
    formatCleave: 'CVC',
    validators: [
      {
        type: 'required',
      },
      {
        type: 'minLength',
        value: 3,
      },
      {
        type: 'maxLength',
        value: 3,
      },
    ],
  },
  {
    type: 'text',
    label: 'Nombre del titular',
    name: 'nameCard',
    placeholder: 'John Doe',
    formatCleave: 'nameCard',
    validators: [
      {
        type: 'required',
      },
    ],
  },
];

export const OPTIONS_CLEAVE = {
  cardNumber: {
    creditCard: true,
    onCreditCardTypeChanged: (type: any = '') => {
      // update UI ...
      if (type == 'unknown' || type == '') {
        return '';
      } else {
        type = type.toUpperCase();
        return type;
      }
    },
  },
  CVC: {
    blocks: [3],
    numericOnly: true,
  },
  cardExpired: {
    date: true,
    datePattern: ['m', 'y'],
  },
  nameCard: {
    uppercase: true,
  },
};
