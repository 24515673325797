export const ERROR_VALIDATORS = {
  required: 'Este campo es requerido.',
  minlength: 'Este campo debe tener al menos {{ requiredLength }} caracteres.',
  maxlength:
    'Este campo no puede tener más de {{ requiredLength }} caracteres.',
  pattern: 'Este campo contiene caracteres no válidos.',
  email: 'Por favor, ingresa un email válido.',
  min: 'Este campo debe ser mayor o igual a {{ min }}.',
  max: 'Este campo debe ser menor o igual a {{ max }}.',
  nullValidator: 'Este campo no puede ser nulo.',
  compose: 'Se produjeron uno o más errores de validación.',
  composeAsync: 'Se produjeron uno o más errores de validación.',
  cardNumberValidator: 'El número de tarjeta no es válido.',
  expiryDateValidator: 'La fecha de expiración no es válida.',
};
