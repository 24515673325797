// enum para declarar todas las animaciones base de uisr
export enum UisrAnimations {
    slideUp,
    slideDown,
    openState,
    closedState
}

// Utilidades para animaciones de uisr
export class UisrAnim {

    // Colección de animaciones base de uisr
    static baseAnim: any = {

        // Animación para ocultar hacia arriba
        slideUp: (ele: any) => {
            return {
                keyframes: [
                    {
                        height: '0px',
                    },
                ],
                easing: 'easeInSine',
                duration: '300'
            };
        },
    
        // Animación para mostrar hacia abajo
        slideDown: (ele: any) => {
            return {
                keyframes: [
                    {
                        height: ele.scrollHeight + 'px',
                    },
                ],
                easing: 'easeInSine',
                duration: '300'
            };
        },

        openState: (ele: any) => {
            return {
                keyframes: [
                    {
                        height: ele.scrollHeight + 'px',
                    },
                ],
                easing: 'easeInSine',
                duration: '0'
            };
        },
        
        closedState: (ele: any) => {
            return {
                keyframes: [
                    {
                        height: '0px',
                    },
                ],
                easing: 'easeInSine',
                duration: '0'
            };
        }

    }
  
    // Función para intentar reproducir una animación base
    static play(anim_name: string) {
      return this.baseAnim[anim_name];
    }
}