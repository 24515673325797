import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ContextService {
  searchComponentContext = new Subject<any>();
  dossierAddComponentContext = new Subject<any>();

  setSearchComponentContext(context: any) {
    this.searchComponentContext.next(context);
  }

  setDossierAddComponentContext(context: any) {
    this.dossierAddComponentContext.next(context);
  }

  getSearchComponentContext() {
    return this.searchComponentContext.asObservable();
  }

  getDossierAddComponentContext() {
    return this.dossierAddComponentContext.asObservable();
  }
}
