<div class="px-5 py-3 border-b bg-pearl-100 border-slate-200 sticky top-0 flex justify-between items-center">
    <span class="font-semibold text-primary-100 text-lg" [translate]="'NOT_VALIDATED_ACCOUNT'"></span>
    <button class="group" type="button" (click)="dialogRef.close()">
        <div class="sr-only">{{ 'CLOSE_STRING' | translate }}</div>
        <i class="fa-solid fa-xmark text-slate-400 group-hover:text-rose-500"></i>
    </button>
</div>
<div class="overflow-auto flex flex-col items-center gap-3 p-5 max-h-[calc(85svh-11.4rem)]">
    <div class="min-w-[8rem] min-h-[8rem] rounded-full bg-indigo-500/30 grid place-content-center">
        <i class="fa-solid fa-shield-xmark text-indigo-500/30" style="font-size: 4rem"></i>
    </div>
    <p class="mx-auto max-w-[30rem] text-center font-medium text-xl text-slate-800" [translate]="'UVA-500'"></p>
    <div class="flex flex-col items-center gap-2 font-medium text-xl">
        <span class="text-slate-800" [translate]="'DID_NOT_RECEIVE_EMAIL_QUESTION'"></span>
        <button class="uisr-primary-button" [loadingElement]="resources.resendValidationEmail" (click)="resendEmail()"><span>Haz clic aquí</span></button>
    </div>
</div>

<div class="py-5 px-4 border-t bg-pearl-100 border-slate-200 sticky bottom-0">
    <div class="flex flex-wrap justify-end space-x-2">
        <button class="uisr-secondary-button" (click)="dialogRef.close()">
            {{'CLOSE_STRING' | translate}}
        </button>
    </div>
</div>