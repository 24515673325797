import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, Self, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appModalOverlay]',
  exportAs: 'appModal',
})
export class ModalOverlayDirective {
  overlayRef: any;
  templateElement!: HTMLElement;

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    @Self() private overlayTemplateRef: TemplateRef<any>
  ) {}

  /* Abrir overlay */
  open() {
    let overlayConfig = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    this.overlayRef = this.overlay.create(
      new OverlayConfig({
        hasBackdrop: true,
        backdropClass: 'uisr-modal-backdrop',
      })
    );

    this.overlayRef.attach(
      new TemplatePortal(this.overlayTemplateRef, this.viewContainerRef)
    );
    this.overlayRef.addPanelClass('custom-overlay-panel');
    this.overlayRef.updatePositionStrategy(overlayConfig);

    this.overlayRef.backdropClick().subscribe(() => {
      this.close();
    });

    // Agregar el evento keydown al documento para cerrar el overlay al presionar 'Escape'
    document.addEventListener('keydown', this.onScapeDown);

    // Agregar evento close a los botones con la clase
    this.overlayRef._pane
      .querySelectorAll('.close-overlay-directive')
      .forEach((button: HTMLElement) => {
        button.addEventListener('click', () => {
          this.close();
        });
      });
  }

  /* Cerrar overlay al pulsar Escape */
  onScapeDown = (event: any) => {
    if (event.key === 'Escape') {
      this.close();
    }
  };

  /* Cerrar overlay de reportar resultado */
  close() {
    this.overlayRef.detach();
  }
}
