import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, lastValueFrom, switchMap, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import Swal from 'sweetalert2';
import { ALERT_DEFAULTS } from '../../constants/alert-defaults.constants';
import { RESOURCES } from '../../constants/resource-service.constants';
import { ComponentBlocker } from '../../utils/component-blocker';
import { toSignal } from '@angular/core/rxjs-interop';
import { LoadingElementDirective } from 'src/app/shared/directives/loading-element.directive';

@Component({
  selector: 'app-not-validated-user',
  standalone: true,
  imports: [CommonModule, TranslateModule, LoadingElementDirective],
  templateUrl: './not-validated-user.component.html',
})
export class NotValidatedUserComponent implements OnInit, OnDestroy {
  resources = RESOURCES;
  messages: any = {};
  loading = toSignal(
    this.blocker.loaderAll.pipe(
      switchMap((val) =>
        of(
          val.button.count &&
            val.button.paths.includes(this.resources.resendValidationEmail)
        )
      )
    )
  );

  private unsubscribe = new Subject<void>();

  constructor(
    private apiService: UisrApiServiceV2,
    private translateService: TranslateService,
    private blocker: ComponentBlocker,
    private toastr: ToastrService,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA)
    public data: any
  ) {}

  async ngOnInit() {
    this.messages = await this.getMessages();
  }

  async getMessages() {
    let allMessages = await lastValueFrom(
      this.translateService.get([
        'GENERIC_ERROR_MESSAGE',
        'VALIDATION_EMAIL_RESENT',
      ])
    );
    return allMessages;
  }

  resendEmail() {
    const data = {
      email: this.data.user,
    };
    this.apiService
      .post(this.resources.resendValidationEmail, data, null, ['button'])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            this.dialogRef.close(true);
            this.toastr.success(this.messages.VALIDATION_EMAIL_RESENT);
          } else {
            let message = this.messages.GENERIC_ERROR_MESSAGE;
            if (res.code) {
              let messageAux = this.translateService.instant(res.code);
              message = messageAux != res.code ? messageAux : message;
            }
            Swal.fire({
              ...(ALERT_DEFAULTS as any),
              ...{
                text: message,
              },
            });
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
