import Step from 'shepherd.js/src/types/step';
import { VIEW_LATER_STEP } from 'src/app/core/constants/common.steps';

export const getSearchSteps = (context: any): Step.StepOptions[] => [
  {
    attachTo: {
      element: document.getElementById('ss1') || '#ss1',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('ss1') || false) as boolean;
    },
    title: 'Búsqueda Inteligente',
    text: `<p> Experimenta la innovación en búsquedas. Nuestro buscador no solo entiende palabras clave, sino también el contexto y significado detrás de tu consulta.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('ss2') || '#ss2',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('ss2') || false) as boolean;
    },
    title: '¡Flexibilidad en tu Búsqueda!',
    text: `<p>Adapta tu búsqueda al instante. Alterna entre búsqueda semántica, por palabra clave o exacta con facilidad para afinar tus resultados.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('ss3') || '#ss3',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('ss3') || false) as boolean;
    },
    title: 'Aprende con Ejemplos',
    text: `<p>Inspírate con ejemplos de búsqueda. Haz clic aquí para ver métodos efectivos y mejorar tus estrategias de búsqueda legal.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('ss4') || '#ss4',
      on: 'right',
    },
    showOn() {
      return (document.getElementById('ss4') || false) as boolean;
    },
    title: 'Acceso a Múltiples Fuentes',
    text: `<p>Navega por una amplia gama de fuentes con un solo clic. Accede a distintas bases de datos para enriquecer tus investigaciones legales.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('ss5') || '#ss5',
      on: 'right',
    },
    showOn() {
      return (document.getElementById('ss5') || false) as boolean;
    },
    title: 'Filtros de Búsqueda Avanzados',
    text: `<p>Encuentra lo que necesitas con precisión. Utiliza filtros avanzados para segmentar y enfocar tus resultados de búsqueda.</p>`,
  },
  {
    attachTo: {
      element: '#ss6',
      on: 'right',
    },
    beforeShowPromise: () => {
      return context.search(
        'JUICIO DIVORCIO INCAUSADO. PENSIÓN ALIMENTICIA. DEMANDA DE AMPARO EXTEMPORÁNEA. - DIVORCIO',
        0
      );
    },
    title: 'Tipo de asunto',
    text: `<p>Acá puedes consultar cual es el tipo de asunto del resultado que te proporcionamos.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('ss7') || '#ss7',
      on: 'right',
    },
    showOn() {
      return (document.getElementById('ss7') || false) as boolean;
    },
    title: 'Tipo de materia',
    text: `<p>Acá puedes consultar cual es el tipo de materia del resultado que te proporcionamos.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('ss8') || '#ss8',
      on: 'right',
    },
    showOn() {
      return (document.getElementById('ss8') || false) as boolean;
    },
    title: 'Palabras clave',
    text: `<p>Estás son las palabras claves que identifican el documento que te proporcionamos.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('ss9') || '#ss9',
      on: 'right',
    },
    showOn() {
      return (document.getElementById('ss9') || false) as boolean;
    },
    title: 'Circuito / Juzgado / Órgano / Materia',
    text: `<p>Aquí puedes ver cual es el circuito al que pertenece, el juzgado y el organo o materia.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('ss10') || '#ss10',
      on: 'right',
    },
    showOn() {
      return (document.getElementById('ss10') || false) as boolean;
    },
    title: 'Botones de acción',
    text: `<p>Podrás ver todas las acciones con las cuales interactuar con este resultado.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('ss11') || '#ss11',
      on: 'right',
    },
    title: 'Colecciones Personalizadas',
    text: `<p>Crea colecciones para tus documentos más importantes. Agrupa y accede a sentencias y leyes relevantes de manera organizada y eficaz.</p>`,
  },
];

export const getAnalysisSteps = (context: any): Step.StepOptions[] => [
  {
    attachTo: {
      element: document.getElementById('as1') || '#as1',
      on: 'top',
    },
    showOn() {
      return (document.getElementById('as1') || false) as boolean;
    },
    title: 'Análisis',
    text: `<p>Desde esta vista podrás consultar información detallada sobre los documentos, resúmenes generados con IA y más.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('as2') || '#as2',
      on: 'top',
    },
    showOn() {
      return (document.getElementById('as2') || false) as boolean;
    },
    title: 'Información',
    text: `<p>En esta sección podrás encontrar Fecha de Sentencia, Órgano, Tipo de Asunto, Sub-Materia, Actos Reclamados, Actos Reclamados Específicos, Estado, Ciudad, Páginas en el PDF</p>`,
  },
  {
    attachTo: {
      element: '#as3',
      on: 'right',
    },
    beforeShowPromise: () => {
      return context.openPdf(context.resultDetail.urlPDF);
    },
    title: 'Ver PDF',
    text: `<p>Acá puedes consultar la sentencia original.</p>`,
  },
];

export const COLLECTIONS_STEPS: Step.StepOptions[] = [
  {
    attachTo: {
      element: document.getElementById('cs21') || '#cs21',
      on: 'left-end',
    },
    showOn() {
      return !!document.getElementById('cs21');
    },
    title: 'Bienvenido a Colecciones',
    text: `<p>Aquí encontrarás un espacio para visitar y ordenar tus sentencias guardadas.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('cs25') || '#cs25',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('cs25') || false) as boolean;
    },
    scrollTo: false,
    title: 'Buscador de sentencias',
    text: `<p>Asistete de este buscador para encontrar entre tus colecciones las sentencias.</p>`,
  },

  {
    attachTo: {
      element: document.getElementById('cs22') || '#cs22',
      on: 'left-end',
    },
    showOn() {
      return (document.getElementById('cs22') || false) as boolean;
    },
    scrollTo: false,
    title: 'Colecciones por defecto',
    text: `<p>Explora entre tus sentencias visitadas recientemente y entre tus favoritos guardados.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('cs23') || '#cs23',
      on: 'right-end',
    },
    showOn() {
      return (document.getElementById('cs23') || false) as boolean;
    },
    scrollTo: false,
    title: 'Crea tus propias colecciones',
    text: `<p>Esto te permitirá organizar tus sentencias de la manera que más te guste.</p>`,
  },
  {
    attachTo: {
      element: document.getElementById('cs24') || '#cs24',
      on: 'right-end',
    },
    showOn() {
      return (document.getElementById('cs24') || false) as boolean;
    },
    scrollTo: false,
    title: 'Sentencias por asunto',
    text: `<p>Visualiza las sentencias que has asociado a tus asuntos. Desde esta vista tienes la oportunidad de consultar los detalles de la sentencia.</p>`,
  },
  VIEW_LATER_STEP,
];
