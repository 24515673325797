import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';
import { UserData } from '../../models/user-data';
import { JsEncode } from '../../utils/js-encode';
import {
  SimpleUserDataLocalStorage,
  UserDataActionsTypes,
  UserDataSave,
} from './user-data.actions';

@Injectable()
export class SimpleUserDataEffects {
  constructor(private actions$: Actions) {}

  loadUserData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SimpleUserDataLocalStorage>(
        UserDataActionsTypes.SimpleLocalStorage
      ),
      map(() => {
        if (localStorage.getItem('UserData')) {
          let localData = JsEncode.decrypt(localStorage.getItem('UserData'));
          let dataUser: UserData = JSON.parse(localData);

          return new UserDataSave({
            UserData: dataUser,
          });
        } else {
          return new UserDataSave({
            UserData: {} as UserData,
          });
        }
      })
    );
  });
}
