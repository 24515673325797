import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'util';

@Pipe({
  name: 'valueFormDossier'
})
export class ValueFormDossierPipe implements PipeTransform {

  transform(value: string[] | string): string {
    if (Array.isArray(value)) {
      if (value.filter(item => typeof item === 'string').length > 0) {
        const valueForm = value.join(',');
        return valueForm;
      } else if (value.length == 0) {
        return 'SIN REGISTRO';
      } else {
        return value.map((elem: any) => {
          if (elem.names && elem.lastName) {
            return `${elem.names} ${elem.lastName}`;
          } else if (elem.names) {
            return `${elem.names}`;
          } else {
            return `${elem.fullName} ${elem.label}`;
          }
        }).join(', ');
      }
    }
    if (typeof value === 'string') {
      if (value.length == 0) return 'SIN REGISTRO';
      return value;
    }
    return `${value}`;
  }

}
