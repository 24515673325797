import { MenuItem } from '../interfaces/menu-item.interface';

export const MENU_ITEMS = {
  HOME: <MenuItem>{
    label: 'HOME_STRING',
    active: true,
    status: true,
    icon: 'fa-duotone fa-house',
    url: '/main',
  },
  CLIENT_PORTAL_STRING: <MenuItem>{
    label: 'CLIENT_PORTAL_STRING',
    active: true,
    status: true,
    icon: 'fa-folder-user',
    url: '/law-firm/cliente-files',
    public: true,
    children: [ 
      {
        label: 'HOME_STRING',
        active: true,
        status: true,
        url: '/main/client',
        icon: 'matSettingsSuggestOutline',
        public: true, 
      }, 
      {
        label: 'FILES_CLIENT_STRING',
        active: true,
        status: true,
        url: '/law-firm/cliente-files',
        icon: 'matSettingsSuggestOutline',
        public: true, 
      }, 
      {
        label: 'BLOG',
        active: true,
        status: true,
        url: '/law-firm/library/blog',
        icon: 'matReviewsOutline',
        public: true,
      },
    
    ],
  },
  SEARCH: <MenuItem>{
    label: 'INVESTIGATION_STRING',
    active: true,
    status: true,
    icon: 'fa-file-magnifying-glass',
    url: '/search',
    children: [
      {
        label: 'BASIC_SEARCH_STRING',
        active: true,
        status: true,
        url: '/search/b-search',
      },
      {
        label: 'COURTS_STRING',
        active: true,
        status: true,
        url: '/search/courts',
      },
      {
        label: 'JUDGES_AND_MAGISTRATES',
        active: false,
        status: true,
        url: '/search/judges',
      },
      {
        label: 'COLLECTIONS_STRING',
        active: true,
        id: 'menu-item-collections',
        status: true,
        url: '/search/collections',
      },
      {
        label: 'INTELLIGENT_SEARCH_STRING',
        active: false,
        status: true,
        url: '/main/i-search',
      },
    ],
  },
  SETTINGS: <MenuItem>{
    label: 'SETTINGS_STRING',
    active: false,
    status: true,
    icon: 'fa-gears',
    url: '/settings',
    children: [
      {
        label: 'MY_ACCOUNT_STRING',
        active: true,
        status: true,
        url: '/settings/account',
        icon: 'matManageAccountsOutline',
      },
      {
        id: 'menu-item-preferences',
        label: 'APP_PREFERENCES_STRING',
        active: true,
        status: true,
        url: '/settings/preferences',
        icon: 'matSettingsSuggestOutline',
      },
      {
        label: 'APP_PAYMENT_METHODS_STRING',
        active: true,
        status: true,
        url: '/settings/payment-methods',
        icon: 'matCreditCardOutline',
      },
      {
        id: 'menu-item-security',
        label: 'SECURITY_STRING',
        active: true,
        status: true,
        url: '/settings/security',
        icon: 'matSecurityOutline',
      },
      {
        label: 'FEEDBACK_STRING',
        active: true,
        status: true,
        url: '/settings/feedback',
        icon: 'matReviewsOutline',
      },
      {
        label: 'PLANS',
        active: true,
        status: true,
        url: '/settings/plans',
        icon: 'matRequestPageOutline',
      },
      {
        label: 'AFFILIATE',
        active: true,
        status: true,
        url: '/settings/affiliate-program',
        icon: 'matHandshakeOutline',
      },
    ],
  },
  LAW_FIRM: <MenuItem>{
    label: 'LAW_FIRM_STRING',
    active: true,
    status: true,
    icon: 'fa-briefcase-blank',
    url: '/law-firm',
    public: false,
    children: [
      {
        label: 'FILES_STRING',
        active: true,
        status: true,
        url: '/law-firm/files',
        icon: 'matSettingsSuggestOutline',
        public: false,
      }, 
      {
        label: 'CLIENTS_STRING',
        active: true,
        status: true,
        url: '/law-firm/clients',
        icon: 'matCreditCardOutline',
        permission: 'clientes:leer',
      },
      {
        label: 'ECONOMIC_COLLECTIONS_STRING',
        active: true,
        status: true,
        url: '/law-firm/e-collections',
        icon: 'matSecurityOutline',
        permission: 'cobranzas:leer',
      },
      {
        label: 'STATISTICS_STRING',
        active: true,
        status: true,
        url: '/law-firm/statistics',
        icon: 'matSecurityOutline',
        permission: 'estadisticas:ver',
      },
      {
        label: 'LIBRARY_STRING',
        active: true,
        status: true,
        url: '/law-firm/library',
        icon: 'matReviewsOutline',
        public: false,
      },
      {
        label: 'REPORTS',
        active: true,
        status: true,
        icon: 'fa-file-chart-column',
        url: '/law-firm/reports',
        permission: 'reportes:leer'
      },
      // {
      //   label: 'TASKS_STRING',
      //   active: true,
      //   status: true,
      //   url: '/law-firm/tasks',
      //   icon: 'matReviewsOutline',
      //   permission: 'tareas:leer',
      // },
      {
        label: 'SETTINGS_STRING',
        active: false,
        status: true,
        url: '/law-firm/settings',
        icon: 'matReviewsOutline',
        children: [
          {
            label: 'LAW_FIRM_INFO_STRING',
            active: true,
            status: true,
            url: '/law-firm/settings/info',
            icon: 'fa-wrench',
          },
          {
            label: 'TEAM_STRING',
            active: true,
            status: true,
            url: '/law-firm/settings/members',
            icon: 'fa-people-group',
          },
          {
            label: 'GROUPS_STRING',
            active: true,
            status: true,
            url: '/law-firm/settings/groups',
            icon: 'fa-users-rectangle',
          },
          {
            label: 'ROLES_STRING',
            active: true,
            id: 'ams5',
            status: true,
            url: '/law-firm/settings/roles',
            icon: 'fa-user-shield',
          },
          {
            label: 'TEMPLATES_STRING',
            active: true,
            status: true,
            url: '/law-firm/settings/templates',
            icon: 'fa-file',
          },
          {
            label: 'Amparo IA',
            active: true,
            status: true,
            url: '/law-firm/settings/assistant',
            icon: 'fa-robot',
            permission: 'asistente:configurar',
          },
          {
            label: 'Pagos',
            active: true,
            status: true,
            url: '/law-firm/settings/payments',
            icon: 'fa-receipt',
          },
        ],
      },
    ],
  },
  
};
