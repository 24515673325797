import { Dialog, DialogConfig } from '@angular/cdk/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import {
  DEFAULT_DIALOG_OPTIONS,
  DEFAULT_PREVIEW_OPTIONS,
  DEFAULT_SIDE_PANEL_OPTIONS,
} from '../constants/dialog.constants';
import { Overlay } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private defaultDialogOptions = DEFAULT_DIALOG_OPTIONS;
  private defaultSidePanelOptions = DEFAULT_SIDE_PANEL_OPTIONS;
  private defaultPreviewOptions = DEFAULT_PREVIEW_OPTIONS;

  constructor(private dialog: Dialog, private overlay: Overlay) {}

  openDialog<T>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, componentOptions?: DialogConfig) {
    const options: any = this.mergeOptions(
      this.defaultDialogOptions,
      componentOptions
    );
    const dialog = this.dialog.open(componentOrTemplateRef, options);

    return dialog.closed;
  }

  openSidePanel<T>(
    component: ComponentType<T>,
    componentOptions?: DialogConfig
  ) {
    const options: any = this.mergeOptions(
      this.defaultSidePanelOptions,
      componentOptions
    );
    const dialog = this.dialog.open(component, {
      ...options,
      positionStrategy: this.overlay.position().global().right('0').bottom('0'),
    });

    return dialog.closed;
  }

  openPreview<T>(component: ComponentType<T>, componentOptions?: DialogConfig) {
    const options: any = this.mergeOptions(
      this.defaultPreviewOptions,
      componentOptions
    );
    const dialog = this.dialog.open(component, options);

    return dialog.closed;
  }

  private mergeOptions(
    defaultOptions: DialogConfig,
    newInformation?: DialogConfig
  ) {
    return { ...defaultOptions, ...newInformation };
  }

  closeAll() {
    this.dialog.closeAll();
  }
}
