import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByBool',
  standalone: true
})
export class FilterByBoolPipe implements PipeTransform {

  transform(notifications: any[], field: string, value: boolean): any[] {
    return notifications.filter(notification => notification[field] === value);
  }

}