import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dossierStatusIcon',
  standalone: true,
})
export class DossierStatusIconPipe implements PipeTransform {

  transform(id: string | number): string {
    id = Number(id);
    let icon = 'fa-folders text-white';

    switch (id) {
      case 1:
        icon = 'fa-folders text-white';
        break;
      case 2:
        icon = 'fa-folder-closed text-white';
        break;
      case 3:
        icon = 'fa-box-archive text-white';
        break;
      default:
        icon = 'fa-folders text-white';
        break;
    }

    return icon;
  }

}
