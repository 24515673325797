import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BlockTypes } from 'src/app/core/interfaces/blockType.interface';

@Injectable({
  providedIn: 'root',
})
export class UisrApiService {
  httpClient: HttpClient;
  resource: string = '';
  constructor(private _httpClient: HttpClient) {
    this.httpClient = this._httpClient;
  }

  get(params?: any, blockType: Array<BlockTypes['type']> = ['global']): Observable<any> {
    return this.httpClient
      .get<any>(this.resource, { params: params, headers: { blockType: blockType.toString(), path: this.resource } })
      .pipe(map((res: any) => res));
  }

  put(data: any, params?: any, blockType: Array<BlockTypes['type']> = ['global']): Observable<string> {
    return this.httpClient
      .put<any>(this.resource, data, { params: params, headers: { blockType: blockType.toString(), path: this.resource } })
      .pipe(map((res: any) => res));
  }

  patch(data: any, params?: any, blockType: Array<BlockTypes['type']> = ['global']): Observable<string> {
    return this.httpClient
      .patch<any>(this.resource, data, { params: params, headers: { blockType: blockType.toString(), path: this.resource } })
      .pipe(map((res: any) => res));
  }

  post(data: any, params?: any, blockType: Array<BlockTypes['type']> = ['global']): Observable<any> {
    return this.httpClient
      .post(this.resource, data, { params: params, headers: { blockType: blockType.toString(), path: this.resource } })
      .pipe(map((res: any) => res));
  }

  delete(data: any, params?: any, blockType: Array<BlockTypes['type']> = ['global']): Observable<any> {
    return this.httpClient
      .delete(this.resource, { params: params, body: data, headers: { blockType: blockType.toString(), path: this.resource } })
      .pipe(map((res: any) => res));
  }

}
