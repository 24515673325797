<div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

    <div class="max-w-2xl m-auto mt-16">

        <div class="text-center px-4">
            <div class="inline-flex mb-8">
                <img src="/assets/images/404-illustration.svg" width="176" height="176" alt="404 illustration" />
            </div>
            <div class="mb-6">Hmm...this page doesn’t exist. Try searching for something else!</div>
            <a [routerLink]="'/main'" class="btn bg-primary-100 hover:bg-primary-200 text-pearl-100">Back To Dashboard</a>
        </div>

    </div>

</div>