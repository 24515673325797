import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, map, takeUntil } from 'rxjs/operators';
import { JsEncode } from 'src/app/core/utils/js-encode';
import Swal from 'sweetalert2';
import { NotValidatedUserComponent } from '../components/not-validated-user/not-validated-user.component';
import { RESOURCES } from '../constants/resource-service.constants';
import { BlockTypes, BodyLoading } from '../interfaces/blockType.interface';
import { UserData } from '../models/user-data';
import { Logout, UserDataSave } from '../reducer/user-data/user-data.actions';
import { UserDataFull } from '../reducer/user-data/user-data.selector';
import { DialogService } from '../services/dialog.service';
import { UisrAuthService } from '../services/uisr-auth.service';
import { LoadingService } from './loading.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  userData?: UserData;

  private resources = RESOURCES;
  private _unsubscribe = new Subject<void>();

  private tokenErrors = ['TKN-404', 'TKN-400'];

  processLoading: BodyLoading = {
    global: this.loaderService.loadingServices.getValue().global,
    form: this.loaderService.loadingServices.getValue().form,
    button: this.loaderService.loadingServices.getValue().button,
  };

  private readonly _router = inject(Router);

  constructor(
    private store: Store,
    private loaderService: LoadingService,
    private translateService: TranslateService,
    private authService: UisrAuthService,
    private dialogService: DialogService
  ) {
    this.store
      .pipe(select(UserDataFull), takeUntil(this._unsubscribe))
      .subscribe((data) => {
        this.userData = data;
        let lang = this.userData.id_languages
          ? this.userData.id_languages == 1
            ? 'es'
            : 'en'
          : 'es';
        this.translateService.use(lang);
      });
    if (!this.userData || !this.userData.id_languages) {
      this.translateService.use('es');
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let getPath = req.headers.get('path') as string;
    let blockTypes = req.headers.get('blockType')?.split(',') as Array<
      BlockTypes['type']
    >;
    this.setLoadingStatus(getPath, blockTypes);

    const token: string | null = localStorage.getItem('accessToken');
    if (token) {
      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token),
      });
    }
    req = req.clone({
      headers: req.headers.delete('blockType'),
    });
    req = req.clone({
      headers: req.headers.delete('path'),
    });
    req = req.clone({
      headers: req.headers.delete(
        'Accept',
        'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/'
      ),
    });

    return next.handle(req).pipe(
      map((event: any) => {
        try {
          let dataSession = event.body || event; // Simplificamos la obtención de dataSession

          // Simplificamos la condición para verificar el estado de mantenimiento
          const isInMaintenance: boolean =
            typeof dataSession?.data?.system?.maintenance === 'boolean'
              ? dataSession?.data?.system?.maintenance
              : typeof dataSession?.body?.system?.maintenance === 'boolean'
                ? dataSession?.body?.system?.maintenance
                : false;
          const emailIsFromUisr: boolean =
            dataSession?.data?.userData?.email?.includes('@uisr.io') || false;
          if (isInMaintenance) {
            if (emailIsFromUisr) {
              this.processEventBody(event); // Procesamos el cuerpo del evento
            } else {
              this.showMaintenanceSwal(); // Mostramos el mensaje de mantenimiento
            }
          } else {
            this.processEventBody(event); //  Procesamos el cuerpo del evento si no estamos en mantenimiento
          }
        } catch (error: any) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Ha ocurrido un error.',
            text: error,
            showConfirmButton: true,
          });
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        const errorCode = error.status;
        const apiError = error.error?.code || error.error?.data?.code || null;
        const type = typeof apiError;
        let parsedMessage = '';

        if (type != 'object') {
          parsedMessage = this._parseErrorCode(apiError);
        }

        if (errorCode === 0 || errorCode === 401) {
          if (getPath != this.resources.requestToken && apiError != 'UVA-500') {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Ha ocurrido un error.',
              html: parsedMessage,
              showConfirmButton: true,
            });
          }

          if (apiError == 'UVA-500') {
            this.dialogService.openDialog(NotValidatedUserComponent, {
              data: req.body,
            });
          }

          this.authService.clearStorage();
          this._router.navigate(['security/login']);
        } else {
          if (apiError == 'UVA-500') {
            this.dialogService.openDialog(NotValidatedUserComponent, {
              data: req.body,
            });

            return throwError(() => error);
          }

          if (apiError && this.tokenErrors.includes(apiError)) {
            this.authService.logout('');
          }

          if (apiError && type == 'object' && error.error.statusCode == 403) {
            let messageAux = this.translateService.instant('PERMISSIONS_ERROR');
            parsedMessage = messageAux;
          }

          if (getPath != this.resources.requestToken) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Ha ocurrido un error.',
              html: parsedMessage,
              showConfirmButton: true,
            });
          }
        }
        return throwError(() => error);
      }),
      finalize(() => {
        if (blockTypes) {
          blockTypes.forEach((el) => {
            this.processLoading[el].count = this.processLoading[el].count - 1;
            this.processLoading[el].paths = this.processLoading[
              el
            ].paths.filter((el: string) => el !== getPath);
          });
          this.loaderService.loadingServices.next(this.processLoading);
        }
      })
    );
  }

  setTokens(resBody: any) {
    if (resBody.authorization) {
      let token: string | null = resBody.authorization.token;
      let refreshToken: string | null = resBody.authorization.refresh;
      if (token) {
        localStorage.setItem('accessToken', token);
      }
      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
      }
      this.authService.updateStore(resBody.authorization);
    }
  }

  setLoadingStatus(
    getPath: string,
    blockTypes: Array<BlockTypes['type']>
  ): void {
    if (blockTypes && blockTypes.length > 0) {
      blockTypes.forEach((el) => {
        this.processLoading[el].count = this.processLoading[el].count + 1;
        this.processLoading[el].paths.push(getPath);
      });
      this.loaderService.loadingServices.next(this.processLoading);
    }
  }

  updateUserData(newUserData: any): void {
    if (newUserData) {
      localStorage.setItem(
        'UserData',
        JsEncode.encrypt(JSON.stringify(newUserData))
      );
      this.store.dispatch(
        new UserDataSave({
          UserData: newUserData as UserData,
        })
      );
    }
  }

  processEventBody(event: any): void {
    let resBody = event.body?.data || event.body;
    if (resBody) {
      this.setTokens(resBody);
    }

    // Simplificamos la desencriptación
    if (event.body && event.body?.data) {
      if (event.body?.crypt && event.body?.data) {
        event.body.data = JsEncode.decrypt(event.body.data);
      }
      event.body = {
        ...event.body.data,
        system: event.body.data.system || event.body.system,
        userData: event.body.data.userData || event.body.userData,
      };
    }
    if (event.body?.userData) {
      this.updateUserData(event.body.userData);
    }
  }

  showMaintenanceSwal(): void {
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: 'Mantenimiento',
      html: `
        Estimado usuario,
        <br>
        Estamos mejorando nuestra plataforma para ofrecerte una mejor experiencia.  
         Apreciamos tu paciencia, Por favor intente de nuevo más tarde.
        <br>              
        Para más información, contacta a nuestro equipo de ventas.
        <br>     
      `,
      showConfirmButton: true,
    });

    setTimeout(() => {
      this.authService.clearStorage();
      this.store.dispatch(new Logout());
      this._router.navigate(['security/login']);
    }, 100);
  }

  private _parseErrorCode(apiErrorCode: string | null) {
    let message = this.translateService.instant('GENERIC_ERROR_MESSAGE');
    if (apiErrorCode) {
      console.log(apiErrorCode);
      let messageAux = this.translateService.instant(apiErrorCode);
      message = messageAux != apiErrorCode ? messageAux : message;
    }

    message += `<p class='text-sm text-slate-500 text-right mt-4'>Código: ${apiErrorCode}</p>`;

    return message;
  }
}
