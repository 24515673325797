import { Location } from '@angular/common';
import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

/**
 * Directiva que aplica estilos de manera condicional a un elemento basado en la URL actual o en un input de texto y una segunda cadena de texto a comparar
 */
@Directive({
  selector: '[appStringConditionedStyle]',
  standalone: true,
})
export class StringConditionedStylingDirective implements OnChanges {
  /** La cadena de texto a buscar en la URL o a comparar con el input "baseString" usando el método "includes". */
  @Input('stringToMatch') str?: string;
  /** La cadena de texto base con la cual se compara "stringToMatch", si no se recibe, la comparación se hace con la URL actual */
  @Input('baseString') baseString?: string;
  /** Arreglo de texto con las clases que se aplicarán de manera condicional. */
  @Input() conditionalClasses?: string[];
  /** Objeto de estilos para ser aplicados de manera condicional. */
  @Input() conditionalStyles?: { [key: string]: string };

  constructor(
    private location: Location,
    private renderer: Renderer2,
    private el: ElementRef,
    private router: Router
  ) {
    this.router.events
      .pipe(
        filter((event: any) => {
          return event.routerEvent instanceof NavigationEnd;
        })
      )
      .subscribe((data) => this.checkUrl());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['str'] && changes['str'].currentValue) {
      this.checkUrl();
    }
  }

  checkUrl() {
    const baseString = this.baseString ? this.baseString : this.location.path();
    const isStringIncluded = baseString.includes(this.str || '');

    if (isStringIncluded) {
      if (this.conditionalClasses) {
        this.conditionalClasses.forEach((cls) => {
          this.renderer.addClass(this.el.nativeElement, cls);
        });
      }
      if (this.conditionalStyles) {
        Object.entries(this.conditionalStyles).forEach(([property, value]) => {
          this.renderer.setStyle(this.el.nativeElement, property, value);
        });
      }
    } else {
      if (this.conditionalClasses) {
        this.conditionalClasses.forEach((cls) => {
          this.renderer.removeClass(this.el.nativeElement, cls);
        });
      }
      if (this.conditionalStyles) {
        Object.entries(this.conditionalStyles).forEach(([property, value]) => {
          this.renderer.removeStyle(this.el.nativeElement, property);
        });
      }
    }
  }
}
