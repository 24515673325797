import { CdkStepper } from '@angular/cdk/stepper';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-uisr-stepper',
  templateUrl: './uisr-stepper.component.html',
  styleUrls: ["./uisr-stepper.component.scss"],
  providers: [{ provide: CdkStepper, useExisting: UisrStepperComponent }],
})
export class UisrStepperComponent extends CdkStepper {

  @Input() allowStepChange = true; 

  selectStepByIndex(index: number): void {
    if (this.allowStepChange) {
      this.selectedIndex = index;
    }
  }
}
