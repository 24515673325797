import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { UserDataLocalStorage } from '../../reducer/user-data/user-data.actions';
import { UisrAuthService } from '../../services/uisr-auth.service';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  @ViewChild(SidebarComponent, { static: true })
  sidebarComponent!: SidebarComponent;
  lastScrollTop: any = 0;

  constructor(
    private store: Store,
    private renderer: Renderer2,
    private authService: UisrAuthService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new UserDataLocalStorage());
    this.authService.startValidationLoop();
    this.authService.updatePermissions();
    this.authService.updateAssistantSettings();
    this.renderer.addClass(this.document.body, 'inner-app');
  }

  openSidebar() {
    this.sidebarComponent.openOrCloseSidebar();
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'inner-app');
  }
}
