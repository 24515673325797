import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Renderer2,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import {
  BehaviorSubject,
  distinctUntilChanged
} from 'rxjs';
import { ERROR_VALIDATORS } from 'src/app/core/constants/error-validators.constants';
import {
  ErrorNames,
  FormControlConfig,
} from 'src/app/core/interfaces/form-control-config.interface';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';
import { UisrTools } from 'src/app/core/utils/uisr-tools';
import { OPTIONS_CLEAVE } from 'src/app/features/settings/constants/settings-controls.constants';
import { UisrGenericFormControlsService } from '../../services/uisr-generic-form-controls.service';

@UntilDestroy()
@Component({
  selector: 'app-input-generic',
  templateUrl: './input-generic.component.html',
})
export class InputGenericComponent
  implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {
  public seeInput = new BehaviorSubject<boolean>(false);
  errorsArray: Array<any> = [];
  requiredCheck: boolean = false;
  model: any;
  localOptionsCleave: any = {};
  cardType: string = '';
  valueFormat: any = null;
  changeValue: boolean = true;
  isDarkTheme: boolean = false;
  @Input() value: any;
  @Input() showLabel: boolean = true;
  @Input() showErrors: boolean = false;
  @Input() hasSuffix: boolean = false;
  @Input() hasExtendedSuffix: boolean = false;
  @Input() inputClass: string | null = null;
  @Input() isTextArea: boolean = false;
  @Input() objectConfig: FormControlConfig = {
    type: 'text',
    label: '',
    name: '',
  };

  @ViewChild('genericInput', { static: false }) genericInput?: ElementRef;

  onChange = (value: any) => { };
  onTouched = () => { };

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public controlsService: UisrGenericFormControlsService,
    private cdr: ChangeDetectorRef,
    private store: Store,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this.store
      .pipe(select(UserDataFull), untilDestroyed(this))
      .subscribe((data) => {
        this.isDarkTheme = data.id_themes_preference == 2;
      });
  }
  returnDisabled(): boolean {
    if (this.ngControl.control?.disabled) {
      return true;
    } else {
      return false;
    }
  }

  lineBoxShadow(): string {
    if (this.returnDisabled()) {
      return '';
    }
    if (this.isDarkTheme) {
      return '-webkit-box-shadow: 0 0 0 30px rgba(51, 65, 85, var(--tw-border-opacity)) inset !important;';
    } else {
      return '-webkit-box-shadow: 0 0 0 30px white inset !important';
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.objectConfig.validators) {
        this.requiredCheck =
          this.objectConfig.validators?.filter((el) => el.type == 'required')
            .length > 0
            ? true
            : false;
      }
      this.seeInput.next(true);
    }, 0);
    setTimeout(() => {
      if (this.objectConfig.formatCleave && this.genericInput) {
        this.valueFormat = UisrTools.CleaveFormat(
          this.genericInput.nativeElement,
          this.localOptionsCleave[this.objectConfig.formatCleave]
        );
      }
      this.callSubscriptions();
    }, 10);
  }

  ngOnChanges(changes: any): void {
    this.localOptionsCleave = JSON.parse(JSON.stringify(OPTIONS_CLEAVE)); // esto realiza una copia profunda del objeto
    if (this.objectConfig.formatCleave == 'cardNumber') {
      this.localOptionsCleave['cardNumber'].onCreditCardTypeChanged = (
        type: any
      ) => {
        if (type == 'unknown' || type == '') {
          this.cardType = '';
        } else {
          this.cardType = type.toUpperCase();
        }
      };
    }
  }

  ngOnInit() { }
  callSubscriptions() {
    if (this.valueFormat == null) {
      this.ngControl.control?.valueChanges.subscribe((value) => {
        this.cdr.detectChanges();
        if (this.model === value) return;
        this.writeValue(value);
      });
    } else {
      this.ngControl.control?.valueChanges
        .pipe(distinctUntilChanged(), untilDestroyed(this))
        .subscribe((value) => {
          setTimeout(() => {
            if (this.objectConfig.formatCleave == 'cardExpired') {
              if (value?.length > 5) {
                this.ngControl.control?.setValue(
                  this.valueFormat.properties.result
                );
                this.ngControl.control?.updateValueAndValidity();
                return;
              } else {
                this.cdr.detectChanges();
                this.ngControl.control?.updateValueAndValidity();
                if (this.model === value) return;
                this.writeValue(value);
              }
            } else {
              if (
                this.valueFormat.properties.result !== value &&
                this.objectConfig.formatCleave != 'nameCard'
              ) {
                this.ngControl.control?.setValue(
                  this.valueFormat.properties.result
                );
                this.ngControl.control?.updateValueAndValidity();
                return;
              } else {
                this.cdr.detectChanges();
                this.ngControl.control?.updateValueAndValidity();
                if (this.model === value) return;
                this.writeValue(value);
              }
            }
          }, 10);
        });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onModelChange(event: any) {
    this.validateOnChange();
    this.onChange(event);
  }

  validateOnChange() {
    setTimeout(
      () => {
        if (this.ngControl.control?.errors) {
          this.errorsArray = Object.keys(
            this.ngControl.control?.errors as object
          ).map((key: any) => {
            console.log(key, this.ngControl.control?.errors)
            if (this.ngControl.control?.errors) {
              switch (key) {
                case 'minlength':
                case 'maxlength':
                  return ERROR_VALIDATORS[key as ErrorNames['type']].replace(
                    '{{ requiredLength }}',
                    this.ngControl.control.errors[key].requiredLength
                  );
                case 'min':
                  return ERROR_VALIDATORS[key as ErrorNames['type']].replace(
                    '{{ min }}',
                    this.ngControl.control.errors[key].min
                  );
                case 'max':
                  return ERROR_VALIDATORS[key as ErrorNames['type']].replace(
                    '{{ max }}',
                    this.ngControl.control.errors[key].max
                  );
                case 'cardNumberValidator':
                  return ERROR_VALIDATORS[key as ErrorNames['type']].replace(
                    '{{ cardNumberValidator }}',
                    this.ngControl.control.errors[key].cardNumberValidator
                  );
                case 'expiryDateValidator':
                  return ERROR_VALIDATORS[key as ErrorNames['type']].replace(
                    '{{ expiryDateValidator }}',
                    this.ngControl.control.errors[key].expiryDateValidator
                  );
                case 'pattern':
                  return ERROR_VALIDATORS[key as ErrorNames['type']].replace(
                    '{{ pattern }}',
                    this.ngControl.control.errors[key].pattern
                  );
                default:
                  return ERROR_VALIDATORS[key as ErrorNames['type']];
              }
            }
            return null;
          });
        } else {
          this.errorsArray = [];
        }
      },
      this.valueFormat ? 15 : 0
    );
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    this.model = value;
  }

  onClick() {
    this.validateOnChange();
    this.onTouched();
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'disabled',
      isDisabled
    );
  }

  checkTypeInput(type: string) {
    if (this.inputClass) {
      return this.inputClass;
    }
    switch (type) {
      case 'text':
      case 'password':
      case 'email':
        if (this.objectConfig.formatCleave == 'cardNumber') {
          return 'form-input w-full truncate';
        } else {
          return this.isTextArea ? 'form-input w-full min-h-[100px]' : 'form-input w-full truncate';
        }
      case 'checkbox':
        return 'form-checkbox truncate';
      case 'checkbox':
        return 'form-checkbox truncate';
      case 'radio':
        return 'form-radio truncate';
      default:
        return 'form-input w-full truncate';
    }
  }
  CheckTypeCard() {
    switch (this.cardType) {
      case 'JCB':
        return {
          type: 'a-cc-jcb',
          name: 'JCB',
        };
      case 'AMEX':
        return {
          type: 'fa-cc-amex',
          name: 'AMEX',
        };
      case 'VISA':
        return {
          type: 'fa-cc-visa',
          name: 'VISA',
        };
      case 'DINERS':
        return {
          type: 'fa-cc-diners-club',
          name: 'DINERS',
        };
      case 'DISCOVER':
        return {
          type: 'fa-cc-discover',
          name: 'DISCOVER',
        };
      case 'MASTERCARD':
        return {
          type: 'fa-cc-mastercard',
          name: 'MASTERCARD',
        };
      default:
        return {
          type: '',
          name: '',
        };
    }
  }

  
}
