import { Injectable } from "@angular/core";
import { LoadingService } from "../interceptor/loading.service";
import { BodyLoading } from "src/app/core/interfaces/blockType.interface";
import { BehaviorSubject } from "rxjs";
@Injectable()
export class ComponentBlocker {
    public loaderAll: BehaviorSubject<BodyLoading> = new BehaviorSubject(new BodyLoading());
    public loaderGlobal: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loaderForm: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loaderButton: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(public loaderService: LoadingService) {
        this.loaderService.loadingServices.subscribe((el: BodyLoading) => {
            this.loaderAll.next(el)
            this.loaderGlobal.next(el.global.count > 0 ? true : false)
            this.loaderForm.next(el.form.count > 0 ? true : false)
            this.loaderButton.next(el.button.count > 0 ? true : false)

        })
    }

}