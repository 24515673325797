import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dossierIconStatus',
  standalone: true,
})
export class DossierIconStatusPipe implements PipeTransform {
  transform(id: number | string): string {
    id = Number(id);
    let styles = 'bg-gray-500';
    switch (id) {
      case 1:
        styles = 'bg-emerald-500';
        break;
      case 2:
        styles = 'bg-gray-500';
        break;
      case 3:
        styles = 'bg-rose-500';
        break;
      default:
        styles = 'bg-gray-500';
        break;
    }
    return styles;
  }
}
