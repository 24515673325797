<span class="block text-sm font-medium mb-1">
    {{ label }}
</span>
<div class="w-full flex flex-col items-center mx-auto">
    <div class="w-full">
        <div class="flex flex-col items-center relative">
            <div class="w-full  svelte-1l8159u">
                <div class="p-1 flex border border-gray-200 bg-pearl-100 rounded svelte-1l8159u form-input w-full !py-0 min-h-[2.375rem]"
                    [ngClass]="[this.ngControl.control?.errors &&this.ngControl.control?.touched ? 'border-rose-300' : '',  
                this.ngControl.control?.disabled ? '!border-slate-200 !bg-slate-100 text-slate-400 !cursor-not-allowed' : '' ]">
                    <div class="flex flex-auto flex-wrap gap-[.39rem] my-[.313rem]">

                        <div *ngFor="let item of this.optionSelected"
                            class="flex justify-center m-0 items-center font-medium py-1 px-2 rounded-xl text-indigo-700 bg-indigo-100 border border-indigo-300 !min-h-[1.5rem]">
                            <div class="text-xs font-normal leading-4 max-w-full flex-initial">{{item.label}}</div>
                            <div class="flex flex-auto flex-row-reverse">
                                <div (click)="removeItem(item)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none"
                                        viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-x cursor-pointer hover:text-indigo-400 rounded-full w-4 h-4 ml-2">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="flex-1">
                            <input placeholder=""
                                [ngClass]="[ this.ngControl.control?.disabled ? '!cursor-not-allowed' : '' ]"
                                class="clean-input h-full w-full text-gray-800 min-w-[3rem] min-h-[1.47rem]"
                                [disabled]=" this.ngControl.control?.disabled || false" autocomplete="new-password"
                                [(ngModel)]="valuekeyPress" [placeholder]="label" #inputText
                                (focus)="focus()">
                        </div>
                    </div>
                </div>
                <div class="ul-list z-10 absolute left-0 top-full overflow-auto max-h-32 xl:max-h-40 font-medium text-sm text-slate-600 divide-y divide-slate-200 bg-white border border-slate-200 rounded py-1.5 w-full mt-1"
                    [ngClass]="{ 'hidden': !statusSelect }">
                    <div *ngFor="let item of options | filterForAutocomplete: valuekeyPress"
                        class="flex items-center justify-between w-full hover:bg-slate-50 py-2 px-3 cursor-pointer"
                        [ngClass]="{ 'text-primary-100': checkListOptions(item)  }" (click)="selectOption(item)">
                        <span *ngIf="!item?.disabled; else disabled">
                            {{ item.label }}
                        </span>
                        {{checkListOptions(item) ? '✓' : ''}}
                        <ng-template #disabled>
                            {{ item.label }}
                        </ng-template>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>