<div *ngIf="paginationConfig"
  class="flex flex-col items-center gap-3 p-5 xs:flex-row xs:justify-between bg-pearl-100 border-t border-slate-200"
  [ngClass]="{'xl:!justify-end': !paginationConfig.totalItems || paginationConfig.totalItems <= 1}">
  <div class="gap-2 text-sm text-center text-slate-500 sm:text-left"
    *ngIf="paginationConfig.totalItems && paginationConfig.totalItems > 1"
    [innerHTML]="'PAGINATION_VALUES' | translate: {lowerLimit: (paginationConfig.currentPage - 1) * paginationConfig.itemsPerPage + 1, upperLimit: getUpperPaginationLimit(), totalItems: paginationConfig.totalItems }">
  </div>
  <pagination-template #p="paginationApi" [maxSize]="visiblePages" [id]="paginationConfig.id || ''"
    class="col-span-12 flex flex-col sm:flex-row !justify-center sm:justify-start" (pageChange)="onPageChange($event)">
    <nav class="flex" role="navigation" aria-label="Navigation">
      <ul class="inline-flex text-sm font-medium space-x-2 scale-75 sm:scale-100">
        <li [class.disabled]="p.isFirstPage()" class="flex">
          <button
            class="grid place-content-center rounded leading-5 px-2.5 py-2 bg-pearl-100 hover:bg-indigo-500 border border-slate-200 text-slate-600 hover:text-white shadow-sm"
            *ngIf="!p.isFirstPage()" (click)="p.previous()">
            <span class="sr-only" translate="PREVIOUS_STRING"></span>
            <i class="fa-duotone fa-chevron-left text-[1rem]"></i>
          </button>
        </li>
        <div class="inline-flex text-sm font-medium shadow-sm -space-x-px">
          <li [class.current]="paginationConfig.currentPage === page.value" *ngFor="let page of p.pages; let i = index">
            <button (click)="p.setCurrent(page.value)"
              class="inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-pearl-100 hover:bg-indigo-500 border border-slate-200 text-slate-600 hover:text-white"
              [ngClass]="{'rounded-l': i === 0, 'rounded-r': i === p.pages.length - 1}"
              *ngIf="paginationConfig.currentPage !== page.value">
              <span>{{ page.label }}</span>
            </button>
            <button
              class="inline-flex items-center justify-center leading-5 px-3.5 py-2 bg-pearl-100 border border-slate-200 text-indigo-500"
              [ngClass]="{'rounded-l': i === 0, 'rounded-r': i === p.pages.length - 1}"
              *ngIf="paginationConfig.currentPage === page.value">
              <span>{{ page.label }}</span>
            </button>

          </li>
        </div>
        <li *ngIf="!p.isLastPage()" class="flex">
          <button
            class="grid place-content-center rounded leading-5 px-2.5 py-2 bg-pearl-100 hover:bg-indigo-500 border border-slate-200 text-slate-600 hover:text-white shadow-sm"
            (click)="p.next()">
            <span class="sr-only" translate="NEXT_STRING"></span>
            <i class="fa-regular fa-chevron-right" style="font-size: 1rem;"></i>
          </button>
        </li>
      </ul>
    </nav>
  </pagination-template>
</div>