import Step from 'shepherd.js/src/types/step';


export const ADD_MEMBER_STEPS: Step.StepOptions[] = [
    {
      attachTo: {
        element: document.getElementById('ams1') || '#ams1',
        on: 'bottom',
      },
      showOn() {
        return (document.getElementById('ams1') || false) as boolean;
      },
      beforeShowPromise: function() {
        return new Promise<void>((resolve) => {
          const closeButton = document.querySelector('#close-add-member-modal') as HTMLElement;
          if (closeButton) {
            closeButton?.click();
          }
          resolve();
        });
      },
      title: 'Invitar a un nuevo miembro',
      text: `<p>Amplía tu equipo fácilmente. Aquí podrás enviar invitaciones a nuevos miembros simplemente ingresando sus emails.</p>`,
    },
    {
      attachTo: {
        element: document.getElementById('ams2') || '#ams2',
        on: 'top',
      },
      beforeShowPromise: function() {
        return new Promise<void>((resolve) => {
          const ams3Element = document.querySelector('#ams1') as HTMLElement;
          ams3Element?.click();
          resolve();
        });
      },
      title: 'Invitaciones Múltiples',
      text: `<p>Optimiza tu tiempo invitando a varios colaboradores de una sola vez. Añade un email, presiona enter y continúa con el siguiente.</p>`,
    },
    {
      attachTo: {
        element: document.getElementById('ams3') || '#ams3',
        on: 'top',
      },
      showOn() {
        return (document.getElementById('ams3') || false) as boolean;
      },
      beforeShowPromise: function() {
        return new Promise<void>((resolve) => {
          const closeButton = document.querySelector('#close-add-member-modal') as HTMLElement;
          if (closeButton) {
            closeButton?.click();
          }
          resolve();
        });
      },
      title: 'Acciones',
      text: `<p>Este es tu centro de control para el equipo. Administra miembros y ajusta sus permisos según sea necesario en esta sección.</p>`,
    },
    {
      attachTo: {
        element: document.getElementById('ams4') || '#ams4',
        on: 'left',
      },
      showOn() {
        return (document.getElementById('ams4') || false) as boolean;
      },
      title: 'Filtrar Miembros',
      text: `<p>Encuentra rápidamente a los miembros de tu equipo usando filtros como estado de la invitación y más.</p>`,
    },
    {
      attachTo: {
        element: document.getElementById('ams5') || '#ams5',
        on: 'right',
      },
      showOn() {
        return (document.getElementById('ams5') || false) as boolean;
      },
      title: 'Roles',
      text: `<p>Administra eficientemente los niveles de acceso asignando roles específicos a cada miembro de tu equipo desde aquí.</p>`,
    },
  ];