import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { UisrAnim, UisrAnimations } from '../../core/utils/uisr-animations';
import anime from 'animejs';
import { AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appAnimeJs],[animeJs]',
})
export class AnimeJsDirective implements OnChanges, OnDestroy, AfterViewInit {
  // Implementacion de la libreria anime.js para tenerla disponible
  // como directiva a lo largo de la aplicacion.
  //
  // referencias:
  // - https://animejs.com/documentation/#cssSelector
  // - https://stackblitz.com/edit/angular-animejs-z9xqet?file=src%2Fapp%2Fapp.component.ts

  @Input() animeJs: UisrAnimations | object | null | string = null;
  private animeInstance: any;

  constructor(private elRef: ElementRef) {}

  ngOnDestroy(): void {
    this.dispose();
  }

  ngAfterViewInit(): void {
    this.updateAnimation({ animeJs: '' });
  }

  ngOnChanges(changes: any): void {
    this.updateAnimation(changes);
  }

  updateAnimation(changes: any) {
    if ('animeJs' in changes) {
      // Si el tipo del parametro de la directiva es string
      if (typeof this.animeJs === 'string') {
        // Se intenta buscar la animacion en la libreria base de animaciones
        if (UisrAnim.play(this.animeJs)) {
          // Si la animacion existe eliminamos las animaciones actuales
          this.dispose();

          // Aplicamos la nueva animacion
          this.animeInstance = anime({
            targets: this.elRef.nativeElement,
            ...UisrAnim.play(this.animeJs)(this.elRef.nativeElement),
          });
        }
      }

      if (typeof this.animeJs === 'object') {
        // Se infiere que el parametro de la directiva es un objeto
        // y se aplica el objeto a la animacion

        this.dispose();
        this.animeInstance = anime({
          targets: this.elRef.nativeElement,
          ...(this.animeJs as any),
        });
      }
    }
  }

  private dispose() {
    if (this.animeInstance) {
      anime.remove(this.elRef.nativeElement);
      this.animeInstance = null;
    }
  }
}
