import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { AccessToken, UserData } from '../models/user-data';
import { SaveToken } from '../reducer/user-data/user-data.actions';
import {
  AccessTokenSelector,
  UserDataFull,
} from '../reducer/user-data/user-data.selector';
import { UisrAuthService } from '../services/uisr-auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  /** JWT obtenido desde el reducer */
  DataToken: AccessToken = {} as AccessToken;
  UserDataFull: UserData = {} as UserData;
  isDarkTheme: boolean = false;
  private unsubscribe = new Subject<void>();
  /** JWT como objeto obtenido desde el token en el localStorage */
  private currentToken: any | null = null;
  /** JWT token sin decodificar */
  private tokenRaw: string | null = null;

  blogUrl = 'law-firm/library/post';
  doNotRedirect = ['/welcome', '/payment-success'];
  private _router = inject(Router);

  constructor(
    private store: Store,
    private translateService: TranslateService,
    private authService: UisrAuthService,
    private jwtHelper: JwtHelperService
  ) {
    this.checkAndUpdateDataLogic();
    this.updateStore();
  }

  setThemeClass() {
    if (this.isDarkTheme === true) {
      document.getElementsByTagName('body')[0].classList.add('dark');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('dark');
    }
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.checkAndUpdateDataLogic();
    if (this.currentToken) {
      this.store
        .pipe(select(UserDataFull), takeUntil(this.unsubscribe))
        .subscribe((data) => {
          this.UserDataFull = data;
          this.isDarkTheme = this.UserDataFull.id_themes_preference == 2;
          let lang = this.UserDataFull.id_languages
            ? this.UserDataFull.id_languages == 1
              ? 'es'
              : 'en'
            : 'es';
          this.translateService.setDefaultLang(lang);
          this.translateService.use(lang);
          this.setThemeClass();
          if (
            this.UserDataFull?.id_users &&
            this.UserDataFull.profileComplete === false &&
            !this.doNotRedirect.includes(state.url) &&
            (this.UserDataFull?.idWorkspace ||
              this.UserDataFull?.id_workspace_member)
          ) {
            this._router.navigate(['/welcome']);
          }
        });

      this.authService.tokenExpirationTime = this.currentToken.exp;
      let validSession = await this.authService.executeTokenValidation();

      if (validSession) {
        return true;
      } else {
        return this.sessionExpired(state.url);
      }
    } else {
      this.translateService.use('es');
      return this.sessionExpired(state.url);
    }
  }

  private checkAndUpdateDataLogic() {
    let localStorageToken = localStorage.getItem('accessToken') || null;
    if (localStorageToken && this.tokenRaw != localStorageToken) {
      try {
        this.currentToken =
          this.jwtHelper.decodeToken(localStorageToken) || null;
      } catch (error: any) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Ha ocurrido un error.',
          text: error,
          showConfirmButton: true,
        });

        this.currentToken = null;
      }
    }
  }

  updateStore() {
    if (this.currentToken) {
      let AccessToken: AccessToken = {
        accessToken: this.tokenRaw || '',
        expiresIn: this.currentToken.exp,
        tokenType: 'JWT',
      };

      this.store.dispatch(
        new SaveToken({
          AccessToken: AccessToken as AccessToken,
        })
      );

      this.store
        .pipe(select(AccessTokenSelector), takeUntil(this.unsubscribe))
        .subscribe((data) => {
          this.DataToken = data;
        });

      this.store
        .pipe(select(UserDataFull), takeUntil(this.unsubscribe))
        .subscribe((data) => {
          this.UserDataFull = data;
        });
    }
  }

  private async sessionExpired(url: string): Promise<boolean> {
    this.authService.logout(url);
    return false;
  }
}
