<div class="px-5 py-3 border-b bg-pearl-100 border-slate-200 sticky top-0 flex justify-between items-center"
  *ngIf="dialogRef">
  <span class="font-semibold text-primary-100" translate="ADD_DOCUMENTS"></span>
  <button class="text-slate-400 hover:text-rose-500 size-6 rounded-full shrink-0" (click)="dialogRef.close()"
    id="close-doc-picker">
    <span class="sr-only" translate="CLOSE_STRING"></span>
    <i class="fa-solid fa-xmark"></i>
  </button>
</div>

<div class="pt-5 max-h-[calc(85svh-11.4rem)] flex flex-col w-[90svw] max-w-2xl" id="doc-picker-container">
  <p class="px-5 text-slate-600 text-sm" *ngIf="prompt">{{prompt}}</p>

  <!-- Tabs -->
  <div class="flex pb-2 mt-4 border-b border-slate-200 min-w-0">
    <ng-container *ngIf="!selectedDossier">
      <div *ngFor="let tab of tabs; let i = index; trackBy:trackByFn" (click)="changeTab(tab.action)"
        class="cursor-pointer grid place-items-center">
        <a [id]="'tab-'+tab.action" [ngClass]="{'active': selectedTab == tab.action}"
          class="px-5 max-md:py-2 text-slate-400 active:text-indigo-500 font-medium active:font-semibold">
          {{tab.label}}
        </a>
      </div>
    </ng-container>
    <span class="pl-5 max-md:py-2 text-indigo-500 font-semibold truncate"
      *ngIf="selectedDossier">{{selectedDossier.name}}</span>
  </div>


  <!-- Input de búsqueda -->
  <div class="py-2 px-5 flex gap-2 border-b border-slate-200">
    <button class="uisr-secondary-button bg-white" (click)="selectedDossier = undefined" [disabled]="!selectedDossier">
      <i class="fa-solid fa-arrow-left" ngxTippy="Volver a Asuntos"></i>
    </button>
    <div class="relative grow">
      <label for="simple-doc-picker-search" class="sr-only" translate="SEARCH_PLACEHOLDER_STRING"></label>
      <input (keydown.enter)="$event.preventDefault();onSearch()" id="simple-doc-picker-search"
        [formControl]="searchControl" class="form-input w-full !pr-14" type="search"
        [placeholder]="this.selectedDossier ? 'Busca por nombre del archivo o carpeta' : 'Busca por titulo, número u órgano del asunto'">
      <div class="absolute flex gap-2 right-3 top-2/4 -translate-y-2/4">
        <button type="submit" *ngIf="searchControl.value" aria-label="Limpiar" ngxTippy data-tippy-content="Limpiar"
          (click)="clearSearch()" class="px-2 border-r border-slate-200" [tippyProps]="{placement: 'top'}">
          <i class="fa-duotone error-icon fa-xmark text-[0.8rem]" style="--fa-secondary-opacity: 0.8 !important"></i>
        </button>
        <button (click)="onSearch()" type="submit" aria-label="Buscar" ngxTippy
          data-tippy-content="Clic aquí para buscar" [tippyProps]="{placement: 'top'}">
          <i class="fa-duotone fa-magnifying-glass secondary-icon text-[0.8rem]"></i>
        </button>
      </div>
    </div>
  </div>

  <!-- Listado de Asuntos -->
  <div class="bg-pearl-100 divide-y divide-slate-200 overflow-y-auto relative"
    *ngIf="!selectedDossier && !(loading | async) && dossiers[0]" infiniteScroll [infiniteScrollDistance]="0"
    [infiniteScrollThrottle]="0" [scrollWindow]="false" [immediateCheck]="true" [alwaysCallback]="true"
    (scrolled)="nextPage()">
    <button type="button" *ngFor="let dossier of dossiers; let i = index; trackBy:trackByFn"
      class="hover:bg-indigo-50 group flex gap-2 py-2 items-center px-5 w-full" (click)="selectDossier(dossier)">
      <div class="size-6 rounded-full grid place-content-center justify-center shrink-0"
        [ngClass]="dossier.fkIdStatusDossier | dossierIconStatus">
        <i class=" fa-regular text-[0.8rem]" [ngClass]="dossier.fkIdStatusDossier | dossierStatusIcon"></i>
      </div>
      <div class="flex flex-col min-w-0 items-start">
        <span class="font-medium text-slate-600 truncate text-sm">{{dossier.name | titlecase}}</span>
        <div class="text-xs text-slate-600">{{dossier.numberDossier}}</div>
      </div>
      <div
        class="size-6 rounded-full hover:bg-indigo-100 grid place-content-center invisible group-hover:visible ml-auto">
        <i class=" fa-solid fa-chevron-right text-[0.8rem]"></i>
      </div>
    </button>
  </div>

  <!-- Empty state si no hay asuntos -->
  <div class="col-span-full justify-center items-center flex flex-col gap-1 pointer-events-none text-slate-400 py-3"
    *ngIf="!dossiers[0] && !selectedDossier && !(loading | async) && !(loadingFolder | async)">
    <div class="bg-indigo-100 size-20 grid place-content-center rounded-full">
      <i class="fa-solid fa-folder-open text-[2rem]"></i>
    </div>
    <span class="text-center font-medium">No encontramos asuntos para mostrar</span>
  </div>

  <!-- Listado de carpetas y archivos del asunto seleccionado -->
  <div class="pl-5 pr-4 overflow-auto" *ngIf="selectedDossier && !(loading | async)">
    <app-simple-storage-object-data [storageObjects]="storageObjects" />
  </div>

  <!-- Loader de asuntos -->
  <div class="flex flex-col gap-2 animate-pulse pointer-events-none p-5"
    *ngIf="(loading | async) || ((loadingFolder | async) && !selectedFolder)">
    <div class="flex gap-2" *ngFor="let _ of [].constructor(5);">
      <div class="size-8 shrink-0 bg-gray-200 rounded-full"></div>
      <div class="flex flex-col gap-1 *:bg-gray-200 grow">
        <div class="w-9/12 h-3 rounded"></div>
        <div class="w-5/12 h-3 rounded"></div>
      </div>
    </div>
  </div>
  <div *ngIf="(scrolling | async)"
    class="flex items-center justify-center gap-1 p-4 text-slate-600 pointer-events-none">
    <i class="fa-solid text-[1rem] text-slate-600 fa-spinner-third animate-spin"></i>
    <span>Cargando...</span>
  </div>
</div>

<div class="py-5 px-4 border-t bg-pearl-100 border-slate-200 sticky bottom-0 flex flex-wrap justify-end gap-2"
  *ngIf="dialogRef">
  <button [disabled]="loading | async" class="uisr-secondary-button" (click)="dialogRef.close()">
    <span translate="CANCEL_STRING"></span>
  </button>
  <button class="uisr-primary-button flex justify-center items-center gap-1" (click)="onSubmit()"
    [disabled]="!selectedFiles.size">
    <span translate="ADD_STRING"></span>
    <span>({{selectedFiles.size}})</span>
  </button>
</div>