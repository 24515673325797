import { Directive, ElementRef, EventEmitter, Injectable, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[appResizeObserver]'
})
@Injectable({
  providedIn: 'root'
})
export class ResizeObserverDirective implements OnInit, OnDestroy {

  @Output() onResizeObserver = new EventEmitter<DOMRect>();

  private resizeObserver: any;

  constructor(private elementRef: ElementRef) { }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  ngOnInit(): void {
    const element = this.elementRef.nativeElement;

    this.resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.contentRect) {
          this.onResizeObserver.emit(entry.contentRect);
        }
      }
    });

    this.resizeObserver.observe(element);
  }

}
