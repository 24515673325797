import { inject } from '@angular/core';
import { ActivatedRoute, CanActivateFn, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DateTime } from 'luxon';

export const BlogGuard: CanActivateFn = (route, state) => {

  const router = inject(Router);
  const activatedRoute = inject(ActivatedRoute);

  const jwtHelper = inject(JwtHelperService);

  const publicPostUrl = 'blog-md/post';
  const publicBlogUrl = 'blog';
  const postUrl = 'law-firm/library/post';
  const blogUrl = 'law-firm/library/blog';
  const currentRoute = state.toString().replaceAll(',', '/');

  const validSession = (): boolean => {
    const currentTime = DateTime.now().toSeconds();
    const refreshToken = localStorage.getItem('refreshToken') || '';

    if (!refreshToken) {
      return false;
    }

    const decodedToken = jwtHelper.decodeToken(refreshToken) || null;
    const expirationTime = decodedToken.exp;
    const duration = expirationTime - currentTime;

    if (duration <= 0) {
      return false;
    } else {
      return true;
    }
  };

  

  if (currentRoute) {
    if (currentRoute.includes('blog-md')) {
      const newRoute = currentRoute.replace('blog-md', 'blog');
      router.navigate([newRoute]);

      return false;
    }
    // Si el usuario no tiene una sesión válida y trata de acceder a la sección de blog
    if (currentRoute.includes(blogUrl) && !validSession()) {
      // Redirigir al usuario a la sección pública del blog
      router.navigate(['blog-md']);
      return false;
    }

    // Si el usuario no tiene una sesión válida y trata de acceder a un post del blog
    if (currentRoute.includes(postUrl) && !validSession()) {
      const postSlug = currentRoute.split('/').pop();
      // Redirigir al usuario a la sección pública del blog
      router.navigate(['blog-md', 'post', postSlug]);
      return false;
    }

    // Si el usuario tiene una sesión válida y trata de acceder a un post del blog
    if (currentRoute.includes(publicPostUrl) && validSession()) {
      const postSlug = currentRoute.split('/').pop();
      // Redirigir al usuario a la sección privada del blog
      router.navigate(['law-firm', 'library', 'post', postSlug]);
      return false;
    }

    // Si el usuario tiene una sesión válida y trata de acceder a la sección pública del blog
    if (currentRoute === publicBlogUrl && validSession()) {
      
      // Incompleto redirigir de /law-firm/library/blog?idWorkspace=99 ->  /blog?idWorkspace=99
      let idWorkspace = activatedRoute.snapshot.queryParamMap.get('idWorkspace');
      let extra = idWorkspace ? { queryParams: { idWorkspace: idWorkspace } } : {};

      // Redirigir al usuario a la sección privada del blog
      router.navigate(['law-firm', 'library', 'blog'], extra);
      return false;
    }
  }

  return true;
};
